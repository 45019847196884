import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth";
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {

        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID


    };

export const app = firebase.initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;

//const analytics = getAnalytics(app);
