import React, {Component} from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {getAuth} from "firebase/auth";
import Navbar from "../../components/Navbar";
import {useParams} from 'react-router-dom';
import {collection, getDocs, getDoc, doc, updateDoc, arrayUnion} from "firebase/firestore";
import { db } from "../../firebase/db";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faUpLong, faCheck, faPlusCircle, faInfoCircle, faFloppyDisk, faCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import 'datatables.net-fixedcolumns-dt';
import Accordion from 'react-bootstrap/Accordion';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


/*
function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}
*/
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class GutDetails extends Component{
    constructor(props){
        super(props);

        this.userServing = React.createRef();
        this.carb_actual_start = React.createRef();
        this.getWeekName = React.createRef();
        this.currentWeekName = '';
        this.carb_actual = 0;
        this.startWeight = [];
        this.endWeight = [];
        this.weightDiff = [];
        this.drinkVolume = [];
        this.sweat_rate = [];
        this.sweat_duration = [];
        this.updateProdObject = [];
        this.race_duration = 0;
        this.current_products = [];
        this.weeklyCarbGoals = [];
        this.updateWeeklyServings = {};
        this.weekCount = 0;
        this.productDone = [];
        this.serving_count = 0;
        this.id = '';
        this.na_goal = '';
        this.naover = '';
        this.carbover = '';


        this.state = {value: ''};
        this.state = {loading: false}
        this.state = {
            title: '',
            date: '',
            race_date: '',
            sodium_loss: '',
            sodium_loss_value:0,
            carb_consume:'yes',
            carb_consume_value: 0,
            fluids_train: 'yes',
            fluids_value: 0,
            product: '',
            prod_name: '',
            currentProducts: [],
            productInfo: [],
            weeklyCarbGoal: [],
            sweat_duration: 0,
            race_duration: 0,
            product_info: [],
            carbPlusMinus:0,
            productDone:[],
            initProduct:[],
            carb_actual_add:{},
            sodium_actual_add:{},
            sodium_goal: 500,
            carb_actual_class:'',
            sodium_actual_class:'',
            actual_icon:'',
            week_row: '',
            readonly: '',
            button:'',
            updateHeading:''

        }

        this.prodObject = {

        }
        this.gutObject = {

        };

        this.current_products = '';
        this.componentDidMount = this.componentDidMount();
        //this.popover = this.popover.bind(this);

    }


    // Start funcitons on page load

    componentDidMount = async () => {
        //get param from url
        let {id} = this.props.params;
        this.id = id;

        // call fetchData function and pass the id
        this.fetchData(id, 'start');

      //  console.log(this.state.carb_actual_add['week_one']);

        /// Carb Plus Minus Function

        this.carbPlusMinus = async (event) => {
            //console.log(event.target.defaultValue);
            //console.log(event.target.value);
            console.log(this.getWeekName.current.id);
            const auth = getAuth();
            const user = auth.currentUser;
            const user_id = user.uid;

            const {carb_actual_add} = this.state;
            const {sodium_actual_add} = this.state;

            let startServing = event.target.defaultValue;
            console.log(startServing)

            let serving = event.target.value;
            console.log(serving);

            let inputID = event.target.id;


            let add_serving = .5;

            let inputArray = inputID.split('-');

            let productID = inputArray[1];
            let week_name = inputArray[0];
            let carb_actual_new = this.carb_actual;
            let sodium_actual_new = this.na_actual;


            if(this.product_td !== inputID){
                this.serving_count = event.target.defaultValue;
            }

            let updateItem = this.state.productInfo.find(element => element.id == productID);

            console.log(event.target.value);

            switch (week_name) {
                case 'week_ten':
                    this.serving_count = updateItem.weekly_serving.week_ten;
                    updateItem.weekly_serving.week_ten = Number(event.target.value);

                    break;
                case 'week_nine':
                    this.serving_count = updateItem.weekly_serving.week_nine;
                    updateItem.weekly_serving.week_nine = Number(event.target.value);
                    break;
                case 'week_eight':
                    this.serving_count = updateItem.weekly_serving.week_eight;
                    updateItem.weekly_serving.week_eight = Number(event.target.value);
                    break;
                case 'week_seven':
                    this.serving_count = updateItem.weekly_serving.week_seven;
                    updateItem.weekly_serving.week_seven = Number(event.target.value);
                    break;
                case 'week_six':
                    this.serving_count = updateItem.weekly_serving.week_six;
                    updateItem.weekly_serving.week_six = Number(event.target.value);
                    break;
                case 'week_five':
                    this.serving_count = updateItem.weekly_serving.week_five;
                    updateItem.weekly_serving.week_five = Number(event.target.value);
                    break;
                case 'week_four':
                    this.serving_count = updateItem.weekly_serving.week_four;
                    updateItem.weekly_serving.week_four = Number(event.target.value);
                    break;
                case 'week_three':
                    this.serving_count = updateItem.weekly_serving.week_three;
                    updateItem.weekly_serving.week_three = Number(event.target.value);
                    break;
                case 'week_two':
                    this.serving_count = updateItem.weekly_serving.week_two;
                    updateItem.weekly_serving.week_two = Number(event.target.value);
                    break;
                case 'week_one':
                    this.serving_count = updateItem.weekly_serving.week_one;
                    updateItem.weekly_serving.week_one = Number(event.target.value);
                    break;
                default:

                    break;
            }


            let index_to_update = this.state.productInfo.findIndex((obj => obj.id == updateItem.id));

            this.state.productInfo[index_to_update] = updateItem;


            let carbActualStart = parseFloat(this.state.carb_actual_add[week_name]);
            let sodiumActualStart = parseFloat(this.state.sodium_actual_add[week_name]);

            let carbServing = parseFloat(updateItem.carbs) * add_serving;
            let sodiumServing = parseFloat(updateItem.sodium) * add_serving;


            if(parseFloat(serving) > parseFloat(this.serving_count)) {
                carb_actual_new = parseFloat(carbActualStart) + carbServing;
                sodium_actual_new = parseFloat(sodiumActualStart) + sodiumServing;
            }else{
                carb_actual_new = parseFloat(carbActualStart) - carbServing;
                sodium_actual_new = parseFloat(sodiumActualStart) - sodiumServing;

            }



           // let carbandserving = result[i].product_info[p].carbs * result[i].product_info[p].serving;
            //this.carb_actual_add = carbandserving + this.carb_actual_add;

            this.state.carb_actual_add[week_name] = carb_actual_new;
            this.state.sodium_actual_add[week_name] = sodium_actual_new;

            this.setState({carb_actual_add});
            this.setState({sodium_actual_add});

            await updateDoc(doc(db, "users", user_id, 'gut-training', id), {
                products: this.state.productInfo
            });

            //this.serving_count = event.target.value;

            this.product_td = event.target.id;

        } // End Carb Plus Minus Function


    }
    fetchData = async (id, update) => {
        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;

        // fetch gut-training by param
        const docSnap = await getDoc(doc(db, "users", user_id, 'gut-training', id));

        this.setState({userEdit:docSnap.data().user_edit });

        this.user_edit = docSnap.data().user_edit;



        // get sodium loss from Firebase
        this.sodium_loss = docSnap.data().sodium_consume;




        // get race id
        let race_id = docSnap.data().race_details.id;

        // get race-plan from database
        const raceSnap = await getDoc(doc(db, "users", user_id, 'race-plan', race_id));

        console.log(raceSnap.data())

        this.setState({race_name:raceSnap.data().race_name})
        // calculate race date into milliseconds
        var race_date = Date.parse(raceSnap.data().race_date);
        var week_date = 7 * 24 * 60 * 60 * 1000;

        // calculate todays date.
        var todays_date = new Date();
        todays_date = Date.parse(todays_date);

        // set states to use in table
        //this.setState({title: raceSnap.data().race_name});
        this.setState({date: raceSnap.data().race_date});
        this.setState({carb_consume: docSnap.data().carb_consume});
        this.setState({sodium_loss: docSnap.data().sodium_loss});
        this.setState({sodium_loss_value: docSnap.data().sodium_loss_value});
        this.setState({carb_consume_value: docSnap.data().carb_consume_value});
        this.setState({fluids_value: docSnap.data().fluids_value});
        this.setState({current_products: docSnap.data().products});
        this.setState({race_duration: raceSnap.data().race_time});

        this.race_duration = raceSnap.data().race_time

        //init sodium questions @@ edit gut training page
        let sodium_goal_letter = docSnap.data().sodium_question;

        //If sodium loss is no then calculate
        //TODO Add 18 / 38/ 70
        if(this.sodium_loss === "no") {
            switch (sodium_goal_letter) {
                case 'a':
                    this.na_goal = 500;
                    break;
                case 'b':
                    this.na_goal = 1000;
                    break;
                case 'c':
                    this.na_goal = 1500;
                    break;
                default:
                    this.na_goal = 500;
                    break;

            }
        }else{
            this.na_goal = docSnap.data().sodium_loss_value;

        }


        // get all current product ID's from gut training
        this.current_products = docSnap.data().products;

        // get sweat testing from database --> needed for calculations
        const sweatSnap = await getDocs(collection(db, "users", user_id, 'sweat-test'));

        // loop through sweat-trainings to get averages
        sweatSnap.forEach((doc) => {
            console.log(doc.data());

            let start_weight = doc.data().start_weight;
            let end_weight = doc.data().end_weight;
            let drink_volume = doc.data().dvolume;

            let race_duration = doc.data().duration;

            let length_of_event = Number(race_duration) / 60;

            let weight_diff = Number(start_weight) - Number(end_weight);

            let total_sweat_loss = parseInt(doc.data().total_sweat_loss);


            let length_until_event = this.race_day;

            // Get Only Sweat Rates where sweat temps are within +15 -15 of race temp

            let sweat_temp = parseInt(doc.data().temp)
            let race_temp = parseInt(raceSnap.data().race_temp);


            let sweat_rate = parseInt(doc.data().sweat_rate);

            console.log(sweat_temp);
            console.log(race_temp);

            this.startWeight.push(Number(doc.data().start_weight))
            this.weightDiff.push(weight_diff);

            if(sweat_temp >= race_temp - 10 && sweat_temp <= race_temp + 10) {
                this.sweat_rate.push(sweat_rate);
            }

            console.log(this.sweat_rate);
            this.race_day = doc.data().sdate;

        });

        this.startWeight.sort(function(a, b){return b-a});

        if(this.startWeight.length > 3) {
            this.startWeight = this.startWeight.slice(0, 3);
        }

        const {productInfo} = this.state;

        const prodSnap = await getDocs(collection(db, "products"));

        Object.values(docSnap.data().products).forEach((doc) => {

                    //console.log(doc);
                    let prodObject = {
                        ['id']: doc.id,
                        ['name']: doc.name,
                        ['calories']: doc.calories,
                        ['carbs']: parseInt(doc.carbs),
                        ['sodium']: doc.sodium,
                        ['serving']: doc.serving,
                        ['serving_size']: doc.serving_size,
                        ['flavor']: doc.flavor,
                        ['weekly_serving']: doc.weekly_serving,
                        ['type']: doc.type,
                    }
                    this.state.productInfo.push(prodObject);

        });

        this.setState({productInfo});



        this.sweat_rate.sort(function(a, b){return b-a});

        console.log(this.sweat_rate);

        if(this.sweat_rate.length > 3) {
            this.sweat_rate = this.sweat_rate.slice(0, 3);
        }

        if(this.weightDiff.length > 3) {
            this.sweat_rate = this.sweat_rate.slice(0, 3);
        }


        // function to produce averages
        const average = array => array.reduce((a, b) => a + b, 0) / array.length || 0;

        // average all numbers for calculations
        let sweat_rate = average(this.sweat_rate);

        //console.log(sweat_rate);


        let average_start_weight = average(this.startWeight);

        sweat_rate = Math.round(sweat_rate * 100) / 100;

        //console.log(sweat_rate);

        let length_of_event = this.race_duration / 60;

        //console.log(length_of_event);

        let total_tolerable = average_start_weight * (.02) * 16;

        //console.log(total_tolerable);

        let possible_fluid_loss = sweat_rate * length_of_event;

        //console.log(possible_fluid_loss);

        let total_fluid_must_be_consumed = possible_fluid_loss - total_tolerable;

        let calculated_fluid_goal = total_fluid_must_be_consumed / length_of_event;



        this.fluid_consume = docSnap.data().fluid_consume;

        if(this.fluid_consume === 'no') {
            var fluid_goal_per_hour = Math.round(total_fluid_must_be_consumed / 10 * 100) / 100;
            this.fluid_goal_per_hour = Math.round(total_fluid_must_be_consumed / 10 * 100) / 100;
        }else{
            var fluid_goal_per_hour = raceSnap.data().fluids_value;
            this.fluid_goal_per_hour = raceSnap.data().fluids_value;
            console.log(this.fluid_goal_per_hour);
        }



        let sodium_calc_value = this.sodium_loss_value * fluid_goal_per_hour;

        this.carb_consume = docSnap.data().carb_consume;

        if(this.carb_consume === 'no') {
            if (10 >= 3) {
                var carb_goal = 90;
            } else if (length_of_event < 3 && length_of_event > 1.5) {
                var carb_goal = 60;
            } else if (length_of_event > 0 && length_of_event <= 1.5) {
                var carb_goal = 30;
            }
        }else{
            var carb_goal = parseInt(docSnap.data().carb_consume_value);
        }

        console.log(calculated_fluid_goal);

        var starting_sweat_rate = calculated_fluid_goal * 1.25;
        this.starting_sweat_rate = calculated_fluid_goal * 1.25;
        var starting_carb_goal = 90 * 1.25;
        var number_of_weeks_until_race = 10;


        if(fluid_goal_per_hour <= 0){
            fluid_goal_per_hour = 10;
        }

        fluid_goal_per_hour = Math.round(fluid_goal_per_hour);

        // Carb Goals for weeks

        var carb_goal_week_ten = carb_goal;
        var fl_goal_week_ten = fluid_goal_per_hour;
        var carb_goal_week_nine = Math.round(carb_goal_week_ten + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_nine = Math.round(fl_goal_week_ten + (starting_sweat_rate - fl_goal_week_ten)/(number_of_weeks_until_race - 1));
        var carb_goal_week_eight = Math.round(carb_goal_week_nine + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_eight = Math.round(fl_goal_week_nine + (starting_sweat_rate - fl_goal_week_nine)/(number_of_weeks_until_race - 1));
        var carb_goal_week_seven = Math.round(carb_goal_week_eight + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_seven = Math.round(fl_goal_week_eight + (starting_sweat_rate - fl_goal_week_eight)/(number_of_weeks_until_race - 1));
        var carb_goal_week_six = Math.round(carb_goal_week_seven + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_six = Math.round(fl_goal_week_seven + (starting_sweat_rate - fl_goal_week_seven)/(number_of_weeks_until_race - 1));
        var carb_goal_week_five = Math.round(carb_goal_week_six + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_five = Math.round(fl_goal_week_six + (starting_sweat_rate - fl_goal_week_six)/(number_of_weeks_until_race - 1));
        var carb_goal_week_four = Math.round(carb_goal_week_five + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_four = Math.round(fl_goal_week_five + (starting_sweat_rate - fl_goal_week_five)/(number_of_weeks_until_race - 1));
        var carb_goal_week_three = Math.round(carb_goal_week_four + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_three = Math.round(fl_goal_week_four + (starting_sweat_rate - fl_goal_week_four)/(number_of_weeks_until_race - 1));
        var carb_goal_week_two = Math.round(carb_goal_week_three + (starting_carb_goal - carb_goal_week_ten)/(number_of_weeks_until_race - 1));
        var fl_goal_week_two = Math.round(fl_goal_week_three + (starting_sweat_rate - fl_goal_week_three)/(number_of_weeks_until_race - 1));
        var carb_goal_week_one = 90 * 1.25;
        var fl_goal_week_one = starting_sweat_rate;

        console.log(fl_goal_week_two);

        if(this.sodium_loss === "yes") {
            console.log(this.na_goal);
            // Sodium Goals for Weeks
            var na_goal_week_one = fl_goal_week_one * this.na_goal;
            var na_goal_week_two = fl_goal_week_two * this.na_goal;
            var na_goal_week_three = fl_goal_week_three * this.na_goal;
            var na_goal_week_four = fl_goal_week_four * this.na_goal;
            var na_goal_week_five = fl_goal_week_five * this.na_goal;
            var na_goal_week_six = fl_goal_week_six * this.na_goal;
            var na_goal_week_seven = fl_goal_week_seven * this.na_goal;
            var na_goal_week_eight = fl_goal_week_eight * this.na_goal;
            var na_goal_week_nine = fl_goal_week_nine * this.na_goal;
            var na_goal_week_ten = fl_goal_week_ten * this.na_goal;
        }else{
            var na_goal_week_one = this.na_goal;
            var na_goal_week_two = this.na_goal;
            var na_goal_week_three = this.na_goal;
            var na_goal_week_four = this.na_goal;
            var na_goal_week_five = this.na_goal;
            var na_goal_week_six = this.na_goal;
            var na_goal_week_seven = this.na_goal;
            var na_goal_week_eight = this.na_goal;
            var na_goal_week_nine = this.na_goal;
            var na_goal_week_ten = this.na_goal;
        }

        console.log(na_goal_week_ten);

        const {weeklyCarbGoal} = this.state;


        let carbWeeklyObj = {}

        const {carb_actual_add} = this.state;
        const productServings = (week) => {

            let date = new Date();
            let month = date.getMonth();
            let day = date.getDay();
            let year = date.getFullYear();

            let currentDate = month + '-' + day + '-' + year;

            currentDate = new Date(currentDate);

            //console.log(week);
            let prod_count = 0;
            let carb_goal = 0;
            let na_goal = 0;
            let fl_goal = 0;
            let carb_actual = 0;
            let updatedProduct = [];
            let productDone = structuredClone(this.state.productInfo);
            let editProd = [];
            let single_week_date;


            for (let s = 0; s < productDone.length; s++) {
                productDone[s].serving = 0;

            }

            switch (week) {
                case 'week_ten':
                    carb_goal = Math.round(carb_goal_week_ten * 10) / 10;
                    na_goal = Math.round(na_goal_week_ten * 10) / 10;
                    single_week_date = race_date - (week_date * 10);
                    fl_goal = Math.round(fl_goal_week_ten * 10) / 10;
        //            console.log(single_week_date);
                    break;
                case 'week_nine':
                    carb_goal = Math.round(carb_goal_week_nine * 10) / 10;
                    na_goal = Math.round(na_goal_week_nine * 10) / 10;
                    single_week_date = race_date - (week_date * 9);
                    fl_goal = Math.round(fl_goal_week_nine * 10) / 10;
                    break;
                case 'week_eight':
                    carb_goal = Math.round(carb_goal_week_eight * 10) / 10;
                    na_goal = Math.round(na_goal_week_eight * 10) / 10;
                    single_week_date = race_date - (week_date * 8);
                    fl_goal = Math.round(fl_goal_week_eight * 10) / 10;
                    break;
                case 'week_seven':
                    carb_goal = Math.round(carb_goal_week_seven * 10) / 10;
                    na_goal = Math.round(na_goal_week_seven * 10) / 10;
                    single_week_date = race_date - (week_date * 7);
                    fl_goal = Math.round(fl_goal_week_seven * 10) / 10;
                    break;
                case 'week_six':
                    carb_goal = Math.round(carb_goal_week_six * 10) / 10;
                    na_goal = Math.round(na_goal_week_six * 10) / 10;
                    single_week_date = race_date - (week_date * 6);
                    fl_goal = Math.round(fl_goal_week_six * 10) / 10;
                    break;
                case 'week_five':
                    carb_goal = Math.round(carb_goal_week_five * 10) / 10;
                    na_goal = Math.round(na_goal_week_five * 10) / 10;
                    single_week_date = race_date - (week_date * 5);
                    fl_goal = Math.round(fl_goal_week_five * 10) / 10;
                    break;
                case 'week_four':
                    carb_goal = Math.round(carb_goal_week_four * 10) / 10;
                    na_goal = Math.round(na_goal_week_four * 10) / 10;
                    single_week_date = race_date - (week_date * 4);
                    fl_goal = Math.round(fl_goal_week_four * 10) / 10;
                    break;
                case 'week_three':
                    carb_goal = Math.round(carb_goal_week_three * 10) / 10;
                    na_goal = Math.round(na_goal_week_three * 10) / 10;
                    single_week_date = race_date - (week_date * 3);
                    fl_goal = Math.round(fl_goal_week_three * 10) / 10;
                    break;
                case 'week_two':
                    carb_goal = Math.round(carb_goal_week_two * 10) / 10;
                    na_goal = Math.round(na_goal_week_two * 10) / 10;
                    single_week_date = race_date - (week_date * 2);
                    fl_goal = Math.round(fl_goal_week_two * 10) / 10;
                    break;
                case 'week_one':
                    carb_goal = Math.round(carb_goal_week_one * 10) / 10;
                    na_goal = Math.round(na_goal_week_one * 10) / 10;
                    single_week_date = race_date - week_date;
                    fl_goal = Math.round(fl_goal_week_one * 10) / 10;
                    break;
                default:
                    na_goal = na_goal;
                    carb_goal = carb_goal;
                    week_date = race_date;
                    break;
            }

            let loopCount = 0;
            let carb_count = 0;
            let sodium_count = 0;
            editProd = this.state.productInfo[0];
            let carbs = editProd.carbs * .5;
            let sodium = editProd.sodium * .5;
            carb_count += carbs;
            sodium_count += sodium;
            let i = 0;
            while ((carb_count < carb_goal) && loopCount < 100){
                let updateItem = productDone.find(element => element.id == editProd.id);

                if ( updateItem ) {
                    updateItem.serving += 0.5;
                    i += 1;
                    if( i > this.state.productInfo.length -1)
                        i = 0;
                }
                loopCount += 1;
                editProd = this.state.productInfo[i];
                carbs = editProd.carbs * .5;
                sodium = editProd.sodium * .5;
                carb_count += carbs;
                sodium_count += sodium;

            }

            let updateItem = productDone.find(element => element.id == editProd.id);
            updateItem.serving += 0.5;

            this.state.carb_actual_add[week] = parseFloat(carb_count);
            this.state.sodium_actual_add[week] = parseFloat(sodium_count);


                let newObject = {
                    ['product_info']: productDone,
                    ['carb_actual']: carb_count,
                    ['sodium_actual']: sodium_count,
                    ['week']: week,
                    ['carb_goal']: carb_goal,
                    ['na_goal']: na_goal,
                    ['week_date']: single_week_date,
                    ['fl_goal']: fl_goal,
                    //['week_date']: week_date

                }

                updatedProduct = [newObject];


            return (updatedProduct);


        }

        const productUserEditServings = (week) => {

            let date = new Date();
            let month = date.getMonth();
            let day = date.getDay();
            let year = date.getFullYear();

            let currentDate = month + '-' + day + '-' + year;

            currentDate = new Date(currentDate);


            //console.log(week);
            let prod_count = 0;
            let carb_goal = 0;
            let na_goal = 0;
            let fl_goal = 0;
            let carb_actual = 0;
            let updatedProduct = [];
            let productDone = structuredClone(this.state.productInfo);
            let editProd = [];
            let single_week_date;

            //console.log(editProd);


            let loopCount = 0;
            let carb_count = 0;
            let sodium_count = 0;
            editProd = this.state.productInfo[0];
            let carbs = editProd.carbs;
            let sodium = editProd.sodium;
            carb_count = 0;
            sodium_count = 0;

            //console.log(editProd);

            let updateItem = productDone.find(element => element.id == editProd.id);

            let count_week = 604800000;

            //console.log((currentDate - this.state.race_date)/count_week);

            switch (week) {
                case 'week_ten':

                    updateItem.serving = updateItem.weekly_serving.week_ten;
                    carb_goal = Math.round(carb_goal_week_ten * 10) / 10;
                    na_goal = Math.round(na_goal_week_ten * 10) / 10;
                    single_week_date = race_date - (week_date * 10);
                    fl_goal = Math.round(fl_goal_week_ten * 10) / 10;
                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_ten;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_nine':
                    updateItem.serving = updateItem.weekly_serving.week_nine;
                    carb_goal = Math.round(carb_goal_week_nine * 10) / 10;
                    na_goal = Math.round(na_goal_week_nine * 10) / 10;
                    single_week_date = race_date - (week_date * 9);
                    fl_goal = Math.round(fl_goal_week_nine * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_nine;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;


                case 'week_eight':
                    updateItem.serving = updateItem.weekly_serving.week_eight;
                    carb_goal = Math.round(carb_goal_week_eight * 10) / 10;
                    na_goal = Math.round(na_goal_week_eight * 10) / 10;
                    single_week_date = race_date - (week_date * 8);
                    fl_goal = Math.round(fl_goal_week_eight * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_eight;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_seven':
                    updateItem.serving = updateItem.weekly_serving.week_seven;
                    carb_goal = Math.round(carb_goal_week_seven * 10) / 10;
                    na_goal = Math.round(na_goal_week_seven * 10) / 10;
                    single_week_date = race_date - (week_date * 7);
                    fl_goal = Math.round(fl_goal_week_seven * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_seven;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_six':
                    updateItem.serving = updateItem.weekly_serving.week_six;
                    carb_goal = Math.round(carb_goal_week_six * 10) / 10;
                    na_goal = Math.round(na_goal_week_six * 10) / 10;
                    single_week_date = race_date - (week_date * 6);
                    fl_goal = Math.round(fl_goal_week_six * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_six;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;

                case 'week_five':
                    updateItem.serving = updateItem.weekly_serving.week_five;
                    carb_goal = Math.round(carb_goal_week_five * 10) / 10;
                    na_goal = Math.round(na_goal_week_five * 10) / 10;
                    single_week_date = race_date - (week_date * 5);
                    fl_goal = Math.round(fl_goal_week_five * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_five;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_four':
                    updateItem.serving = updateItem.weekly_serving.week_four;
                    carb_goal = Math.round(carb_goal_week_four * 10) / 10;
                    na_goal = Math.round(na_goal_week_four * 10) / 10;
                    single_week_date = race_date - (week_date * 4);
                    fl_goal = Math.round(fl_goal_week_four * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_four;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);
                        console.log(serving);
                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_three':
                    updateItem.serving = updateItem.weekly_serving.week_three;
                    carb_goal = Math.round(carb_goal_week_three * 10) / 10;
                    na_goal = Math.round(na_goal_week_three * 10) / 10;
                    single_week_date = race_date - (week_date * 3);
                    fl_goal = Math.round(fl_goal_week_three * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_three;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_two':
                    updateItem.serving = updateItem.weekly_serving.week_two;
                    carb_goal = Math.round(carb_goal_week_two * 10) / 10;
                    na_goal = Math.round(na_goal_week_two * 10) / 10;
                    single_week_date = race_date - (week_date * 2);
                    fl_goal = Math.round(fl_goal_week_two * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_two;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                case 'week_one':
                    updateItem.serving = updateItem.weekly_serving.week_one;
                    carb_goal = Math.round(carb_goal_week_one * 10) / 10;
                    na_goal = Math.round(na_goal_week_one * 10) / 10;
                    single_week_date = race_date - week_date;
                    fl_goal = Math.round(fl_goal_week_one * 10) / 10;

                    this.state.productInfo.forEach(function(item, index){
                        let serving = item.weekly_serving.week_one;
                        let carbs = item.carbs;
                        let na = Number(item.sodium);

                        let single_carb_count = serving * carbs;

                        //console.log(single_carb_count);

                        let single_na_count = serving * na;

                        carb_count += single_carb_count;
                        sodium_count += single_na_count;
                    })

                    break;
                default:
                    na_goal = na_goal;
                    carb_goal = carb_goal;
                    week_date = race_date;
                    break;
            }


            this.state.carb_actual_add[week] = parseFloat(carb_count);
            this.state.sodium_actual_add[week] = parseFloat(sodium_count);



            let newObject = {
                ['product_info']: productDone,
                ['carb_actual']: carb_count,
                ['sodium_actual']: sodium_count,
                ['week']: week,
                ['carb_goal']: carb_goal,
                ['na_goal']: na_goal,
                ['week_date']: single_week_date,
                ['fl_goal']: fl_goal,

                //['week_date']: week_date

            }

            updatedProduct = [newObject];

            // Carb Actual Table Header Icon
            //console.log(week);
            //console.log(this.state.sodium_actual_add[week]);
            //console.log(na_goal);





            return (updatedProduct);


        }




        if(this.user_edit !== true) {

            this.setState({button:''})
            this.setState({updateHeading:''})

            let week_ten = productServings('week_ten');
            let week_nine = productServings('week_nine');
            let week_eight = productServings('week_eight');
            let week_seven = productServings('week_seven');
            let week_six = productServings('week_six');
            let week_five = productServings('week_five');
            let week_four = productServings('week_four');
            let week_three = productServings('week_three');
            let week_two = productServings('week_two');
            let week_one = productServings('week_one');

            this.state.weeklyCarbGoal.push(week_ten);
            this.state.weeklyCarbGoal.push(week_nine);
            this.state.weeklyCarbGoal.push(week_eight);
            this.state.weeklyCarbGoal.push(week_seven);
            this.state.weeklyCarbGoal.push(week_six);
            this.state.weeklyCarbGoal.push(week_five);
            this.state.weeklyCarbGoal.push(week_four);
            this.state.weeklyCarbGoal.push(week_three);
            this.state.weeklyCarbGoal.push(week_two);
            this.state.weeklyCarbGoal.push(week_one);

        }else{

            this.setState({button:''})
            this.setState({updateHeading:''})

            let week_ten = productUserEditServings('week_ten');
            let week_nine = productUserEditServings('week_nine');
            let week_eight = productUserEditServings('week_eight');
            let week_seven = productUserEditServings('week_seven');
            let week_six = productUserEditServings('week_six');
            let week_five = productUserEditServings('week_five');
            let week_four = productUserEditServings('week_four');
            let week_three = productUserEditServings('week_three');
            let week_two = productUserEditServings('week_two');
            let week_one = productUserEditServings('week_one');

            this.state.weeklyCarbGoal.push(week_ten);
            this.state.weeklyCarbGoal.push(week_nine);
            this.state.weeklyCarbGoal.push(week_eight);
            this.state.weeklyCarbGoal.push(week_seven);
            this.state.weeklyCarbGoal.push(week_six);
            this.state.weeklyCarbGoal.push(week_five);
            this.state.weeklyCarbGoal.push(week_four);
            this.state.weeklyCarbGoal.push(week_three);
            this.state.weeklyCarbGoal.push(week_two);
            this.state.weeklyCarbGoal.push(week_one);
        }


        this.setState({carb_actual_add});



        //console.log(this.state.weeklyCarbGoal);
        //console.log(this.carb_actual_start);

        //console.log(this.state.weeklyCarbGoal);

        $(document).ready(function () {
            setTimeout(function () {
                $('#gut-details-view').DataTable({
                    fixedColumns:   {
                        left: 1,
                        right:0
                    },
                    scrollCollapse: true,
                    "ordering": false,
                    scrollX: true,
                    searching: false,
                    paging: false,
                    info: false,

                });
            });


        });

        this.updateUserEdit = async (checked) => {
            await updateDoc(doc(db, "users", user_id, 'gut-training', id), {
                user_edit:checked
            });

            window.location.reload();
        }



        this.saveWeek = async (event) => {
            let week_name = this.event;

            let productUpdate = structuredClone(this.current_products);

        }


    }

    fluidGoalWarning = () => {
        if(this.fluid_goal_per_hour > this.starting_sweat_rate){
            return(
                <span className="warning"><FontAwesomeIcon icon={faTriangleExclamation}/>It looks like you may be overhydrating in your sessions, if you are seeing
                    your fluid goal decrease each week consider double checking your sweat testing logs and your gut training details page.
                    </span>
            )
        }

    }
    render(){

        return (

            <div className="pageContainer">
                <Navbar/>
                <div className="myRacePlanContainer">
                    <div className="content">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Why and How to Gut Train</Accordion.Header>
                                <Accordion.Body>
                                    <p>To hit your weekly carbohydrate, sodium, and fluid goals, please aim to consume these products each hour during your training sessions.  We encourage breaking the recommended amounts into 20 min increments.</p>

                                    <p>For instance, if your goal is 60g carbs/hr, then aim to consume 20g of carbs at the first 20 minute mark, then another 20g of carbs at the 40 minute mark, and again at the 60 minute mark.</p>

                                    <p>Spreading out your carb intake allows for optimal stomach emptying rate, while also minimizing your risk for GI issues.</p>

                                    <p>
                                        Cox, G. R., Clark, S. A., Cox, A. J., Halson, S. L., Hargreaves, M., Hawley, J. A., Jeacocke, N., Snow, R. J., Yeo, W. K., & Burke, L. M. (2010). Daily training with high carbohydrate availability increases exogenous carbohydrate oxidation during endurance cycling. <i>Journal of applied physiology (Bethesda, Md. : 1985), 109(1)</i>, 126–134. <a href="https://doi.org/10.1152/japplphysiol.00950.2009">https://doi.org/10.1152/japplphysiol.00950.2009</a>
                                    </p>
                                    <p>
                                        Costa, R. J. S., Miall, A., Khoo, A., Rauch, C., Snipe, R., Camões-Costa, V., & Gibson, P. (2017). Gut-training: the impact of two weeks repetitive gut-challenge during exercise on gastrointestinal status, glucose availability, fuel kinetics, and running performance. <i>Applied physiology, nutrition, and metabolism = Physiologie appliquee, nutrition et metabolisme</i>, 42(5), 547–557. <a href="https://doi.org/10.1139/apnm-2016-0453">https://doi.org/10.1139/apnm-2016-0453</a>
                                    </p>
                                    <p>
                                        Snipe, R. M. J., Khoo, A., Kitic, C. M., Gibson, P. R., & Costa, R. J. S. (2017). Carbohydrate and protein intake during exertional heat stress ameliorates intestinal epithelial injury and small intestine permeability. Applied physiology, nutrition, and metabolism = <i>Physiologie appliquee, nutrition et metabolisme</i>, 42(12), 1283–1292. <a href="https://doi.org/10.1139/apnm-2017-0361">https://doi.org/10.1139/apnm-2017-0361</a>
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="row">
                        <div className="gut-details-title col">
                            <h2><b>{this.state.race_name}</b></h2>

                            {this.fluidGoalWarning()}
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    <div className="race-plan-container">
                        <div className="user-edit">
                            <span>User Edit </span>
                            <BootstrapSwitchButton
                                checked={this.state.userEdit}
                                onlabel='On'
                                offlabel='Off'
                                onChange={(checked: boolean) => {
                                    this.updateUserEdit(checked);
                                }}
                        />
                        </div>
                        <div className="gutDetails">
                            <h1>{this.state.title}</h1>
                            <h3>{this.state.race_date}</h3>

                        </div>

                    <div className="gut-details-table">
                        <table id="gut-details-view">
                            <thead>
                            <tr>
                                <th className="weeks-table">Weeks</th>
                                {
                                this.state.productInfo.map((result) => {
                                    this.popover = (

                                        <Popover id="product-popover">
                                            <Popover.Body>
                                                <b>{result.name}</b> <br />
                                                Flavor: {result.flavor} <br />
                                                Calories: {result.calories} <br />
                                                Sodium: {result.sodium} <br />
                                                Carbs: {result.carbs} <br />
                                                Serving Size: {result.serving_size} <br />

                                            </Popover.Body>
                                        </Popover>
                                    );
                                    return(
                                        <th className="product-col">
                                            <OverlayTrigger trigger="click" placement="right" overlay={this.popover}>
                                                <span className="product-information">
                                                    {result.name.substring(0, 20)}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </OverlayTrigger>
                                        </th>
                                    )

                            })
                            }
                                <th>Carb Actual (g/hr)</th>
                                <th>Carb Goal (g/hr)</th>
                                <th>NA Actual (mg/hr)
                                </th>

                                <th>NA Goal (mg/hr)

                                </th>
                                <th>Fluid Goal (oz/hr)</th>



                            </tr>
                            </thead>
                            <tbody>
                            {
                                // Loop through weeklyCarb Goal

                                Object.values(this.state.weeklyCarbGoal).map((result) => {
                                    for (let i = 0; i < result.length; i++) {
                                        let weekCarb = this.state.carb_actual_add[result[i].week];
                                        let weekSodium = this.state.sodium_actual_add[result[i].week];

                                        let week_date = result[i].week_date;

                                        let date_week = new Date(week_date);

                                        let dd = date_week.getDate();
                                        let mm = date_week.getMonth() + 1;
                                        let year = date_week.getFullYear();

                                        let readable_week = mm + '/' + dd + '/' + year;

                                     //   console.log(week_date);
                                        if(this.state.userEdit === true) {
                                            // If carb goal is between -5 and -10
                                            if (weekCarb < result[i].carb_goal - 5 && weekCarb > result[i].carb_goal - 10) {
                                                this.state.carb_actual_class = 'carb-actual-five';
                                                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.carbover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your carbs are slightly off, look at an alt product or
                                                                adjusting serving size</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }
                                            // If carb goal is between +5 and +10
                                            if (weekCarb > result[i].carb_goal + 5 && weekCarb < result[i].carb_goal + 10) {
                                                this.state.carb_actual_class = 'carb-actual-five';
                                                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                                                this.carbover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your carbs are slightly off, look at an alt product or
                                                                adjusting serving size</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                                // Else if carb goal is in normal range
                                            }
                                            if (weekCarb >= result[i].carb_goal - 5 && weekCarb <= result[i].carb_goal + 5) {
                                                this.state.carb_actual_class = 'carb-actual-ok';
                                                this.state.faicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                                                this.carbover = (

                                                    <Popover id="product-popover">

                                                    </Popover>
                                                );
                                            }


                                            // If carb goal is above +10 or below -10
                                            if (weekCarb <= result[i].carb_goal - 10) {
                                                this.state.carb_actual_class = 'carb-actual-ten';
                                                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.carbover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your carb levels are off. Choose an alt product or adjust
                                                                serving to meet goals</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }
                                            if (weekCarb >= result[i].carb_goal + 10) {
                                                this.state.carb_actual_class = 'carb-actual-ten';
                                                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.carbover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your carb levels are off. Choose an alt product or adjust
                                                                serving to meet goals</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }

                                            if (weekSodium < result[i].na_goal - 250 && weekSodium > result[i].na_goal - 500) {
                                                this.state.sodium_actual_class = 'sodium-actual-five';
                                                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.naover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your sodium is slightly off, look at an alt product or
                                                                adjusting serving size</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }
                                            if (weekSodium > result[i].na_goal + 250 && weekSodium < result[i].na_goal + 500) {
                                                this.state.sodium_actual_class = 'sodium-actual-five';
                                                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.naover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your sodium is slightly off, look at an alt product or
                                                                adjusting serving size</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }
                                            if (weekSodium >= result[i].na_goal - 250 && weekSodium <= result[i].na_goal + 250) {
                                                this.state.sodium_actual_class = 'sodium-actual-ok';
                                                this.state.naicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                                                this.naover = (

                                                    <Popover id="product-popover">

                                                    </Popover>
                                                );
                                            }


                                            // If sodium goal is above +1000 or below -1000
                                            if (weekSodium <= result[i].na_goal - 500) {
                                                this.state.sodium_actual_class = 'sodium-actual-ten';
                                                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.naover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your sodium level is off. Choose an alt product or adjust serving to meet goals</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }
                                            if (weekSodium >= result[i].na_goal + 500) {
                                                this.state.sodium_actual_class = 'sodium-actual-ten';
                                                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                this.naover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>Your sodium level is off. Choose an alt product or adjust serving to meet goals</b>

                                                        </Popover.Body>
                                                    </Popover>
                                                );
                                            }

                                        }else{


                                                // If carb goal is between -5 and -10
                                                if (weekCarb < result[i].carb_goal - 5 && weekCarb > result[i].carb_goal - 10) {
                                                    this.state.carb_actual_class = 'carb-actual-five';
                                                    this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.carbover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your carbs are slightly off, look at an alt product or
                                                                    adjusting serving size</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }
                                                // If carb goal is between +5 and +10
                                                if (weekCarb > result[i].carb_goal + 5 && weekCarb < result[i].carb_goal + 10) {
                                                    this.state.carb_actual_class = 'carb-actual-five';
                                                    this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                                                    this.carbover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your carbs are slightly off, look at an alt product or
                                                                    adjusting serving size</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                    // Else if carb goal is in normal range
                                                }
                                                if (weekCarb >= result[i].carb_goal - 5 && weekCarb <= result[i].carb_goal + 5) {
                                                    this.state.carb_actual_class = 'carb-actual-ok';
                                                    this.state.faicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                                                    this.carbover = (

                                                        <Popover id="product-popover">

                                                        </Popover>
                                                    );
                                                }


                                                // If carb goal is above +10 or below -10
                                                if (weekCarb <= result[i].carb_goal - 10) {
                                                    this.state.carb_actual_class = 'carb-actual-ten';
                                                    this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.carbover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your carb levels are off. Choose an alt product or adjust
                                                                    serving to meet goals</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }
                                                if (weekCarb >= result[i].carb_goal + 10) {
                                                    this.state.carb_actual_class = 'carb-actual-ten';
                                                    this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.carbover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your carb levels are off. Choose an alt product or adjust
                                                                    serving to meet goals</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }

                                                if (weekSodium < result[i].na_goal - 250 && weekSodium > result[i].na_goal - 500) {
                                                    this.state.sodium_actual_class = 'sodium-actual-five';
                                                    this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.naover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your sodium is slightly off, look at an alt product  or toggle user edit to adjust serving</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }
                                                if (weekSodium > result[i].na_goal + 250 && weekSodium < result[i].na_goal + 500) {
                                                    this.state.sodium_actual_class = 'sodium-actual-five';
                                                    this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.naover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your sodium is slightly off, look at an alt product  or toggle user edit to adjust serving</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }
                                                if (weekSodium >= result[i].na_goal - 250 && weekSodium <= result[i].na_goal + 250) {
                                                    this.state.sodium_actual_class = 'sodium-actual-ok';
                                                    this.state.naicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                                                    this.naover = (

                                                        <Popover id="product-popover">

                                                        </Popover>
                                                    );
                                                }


                                                // If sodium goal is above +1000 or below -1000
                                                if (weekSodium <= result[i].na_goal - 500) {
                                                    this.state.sodium_actual_class = 'sodium-actual-ten';
                                                    this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.naover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your sodium level is off. Choose an alt product or toggle user edit to adjust serving</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }
                                                if (weekSodium >= result[i].na_goal + 500) {
                                                    this.state.sodium_actual_class = 'sodium-actual-ten';
                                                    this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                                                    this.naover = (

                                                        <Popover id="product-popover">
                                                            <Popover.Body>
                                                                <b>Your sodium level is off. Choose an alt product or toggle user edit to adjust serving</b>

                                                            </Popover.Body>
                                                        </Popover>
                                                    );
                                                }

                                        }

                                        let nowDate = new Date();
                                        let week = 7 * 24 * 60 * 60 * 1000;

                                        let current_date = nowDate.getFullYear()+'-'+(nowDate.getMonth()+1)+'-'+nowDate.getDate();

                                        current_date = Date.parse(current_date);

                                        if(this.user_edit !== true) {

                                            if (result[i].week == 'week_ten' && result[i].week_date > current_date + week) {
                                                this.state.week_row = 'current-week no-arrows';
                                                this.state.readonly = true;
                                            } else {
                                                if (result[i].week_date < current_date) {
                                                    //console.log(result[i].week_date);
                                                    this.state.week_row = 'past-week no-arrows';
                                                    this.state.readonly = true;
                                                }
                                                if (current_date < result[i].week_date && current_date < result[i].week_date + (week * 2)) {
                                                    this.state.week_row = 'current-week no-arrows';
                                                    this.state.readonly = true;
                                                }
                                                if (result[i].week_date > current_date + week) {
                                                    this.state.week_row = 'upcoming-week no-arrows';
                                                    this.state.readonly = true;
                                                }
                                            }

                                        }else{
                                            if (result[i].week == 'week_ten' && result[i].week_date > current_date + week) {
                                                this.state.week_row = 'current-week';
                                                this.state.readonly = false;
                                            } else {
                                                if (result[i].week_date < current_date) {
                                                    //console.log(result[i].week_date);
                                                    this.state.week_row = 'past-week';
                                                    this.state.readonly = false;
                                                }
                                                if (current_date < result[i].week_date && current_date < result[i].week_date + (week * 2)) {
                                                    this.state.week_row = 'current-week';
                                                    this.state.readonly = false;
                                                }
                                                if (result[i].week_date > current_date + week) {
                                                    this.state.week_row = 'upcoming-week';
                                                    this.state.readonly = false;
                                                }
                                            }
                                        }


                                        switch(result[i].week){
                                            case 'week_ten':
                                                this.weekName = '10 Weeks Out';
                                                this.currentWeekName = 'week_ten'
                                                break;
                                            case 'week_nine':
                                                this.weekName = '9 Weeks Out';
                                                this.currentWeekName = 'week_nine'
                                                break;
                                            case 'week_eight':
                                                this.weekName = '8 Weeks Out';
                                                this.currentWeekName = 'week_eight'
                                                break;
                                            case 'week_seven':
                                                this.weekName = '7 Weeks Out';
                                                this.currentWeekName = 'week_seven'
                                                break;
                                            case 'week_six':
                                                this.weekName = '6 Weeks Out';
                                                this.currentWeekName = 'week_six'
                                                break;
                                            case 'week_five':
                                                this.weekName = '5 Weeks Out';
                                                this.currentWeekName = 'week_five'
                                                break;
                                            case 'week_four':
                                                this.weekName = '4 Weeks Out';
                                                this.currentWeekName = 'week_four'
                                                break;
                                            case 'week_three':
                                                this.weekName = '3 Weeks Out';
                                                this.currentWeekName = 'week_three'
                                                break;
                                            case 'week_two':
                                                this.weekName = '2 Weeks Out';
                                                this.currentWeekName = 'week_two'
                                                break;
                                            case 'week_one':
                                                this.weekName = '1 Week Out';
                                                this.currentWeekName = 'week_one'
                                                break;
                                            default:
                                                this.weekName = '';
                                                break;

                                        }


                                        if(this.user_edit !== true) {
                                            return (<>
                                                    <tr className={this.state.week_row} id={this.currentWeekName}
                                                        ref={this.getWeekName}>
                                                        <td className="weeks-table">{this.weekName} - {readable_week}</td>
                                                        {(() => {
                                                            let td = [];
                                                            for (let p = 0; p < result[i].product_info.length; p++) {
                                                                td.push(<td>{<input className="serving"
                                                                                    readOnly={this.state.readonly}
                                                                                    name="serving"
                                                                                    id={result[i].week + '-' + result[i].product_info[p].id}
                                                                                    step=".5" min="0" type="number"
                                                                                    defaultValue={result[i].product_info[p].serving}
                                                                                    ref={this.userServing}
                                                                                    onChange={this.carbPlusMinus}/>}</td>);

                                                            }
                                                            return td;
                                                        })()}
                                                        <td className={this.state.carb_actual_class}>
                                                            {this.state.carb_actual_add[result[i].week]}


                                                            <OverlayTrigger trigger="click" placement="right" overlay={this.carbover}>
                                                                    <span className="carb-icon">
                                                                        {this.state.faicon}
                                                                    </span>
                                                            </OverlayTrigger>



                                                        </td>
                                                        <td>{result[i].carb_goal}</td>
                                                        <td className={this.state.sodium_actual_class}>
                                                            {this.state.sodium_actual_add[result[i].week]}


                                                            <OverlayTrigger trigger="click" placement="right" overlay={this.naover}>
                                                                    <span className="sodium-icon">
                                                                        {this.state.naicon}
                                                                    </span>
                                                            </OverlayTrigger>


                                                        </td>
                                                        <td>{[result[i].na_goal]} </td>
                                                        <td>{result[i].fl_goal}</td>

                                                    </tr>

                                                </>

                                            )
                                        }else{
                                            return (<>
                                                    <tr className={this.state.week_row} id={this.currentWeekName} ref={this.getWeekName}>
                                                        <td className="weeks-table">{this.weekName} - {readable_week}</td>
                                                        { ( () => {
                                                            let td = [];
                                                            for(let p = 0; p < result[i].product_info.length; p++) {
                                                                td.push(<td>{<input className="serving" readOnly={this.state.readonly} name="serving" id={result[i].week + '-' + result[i].product_info[p].id} step=".5" min="0" type="number" defaultValue={result[i].product_info[p].weekly_serving[this.currentWeekName]} ref={this.userServing} onChange={this.carbPlusMinus}/>}</td>);

                                                            }
                                                            return td;
                                                        } ) () }
                                                        <td className={this.state.carb_actual_class}>
                                                            {this.state.carb_actual_add[result[i].week]}


                                                                <OverlayTrigger trigger="click" placement="right" overlay={this.carbover}>
                                                                    <span className="carb-icon">
                                                                        {this.state.faicon}
                                                                    </span>
                                                                </OverlayTrigger>



                                                        </td>
                                                        <td>{result[i].carb_goal}</td>
                                                        <td className={this.state.sodium_actual_class}>
                                                            {this.state.sodium_actual_add[result[i].week]}

                                                                <OverlayTrigger trigger="click" placement="right" overlay={this.naover}>
                                                                    <span className="sodium-icon">
                                                                        {this.state.naicon}
                                                                    </span>
                                                                </OverlayTrigger>


                                                        </td>
                                                        <td>{[result[i].na_goal]}</td>
                                                        <td>{result[i].fl_goal}</td>


                                                    </tr>

                                                </>

                                            )
                                        }
                                    }

                                })
                            }


                        </tbody>
                        </table>

                    </div>

                </div>
                    <div className="quick-add-product col">
                        <a href={"/client-dashboard/gut-training/edit/" + this.id}><FontAwesomeIcon icon={faPlusCircle}/> Add/Remove Products</a>
                    </div>
            </div>
            </div>

        )
    }


}

export default withParams(GutDetails);
