import React from 'react';
import 'react-tabs/style/react-tabs.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';


function Navbar(){

    return (
        <div className="nav-container">
        <nav className="client-dash-nav container-xl">
            <div className="row">
                <div className="col">
                    <a href={"/client-dashboard/"}><img src={require("../assets/imgs/Dashboard.png")}/> <span className="nav-text">Dashboard</span></a>
                </div>
                <div className="col">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <img src={require("../assets/imgs/Protocols.png")}/> <span className="nav-text">Protocols</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href={"/client-dashboard/sweat-testing"}>Sweat Testing</Dropdown.Item>
                            <Dropdown.Item href={"/client-dashboard/gut-training"}>Gut Training</Dropdown.Item>
                            <Dropdown.Item href={"/client-dashboard/race-plans"}>Race Plans</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className={"col"}>
                    <a href={"/client-dashboard/account"}> <img src={require("../assets/imgs/Profile.png")}/> <span className="nav-text">Profile</span></a>
                </div>

            </div>
        </nav>
        </div>
    );


}




export default Navbar;