import React, { Component } from "react";
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpLong, faXmarkCircle} from '@fortawesome/free-solid-svg-icons';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/db.js';


class GetSweatTable extends Component {
    constructor(props) {
        super(props);


        this.state={newData: [],};
        this.removeSweat = this.removeSweat.bind(this);
        this.sweat_id = React.createRef();
        this.componentDidMount = this.componentDidMount();
    }

    removeSweat = async (event) => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;

        let sweat_id = event.currentTarget.id;

        console.log(sweat_id);

        let result = window.confirm("Are you sure you want to delete this?");

        if(result) {
            await deleteDoc(doc(db, "users", userID, 'sweat-test', sweat_id));
            window.location.reload(false);
        }
    }

    componentDidMount = async () =>{
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        const { newData } = this.state;

        console.log(userID);

        const docSnap = await getDocs(collection(db, "users", userID, 'sweat-test'));
        docSnap.forEach((doc) => {

            let start_weight = doc.data().start_weight;
            let end_weight =  doc.data().end_weight;
            let weight_diff = start_weight - end_weight;
            let drink_volume = parseFloat(doc.data().dvolume);
            let duration = parseFloat(doc.data().duration/60);

            console.log(duration);

            let total_sweat_loss = doc.data().total_sweat_loss;
            let sweat_rate = doc.data().sweat_rate;


                let obj = {
                    ['id']:doc.id,
                    ['sdate']:doc.data().sdate,
                    ['type']:doc.data().type,
                    ['temp']:doc.data().temp,
                    ['humidity']:doc.data().humidity,
                    ['duration']:doc.data().duration,
                    ['start_weight']:doc.data().start_weight,
                    ['end_weight']:doc.data().end_weight,
                    ['body_weight_change']:doc.data().body_weight_change,
                    ['dvolume']:doc.data().dvolume,
                    ['total_sweat_loss']:total_sweat_loss,
                    ['sweat_rate']:sweat_rate,

                }
                console.log(obj);
            newData.push(obj);


        });


        $(document).ready(function () {
            setTimeout(function () {
                $('#sweatTable').DataTable({
                    "ordering": true,
                    order:[[0, 'desc']],
                    scrollX: true,
                    scrollY: true
                });
            });
        });

        this.setState({newData});


    }
    render()
    {
        //console.log(this.state.newData)
        return (
            <div>
            <table id="sweatTable" class="sweat-table">
                <thead>
                <tr>
                    <th>
                        Date
                    </th>
                    <th>
                        Session Type
                    </th>
                    <th>
                        Session Duration (min)
                    </th>
                    <th>
                        Temperature (F)
                    </th>
                    <th>
                        Humidity (%)
                    </th>
                    <th>
                        Start Body Weight (lbs)
                    </th>
                    <th>
                        End Body Weight (lbs)
                    </th>
                    <th>
                        Drink Volume (oz)
                    </th>
                    <th>
                        Total Sweat Loss (oz)
                    </th>
                    <th>
                        Sweat Rate (oz/hr)
                    </th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                {this.state.newData.map((result) => {
                    console.log(result.id);
                    return(
                        <tr>
                            <td>{result.sdate}</td>
                            <td>{result.type}</td>
                            <td>{result.duration}</td>
                            <td>{result.temp}</td>
                            <td>{result.humidity}</td>
                            <td>{result.start_weight}</td>
                            <td>{result.end_weight}</td>
                            <td>{result.dvolume}</td>
                            <td>{result.total_sweat_loss}</td>
                            <td>{result.sweat_rate}</td>
                            <td><a href="#"  id={result.id} ref={this.sweat_id} className="sweat-remove" onClick={this.removeSweat}><FontAwesomeIcon icon={faXmarkCircle} /></a> </td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            </div>
    )

    }

}

export default GetSweatTable;