import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { logout } from './firebase/auth';
import { userAuth } from './firebase/auth';
import { SignOutButton } from './firebase/signOutButton.js';

export const Header = () =>{

    return(
        <header className="App-header">
            <div className="header-inner row">


                <div className="col">
                    <a href="/client-dashboard">
                    <div className="logo">
                        <img src={require("./assets/imgs/sgt-logo.png")}/>


                    </div>
                    </a>
                </div>

                <div className="col">
                    <SignOutButton />
                </div>

            </div>
        </header>
    );
}


