import React, { Component } from "react";
import { doc, setDoc, collection, getDoc, addDoc } from "firebase/firestore";
import { db } from '../../firebase/db.js';
import { useState, useForm } from 'react-hook-form';
import { getAuth } from 'firebase/auth';
import { Spinner } from 'react-bootstrap';
import GetSweatTable from './getSweatTable.js';


function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

class SweatForm extends Component {
    constructor(props){
        super(props);
        this.nextSweatTestNumber = 0;
        this.state = {value: ''};
        this.state = {loading: false}
        this.bodyWeightLoss = 0;
        this.dvolume = 0;
        this.date = 0;
        this.sweatObject = {
            ['sdate']: 0,
            ['dvolume']: 0,
            ['start_weight']: 0,
            ['duration']: 0,
            ['temp']: 0,
            ['end_weight']: 0,
            ['type']: 'Run - Outdoor',
            ['humidity']: 0,
            ['body_weight_change']: 0,
            ['total_sweat_loss']: 0,
            ['sweat_rate']: 0,
        };
        this.calcWeightChange();

        this.addSweatSubmit = this.addSweatSubmit.bind(this);
        this.dateInput = this.dateInput.bind(this);
        this.volumeInput = this.volumeInput.bind(this);
        this.tempInput = this.tempInput.bind(this);
        this.startWeightInput = this.startWeightInput.bind(this);
        this.durationInput = this.durationInput.bind(this);
        this.typeInput = this.typeInput.bind(this);
        this.endWeightInput = this.endWeightInput.bind(this);
        this.humidityInput = this.humidityInput.bind(this);


    }


    dateInput(event){
        this.sweatObject.sdate = event.target.value
        console.log(this.sweatObject);
    }

    volumeInput(event){
        this.sweatObject.dvolume = event.target.value
        console.log(this.sweatObject);
    }
    startWeightInput(event){
        this.sweatObject.start_weight = event.target.value
        this.calcWeightChange();
        console.log(this.sweatObject);
    }
    durationInput(event){
        this.sweatObject.duration = event.target.value
        console.log(this.sweatObject);
    }
    tempInput(event){
        this.sweatObject.temp = event.target.value
        console.log(this.sweatObject);
    }
    endWeightInput(event){
        this.sweatObject.end_weight = event.target.value
        this.calcWeightChange();
        console.log(this.sweatObject);
    }
    typeInput(event){
        this.sweatObject.type= event.target.value
        console.log(this.sweatObject);
    }
    humidityInput(event){
        this.sweatObject.humidity = event.target.value
        console.log(this.sweatObject);
    }
    calcWeightChange() {

        let startWeight = this.sweatObject.start_weight;
        let endWeight = this.sweatObject.end_weight;
        let weightDif = parseInt(startWeight) - parseInt(endWeight);
        let bodyWeightLoss = (weightDif / startWeight) * 100;
        bodyWeightLoss = bodyWeightLoss.toFixed(2);

        this.sweatObject.body_weight_change = bodyWeightLoss;

        let drink_volume = parseInt(this.sweatObject.dvolume);


        let total_sweat_loss = (weightDif * 16) + drink_volume;

        this.sweatObject.total_sweat_loss = total_sweat_loss;



        let duration = parseInt(this.sweatObject.duration)/60;


        let sweat_rate = parseFloat(total_sweat_loss/duration).toFixed(1);

        this.sweatObject.sweat_rate = sweat_rate;

        this.setState({bodyWeightLoss});
        this.setColor = '#f79578';

        console.log(this.sweatObject);
    }

    addSweatSubmit = async (e) => {
        e.preventDefault();

        AddLoadingGif();
        await this.updateSweatTestData().then(() => {
            this.setState({ loading: false });

        });


    }

    updateSweatTestData = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;

        await addDoc(collection(db, 'users', userID, 'sweat-test'), {
            ['timestamp']:Date.now(),
            ['sdate']:this.sweatObject.sdate,
            ['dvolume']:this.sweatObject.dvolume,
            ['start_weight']:this.sweatObject.start_weight,
            ['duration']:this.sweatObject.duration,
            ['temp']:this.sweatObject.temp,
            ['end_weight']:this.sweatObject.end_weight,
            ['type']:this.sweatObject.type,
            ['humidity']:this.sweatObject.humidity,
            ['body_weight_change']:this.sweatObject.body_weight_change,
            ['total_sweat_loss']:this.sweatObject.total_sweat_loss,
            ['sweat_rate']:this.sweatObject.sweat_rate,
        })

        window.location.reload(false);


    }

    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };


    render(){

        return (
            <div className="sweatForm">

                <form onSubmit={this.addSweatSubmit}>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="date">Date</label><br/>
                            <input name="date" type="date" value={this.state.fdate} onChange={this.dateInput}  />
                            <label htmlFor="dvolume">Drink Volume Consumed (oz)</label><br/>
                            <input name="dvolume" type="number" step=".1" value={this.state.dvolume} onChange={this.volumeInput} />
                            <label htmlFor="start-weight">Start Body Weight (lbs)</label><br/>
                            <input name="start-weight" type="number" step=".1" value={this.state.startWeight} onChange={this.startWeightInput} />
                        </div>
                        <div className="col">
                            <label htmlFor="session-duration">Session Duration (min)</label><br/>
                            <input name="session-duration" type="number" value={this.state.duration} onChange={this.durationInput} />
                            <label htmlFor="temp">Temperature (F)</label><br/>
                            <input name="temp" type="number" value={this.state.temp} onChange={this.tempInput} />
                            <label htmlFor="end-weight">End Body Weight (lbs)</label><br/>
                            <input name="end-weight" type="number" step=".1" value={this.state.endWeight} onChange={this.endWeightInput} />
                        </div>
                        <div className="col">
                            <label htmlFor="session-type">Session Type</label><br/>
                            <select name="session-type" value={this.state.type} onChange={this.typeInput} >
                                <option value="Run Outdoor">Run Outdoor</option>
                                <option value="Run Indoor">Run Indoor</option>
                                <option value="Bike Outdoor">Bike Outdoor</option>
                                <option value="Bike Indoor">Bike Indoor</option>
                            </select>
                            <label htmlFor="humidity">Humidity (%)</label><br/>
                            <input name="humidity" type="number" value={this.state.humidity} onChange={this.humidityInput} />
                            <label htmlFor="body-weight-change">% Body Weight Change</label><br/>
                            <input name="body-weight-change" style={{background: `${this.setColor}`}} type="body-weight-change" value={this.state.bodyWeightLoss} />
                        </div>

                    </div>
                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Submit Session</button>


                </form>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }

            </div>

        )
    }



}

export default SweatForm;