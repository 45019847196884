import React, {Component} from 'react';

import {getAuth, onAuthStateChanged} from "firebase/auth";

import Navbar from "../../components/Navbar";
import {Dropdown} from "react-bootstrap";
import AddRacePlan from "../../functions/user-functions/addRacePlan";
import {doc, getDoc, collection, getDocs, deleteDoc} from "firebase/firestore";
import {db} from "../../firebase/db";

class Race extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSession: true,
            newData: [],
        };


        this.handleClick = this.handleClick.bind(this);

        this.componentDidMount = this.componentDidMount();

    }



    componentDidMount = async () => {

        const auth = getAuth();
        const user = auth.currentUser;

        if (user !== null) {
            const user_id = user.uid;

            const {newData} = this.state;

            console.log(user_id);


            const docSnap = await getDocs(collection(db, "users", user_id, 'race-plan'));
            docSnap.forEach((doc) => {

                const race_link = "/client-dashboard/race-plan/edit/" + doc.id;
                const view_link = "/client-dashboard/race-plan/details/" + doc.id;
                let obj = {
                    ['id']:doc.id,
                    ['race_name']: doc.data().race_name,
                    ['race_date']: doc.data().race_date,
                    ['race_link']: race_link,
                    ['view_link']: view_link,

                }
                newData.push(obj);

            });

            this.setState({newData});

        }

    }

    handleClick() {
        this.setState(showSession => ({
            isToggleOn: !showSession.isToggleOn
        }));

        this.setState({showQuestions:'hide-questions'})

    }

    remove_race = async (event) => {
        let race_id = event.target.attributes.getNamedItem('data-id').value;

        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;

        const gutSnap = await getDocs(collection(db, "users", user_id, 'gut-training'));

        let gut_id_array = [];
        gutSnap.forEach((doc) => {
           let race_details = doc.data().race_details;

           if(race_details.id === race_id){

               gut_id_array.push(doc.id);
           }
        });

        await deleteDoc(doc(db, "users", user_id, "race-plan", race_id));
        await deleteDoc(doc(db, "users", user_id, "gut-training", gut_id_array[0]));

        window.location.reload(false);
    }

    render() {
        return (
            <div className="pageContainer">
                <Navbar/>


                <div className="myRacePlanContainer">
                    <div className="race-plan-container">
                        <h3 className="module-title">Race Plans</h3>
                        {this.state.newData.map((result) => {
                            return (

                                <div className="race-plan-single row">
                                    <div className="gutTrainingLeft col-10">
                                        <h4 className="fw-bolder">{result.race_name}</h4>
                                        <span className="fw-semibold module-date">
                                            {result.race_date}
                                        </span>
                                    </div>
                                    <div className="gutTrainingRight col-2">
                                            <div className="row">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                        ...
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>

                                                        <Dropdown.Item
                                                            href={result.race_link}>Edit
                                                            race details</Dropdown.Item>
                                                        <Dropdown.Item
                                                            href={result.view_link}>View
                                                            race details</Dropdown.Item>
                                                        <Dropdown.Item
                                                            data-id={result.id} onClick={this.remove_race} href="#">Delete Race</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>

                                    </div>
                                </div>


                            )
                        })}


                    </div>
                    <div className="race-plan-add-session">
                        <button onClick={this.handleClick}> Add Race Plan</button>
                    </div>
                    {this.state.isToggleOn ?
                        <div className="add-new-session-container">
                            <h3 className="module-title">Race Plans > New Race Plan > Race Details</h3>
                            <AddRacePlan/>
                        </div>: ''}
                </div>
            </div>
        )
    }
}

export default Race;