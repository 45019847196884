// Products time slots are a custom array [id,time_slot,servings,carbs,sodium]
import React, {Component} from 'react';
import {useSession} from '../../firebase/UserProvider';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {UserMetaForm} from "../../functions/user-functions/updateUserMeta";
import Navbar from "../../components/Navbar";
import {useSearchParams, useLocation, useParams} from 'react-router-dom';
import $ from "jquery";
import 'datatables.net-fixedcolumns-dt';
import {
    addDoc,
    collection,
    getDocs,
    updateDoc,
    doc,
    where,
    query,
    collectionGroup,
    getDoc,
    arrayUnion
} from "firebase/firestore";
import { db } from "../../firebase/db";
import {Dropdown, Spinner} from "react-bootstrap";
import AddRacePlan from "../../functions/user-functions/addRacePlan";
import Accordion from "react-bootstrap/Accordion";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong, faCheck, faPlusCircle, faInfoCircle, faFloppyDisk, faCircleCheck, faTriangleExclamation, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";


// average sweat rate should be based on temperature +-10°  --  doesn't matter how many

// carb goal each bike and run

// Notes
/*

Race Details

Last hour should round up to the half hour for last slot.
Products should be cut in half.

Bike and Run should start at 0




 */


function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function timeStringToFloat(time){
    var hoursMinutes = time.split(/[.:]/);
    var hours = hoursMinutes[0];
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return parseInt(hours);
}

class RaceDetails extends Component {
    constructor(props) {
        super(props);

        this.race_name = React.createRef();
        this.race_time = React.createRef();
        this.race_date = React.createRef();
        this.race_humidity = React.createRef();
        this.race_temp = React.createRef();
        this.race_type = React.createRef();
        this.bike_fuel = React.createRef();
        this.bike_hydration = React.createRef();
        this.run_fuel = React.createRef();
        this.run_hydration = React.createRef();
        this.bike_serving = React.createRef();
        this.run_serving = React.createRef();
        this.startWeight = [];
        this.drinkVolume = [];
        this.endWeight = [];
        this.sweat_duration = [];
        this.fluid_goal_per_hour = 0;
        this.raceTimeArray = [];
        this.updateProductsArray = [];
        this.current_bike_products = [];
        this.current_run_products = [];
        this.sweat_rate = [];
        this.slot_obj = {};
        this.user_edit = '';

        this.state = {
            loading: false,
            current_products: [],
            productInfo: [],
            race_name: '',
            race_duration: '',
            race_temp: '',
            race_start_time: '',
            race_date: '',
            race_humidity: '',
            race_type: '',
            race_time: '',
            id: '',
            hoursArray: [],
            hours: [],
            time: [],
            bikeArray: [],
            runArray: [],
            carb_goal: '0',
            na_goal: '0',
            fluid_goal: '0',
            current_bike_products: [],
            current_run_products: [],
            run_carb_actual: 0,
            run_na_actual: 0,
            bike_carb_actual: 0,
            bike_carb_count: 0,
            bike_time_slots: [],
            run_time_slots: [],
        }

        this.raceObject = {
            'race_name': '',
            'est_time': '',
            'race_temp': '',
            'race_date': '',
            'race_humidity': '',
            'race_type': '',
            'timestamp': '',

        };


        //this.componentDidMount = this.componentDidMount();

    }

    raceNameInput(event) {
        this.raceObject.race_name = event.target.value

    }

    raceTimeInput(event) {
        this.raceObject.race_time = event.target.value

    }

    raceTempInput(event) {
        this.raceObject.race_temp = event.target.value

    }

    raceDateInput(event) {
        this.raceObject.race_date = event.target.value

    }

    raceHumidityInput(event) {
        this.raceObject.race_humidity = event.target.value

    }

    raceTypeInput(event) {
        this.raceObject.race_type = event.target.value
    }




    componentDidMount = async () => {
        let {id} = this.props.params;

        const auth = getAuth();
        const user = auth.currentUser;


        // The user object has basic properties such as display name, email, etc.

        const user_id = user.uid;



        // function to produce averages


        const docSnap = await getDoc(doc(db, "users", user_id, 'race-plan', id));

        this.setState({id: docSnap.id});
        this.setState({race_name: docSnap.data().race_name});
        this.setState({race_time: docSnap.data().race_time});
        this.setState({race_temp: docSnap.data().race_temp});
        this.race_temp = docSnap.data().race_temp;
        this.setState({race_humidity: docSnap.data().race_humidity});
        this.setState({race_date: docSnap.data().race_date});
        this.setState({race_type: docSnap.data().race_type});
        this.setState({race_start_time: docSnap.data().race_start_time});
        if(docSnap.data.run_est_time !== undefined) {
            this.setState({run_est_time: docSnap.data().run_est_time});
        }else{
            this.setState({run_est_time: 0});
        }
        if(docSnap.data.bike_est_time !== undefined) {
            this.setState({bike_est_time: docSnap.data().run_est_time});
        }else{
            this.setState({bike_est_time: 0});
        }
        this.setState({userEdit: docSnap.data().user_edit});
        this.setState({current_weight: docSnap.data().current_weight})

        this.edit_url = "/client-dashboard/race-plan/edit/" + docSnap.id;

        this.race_time = docSnap.data().race_time;

        let fullRaceTime = docSnap.data().race_time;

        let hours = fullRaceTime / 60;

        this.current_weight = docSnap.data().current_weight;

        this.race_temp = docSnap.data().race_temp;

        this.race_type = docSnap.data().race_type;

        this.user_edit = docSnap.data().user_edit;
// get sweat testing from database --> needed for calculations
        const sweatSnap = await getDocs(collection(db, "users", user_id, 'sweat-test'));

        // loop through sweat-trainings to get averages
        sweatSnap.forEach((doc) => {
            this.startWeight.push(Number(doc.data().start_weight));
            let start_weight = doc.data().start_weight;
            this.endWeight.push(Number(doc.data().end_weight));
            let end_weight = doc.data().end_weight;
            this.drinkVolume.push(Number(doc.data().dvolume));
            this.sweat_duration.push(Number(doc.data().duration));
            let sweat_rate = parseInt(doc.data().sweat_rate);
            let sweat_temp = parseInt(doc.data().temp);

            let weight_diff = Number(start_weight) - Number(end_weight);

            if(sweat_temp >= this.race_temp - 10 && sweat_temp <= this.race_temp + 10) {
                this.sweat_rate.push(sweat_rate);
            }

            

        });

        this.sweat_rate.sort(function(a, b){return b-a});

        if(this.sweat_rate.length > 3) {
            this.sweat_rate = this.sweat_rate.slice(0, 3);
        }

        const average = array => array.reduce((a, b) => a + b, 0) / array.length;

        // average all numbers for calculations
        let sweat_rate = average(this.sweat_rate);


        // average all numbers for calculations
        let average_start_weight = 0;
        let average_end_weight = 0;
        let average_drink_volume = 0;
        let sweat_duration = 0;

        if (this.startWeight.length > 1) {
            average_start_weight = average(this.startWeight);
            average_end_weight = average(this.endWeight);
            average_drink_volume = average(this.drinkVolume);
            sweat_duration = average(this.sweat_duration);
        } else {
            average_start_weight = this.startWeight;
            average_end_weight = this.endWeight;
            average_drink_volume = this.drinkVolume;
            sweat_duration = this.sweat_duration;
        }

        // CALCULATED FLUID GOAL
        let fluid_consume = docSnap.data().fluid_consume;

        let average_drink_volue;


        average_drink_volume = docSnap.data().fluids_value;


        // --> END CALCULATED FLUID GOAL

        // CALCULATE CARB GOAL
        let carb_goal_per_hour = '';

        let carb_consume = docSnap.data().carb_consume;

        let length_of_event = this.race_time / 60;

        let carb = 30;

            if (length_of_event >= 3) {
                carb = 90;
                this.setState({carb_goal_per_hour:carb});
                this.carb_goal_per_hour = Number(carb);
            }

            if (length_of_event > 2) {
                if (length_of_event < 3) {
                    carb = 60;
                    this.setState({carb_goal_per_hour:carb});
                    this.carb_goal_per_hour = Number(carb);
                }
            }

            if (length_of_event <= 2) {
                carb = 30;
                this.setState({carb_goal_per_hour:carb});
                this.carb_goal_per_hour = Number(carb);
            }



        // --> END CALCULATE CARB GOAL


        // GET SWEAT TEST DATA

        let weight_diff = average_start_weight - average_end_weight;

        let total_sweat_loss = (weight_diff * 16) + average_drink_volume;


        let sweat_hours = sweat_duration / 60;


        let ounces_per_race = sweat_rate * length_of_event;


        let finish_loss_value = this.current_weight * .025;


        let tolerable_rate_of_loss = finish_loss_value * 16;



        // TODO length of event should be bike time + run time / 60
            // Single sport total_length_of_event -- 
        let consumed_per_hour = (ounces_per_race - tolerable_rate_of_loss) / length_of_event;




        consumed_per_hour = Math.round(consumed_per_hour * 100) / 100;


        for (let i = 0; i > 2; i++) {
            let low_range = consumed_per_hour - 5;
            let high_range = consumed_per_hour + 5;

            let consumed_text = low_range + ' - ' + high_range;

            this.setState({fluid_goal_per_hour: consumed_text});

        }

        let dehydration = parseInt(this.current_weight) * .025 * 16;

        let dehydration_per_hour = dehydration / 60;

        let sweat_loss = parseInt(dehydration_per_hour) * parseInt(sweat_rate.toFixed(2));

        let fluid_goal_low = sweat_loss - 5;
        let fluid_goal_high = sweat_loss + 5;

        let fluid_goal_text = fluid_goal_low + ' - ' + fluid_goal_high;

        this.setState({fluid_goal_per_hour: fluid_goal_text});

        let sodium_consume = docSnap.data().sodium_consume;
        let sodium_question = docSnap.data().question


        this.sodium_value = 0;

        if(sodium_consume === 'yes') {
            this.sodium_value = Number(docSnap.data().sodium_value);
        }else{
            let sodium_question = docSnap.data().sodium_question;

            switch(sodium_question) {
                case 'a': this.sodium_value = 18;
                    break;
                case 'b': this.sodium_value = 38;
                    break;
                case 'c': this.sodium_value = 70;
                    break;
            }
        }
        let sodium_calc = this.sodium_value * sweat_loss;

        this.setState({sodium_goal_per_hour: sodium_calc});

        // I'm using this to display the carb goal

            if (length_of_event > 3) {
                this.setState({carbs_per_hour: 90})
            }
            if (length_of_event >= 2 && length_of_event < 3) {
                this.setState({carbs_per_hour: 60})
            }
            if (length_of_event < 2) {
                this.setState({carbs_per_hour: 30});
            }


            if (this.state.race_temp < 60) {
                this.setState({na_per_hour: 1200})
            }
            if (this.state.race_temp >= 60 && this.state.race_temp < 80) {
                this.setState({na_per_hour: 1500})
            }
            if (this.state.race_temp <= 80) {
                this.setState({na_per_hour: 2000});
            }



        // initialize product state
        const {productInfo} = this.state;

        // get all products from firebase
        const prodSnap = await getDocs(collection(db, "products"));

        // loop through products
        prodSnap.forEach((doc) => {

            let obj = {
                'product_id': doc.id,
                'product_name': doc.data().name,
                'type': doc.data().type,
                'product_flavor': doc.data().flavor,

            }


            this.state.productInfo.push(obj);
        });

        //sort products
        this.state.productInfo.sort((a, b) => (a.product_name > b.product_name) ? 1 : ((b.product_name > a.product_name) ? -1 : 0));


        // set state all products and info
        this.setState({productInfo});


        const {current_bike_products} = this.state;
        if(docSnap.data().bike.products) {
            Object.values(docSnap.data().bike.products).forEach((doc) => {
                // set current products into temp object

                if (doc.carb_actual) {
                    this.setState({bike_carb_actual: doc.carb_actual});
                } else {
                    this.setState({bike_carb_actual: 0})
                }

                let prodObject = {
                    'product_id': doc.product_id,
                    'name': doc.name,
                    'calories': doc.calories,
                    'carbs': doc.carbs,
                    'sodium': doc.sodium,
                    'type': doc.type,
                    'flavor': doc.flavor,
                    'carb_count': 0,
                    'na_count': 0,
                    'serving': 0,
                    'time_slots':doc.time_slots

                }

                // push object into state array
                this.state.current_bike_products.push(prodObject);
                this.current_bike_products.push(prodObject);

            });
        }

    this.setState({current_bike_products});


        const {current_run_products} = this.state;

        if(docSnap.data().run.products) {

            Object.values(docSnap.data().run.products).forEach((doc) => {

                if (doc.carb_actual) {
                    this.setState({run_carb_actual: doc.carb_actual});
                } else {
                    this.setState({run_carb_actual: 0})
                }

                // set current products into temp object

                let prodObject = {
                    'product_id': doc.product_id,
                    'name': doc.name,
                    'calories': doc.calories,
                    'carbs': doc.carbs,
                    'sodium': doc.sodium,
                    'type': doc.type,
                    'flavor': doc.flavor,
                    'carb_count': 0,
                    'na_count': 0,
                    'serving': 0,
                    'time_slots':doc.time_slots

                }


                this.state.current_run_products.push(prodObject);

            });
        }

        // loop through each gut training array of products for bike

        this.setState({current_run_products})

    if(this.user_edit !== true) {
        if (this.state.current_bike_products) {
            // loop through each gut training array of products for bike


            let carb_goal = this.carb_goal_per_hour;
            let na_goal = Number(this.state.sodium_goal_per_hour);
            let carb_actual = 0;
            let loopCount = 0;
            let carb_count = 0;
            let sodium_count = 0;
            let sodium_actual = 0;
            let serving = 0;
            this.carb_count = 0;
            let p = 0;


            if (this.state.current_bike_products.length > 0) {
                while (carb_count < carb_goal) {
                    if (this.state.bike_est_time !== '') {


                        console.log(p);
                        serving = 0.5;

                        loopCount += 1;

                        let carb = this.state.current_bike_products[p].carbs * serving;
                        let sodium = this.state.current_bike_products[p].sodium * serving;
                        sodium_count += sodium;
                        carb_count += carb;

                        console.log(this.carb_count);
                        this.state.current_bike_products[p].serving += serving;
                        this.state.current_bike_products[p].carb_count = carb_count;
                        this.current_bike_products[p].carb_count = carb_count;
                        this.bike_carb_count = carb_count;
                        this.state.current_bike_products[p].na_count = sodium_count;
                        this.current_bike_products[p].na_count = sodium_count;
                        this.state.current_bike_products[p].carb_goal = carb_goal;
                        this.current_bike_products[p].carb_goal = carb_goal;


                        this.state.bike_na_actual = sodium_count;


                    }

                    this.setState({bike_carb_actual: this.bike_carb_count});
                    p += 1;
                    if (p > this.state.current_bike_products.length - 1)
                        p = 0;

                    loopCount += 1;
                }
            }
        }

        if (this.state.current_run_products) {

            let carb_goal = this.carb_goal_per_hour;
            let na_goal = Number(this.state.sodium_goal_per_hour);
            let carb_actual = 0;
            let loopCount = 0;
            let carb_count = 0;
            let sodium_count = 0;
            let sodium_actual = 0;
            let serving = 0;
            this.carb_count = 0;
            let i = 0;


            if (this.state.current_run_products.length > 0) {
                while (carb_count < carb_goal) {
                    if (this.state.run_est_time !== '') {


                        console.log(i);
                        serving = 0.5;


                        let carb = this.state.current_run_products[i].carbs * serving;
                        let sodium = this.state.current_run_products[i].sodium * serving;
                        sodium_count += sodium;
                        carb_count += carb;
                        console.log(carb_count);
                        this.state.current_run_products[i].serving += serving;
                        this.state.current_run_products[i].carb_count = carb_count;
                        //this.current_run_products[p].carb_count = carb_count;
                        this.run_carb_count = carb_count;
                        this.state.current_run_products[i].na_count = sodium_count;
                        //this.current_run_products[p].na_count = sodium_count;
                        this.state.current_run_products[i].carb_goal = carb_goal;
                        //this.current_run_products[p].carb_goal = carb_goal;


                        this.state.run_na_actual = sodium_count;

                        console.log(carb_count);

                    }

                    this.setState({run_carb_actual: this.run_carb_count});

                    i += 1;
                    if (i > this.state.current_run_products.length - 1)
                        i = 0;

                    loopCount += 1;

                }
            }


        }
    // If User Edit is true
    }else{

        if (this.state.current_bike_products) {
            // loop through each gut training array of products for bike
            this.state.bike_na_actual = 400;
            this.setState({bike_na_actual:400});
            this.setState({bike_carb_actual:30})
            console.log(this.state.bike_na_actual);
        }



    }

        // BIKE POPUPS
        console.log(this.state.bike_carb_actual);
        console.log(this.state.carb_goal);
        // If carb goal is between -5 and -10

        if(this.user_edit === false) {
                    console.log('user edit off')
            if (this.state.bike_carb_actual < this.state.carb_goal - 5 && this.state.bike_carb_actual > this.state.carb_goal - 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikecarbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            // If carb goal is between +5 and +10
            if (this.state.bike_carb_actual > this.state.carb_goal + 5 && this.state.bike_carb_actual < this.state.carb_goal + 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.bikecarbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
                // Else if carb goal is in normal range
            }

            // If carb goal is between +5 and +10
            if (this.state.bike_carb_actual > this.state.carb_goal + 5 && this.state.bike_carb_actual < this.state.carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-five';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
                // Else if carb goal is in normal range
            }
            if (this.state.bike_carb_actual >= this.state.carb_goal - 5 && this.state.bike_carb_actual <= this.state.carb_goal + 5) {
                this.state.bike_carb_actual_class = 'carb-actual-ok';
                this.state.bikefaicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.bikecarbover = (

                    <Popover id="product-popover">

                    </Popover>
                );
            }


            // If carb goal is above +10 or below -10
            if (this.state.bike_carb_actual <= this.state.carb_goal - 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikecarbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (this.state.bike_carb_actual >= this.state.carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }


            if (this.state.bike_na_actual < this.state.sodium_goal_per_hour - 250 && this.state.bike_na_actual >= this.state.sodium_goal_per_hour - 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-five';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }

            if (this.state.bike_na_actual > this.state.sodium_goal_per_hour + 250 && this.state.bike_na_actual <= this.state.sodium_goal_per_hour + 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-five';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (this.state.bike_na_actual <= this.state.sodium_goal_per_hour + 250 && this.state.bike_na_actual >= this.state.sodium_goal_per_hour - 250) {
                this.state.bike_sodium_actual_class = 'sodium-actual-ok';
                this.state.bikenaicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.bikenaover = (

                    <Popover id="product-popover">

                    </Popover>
                );
            }

            console.log(this.state.sodium_goal_per_hour);
            // If sodium goal is above +1000 or below -1000
            if (this.state.bike_na_actual < this.state.sodium_goal_per_hour - 500 || this.state.bike_na_actual > this.state.sodium_goal_per_hour + 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-ten';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium level is off. Choose an alt product or adjust serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }

            // RUN POPUPS

            // If carb goal is between -5 and -10
            if (this.state.run_carb_actual < this.state.carb_goal - 5 && this.state.run_carb_actual > this.state.carb_goal - 10) {
                this.state.run_carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            // If carb goal is between +5 and +10
            if (this.state.run_carb_actual > this.state.carb_goal + 5 && this.state.run_carb_actual < this.state.carb_goal + 10) {
                this.state.run_carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
                // Else if carb goal is in normal range
            }

            // If carb goal is between +5 and +10
            if (this.state.run_carb_actual > this.state.carb_goal + 5 && this.state.run_carb_actual < this.state.carb_goal + 10) {
                this.state.run_carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
                // Else if carb goal is in normal range
            }
            if (this.state.run_carb_actual >= this.state.carb_goal - 5 && this.state.run_carb_actual <= this.state.carb_goal + 5) {
                this.state.run_carb_actual_class = 'carb-actual-ok';
                this.state.faicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.carbover = (

                    <Popover id="product-popover">

                    </Popover>
                );
            }


            // If carb goal is above +10 or below -10
            if (this.state.run_carb_actual <= this.state.carb_goal - 10) {
                this.state.run_carb_actual_class = 'carb-actual-ten';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (this.state.run_carb_actual >= this.state.carb_goal + 10) {
                this.state.run_carb_actual_class = 'carb-actual-ten';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }

            if (this.state.run_na_actual < this.state.sodium_goal_per_hour - 250 && this.state.run_na_actual >= this.state.sodium_goal_per_hour - 500) {
                this.state.run_sodium_actual_class = 'sodium-actual-five';
                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.naover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (this.state.run_na_actual > this.state.sodium_goal_per_hour + 250 && this.state.run_na_actual <= this.state.sodium_goal_per_hour + 500) {
                this.state.run_sodium_actual_class = 'sodium-actual-five';
                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.naover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }


            // If sodium goal is above +1000 or below -1000
            if (this.state.run_na_actual > this.state.sodium_goal_per_hour + 500 || this.state.run_na_actual < this.state.sodium_goal_per_hour - 500) {
                this.state.run_sodium_actual_class = 'sodium-actual-ten';
                this.state.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.naover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium level is off. Choose an alt product or adjust serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }


            if (this.state.run_na_actual >= this.state.sodium_goal_per_hour - 250 && this.state.run_na_actual <= this.state.sodium_goal_per_hour + 250) {
                this.state.run_sodium_actual_class = 'sodium-actual-ok';
                this.state.naicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.naover = (

                    <Popover id="product-popover">

                    </Popover>
                );
            }

        }


        /// This carb actual init function
        this.carb_actual_icon_color = (carb_actual) => {

            let carb_goal = this.carb_goal_per_hour;

            if (carb_actual < carb_goal - 5 && carb_actual > carb_goal - 10) {
                this.carb_actual_class = 'carb-actual-five';

            }
            if (carb_actual > carb_goal + 5 && this.carb_actual < carb_goal + 10) {
                this.carb_actual_class = 'carb-actual-five';

            }
            if (carb_actual > carb_goal + 5 && carb_actual < carb_goal + 10) {
                this.carb_actual_class = 'carb-actual-five';

            }
            // If carb goal is above +10 or below -10
            if (carb_actual <= carb_goal - 10) {
                this.carb_actual_class = 'carb-actual-ten';


            }
            if (carb_actual >= carb_goal + 10) {
                this.carb_actual_class = 'carb-actual-ten';


            }
            if (carb_actual >= carb_goal - 5 && carb_actual <= carb_goal + 5) {
                this.carb_actual_class = 'carb-actual-ok';




            }

            return this.carb_actual_class;

        }

        this.carb_actual_icon = (carb_actual) => {

            let carb_goal = this.carb_goal_per_hour;

            if (carb_actual < carb_goal - 5 && carb_actual > carb_goal - 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
            }
            if (carb_actual > carb_goal + 5 && this.carb_actual < carb_goal + 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
            }
            if (carb_actual > carb_goal + 5 && carb_actual < carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-five';
                this.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
            }
            // If carb goal is above +10 or below -10
            if (carb_actual <= carb_goal - 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

            }
            if (carb_actual >= carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

            }
            if (carb_actual >= carb_goal - 5 && carb_actual <= carb_goal + 5) {
                this.state.bike_carb_actual_class = 'carb-actual-ok';
                this.faicon = <FontAwesomeIcon icon={faCircleCheck}/>;


            }
            return this.faicon;

        }
        this.carb_actual_init = (carb_actual) => {

            console.log(this.carb_goal_per_hour);
            let carb_goal = this.carb_goal_per_hour;

            if (carb_actual < carb_goal - 5 && carb_actual > carb_goal - 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );

            }
            // If carb goal is between +5 and +10
            if (carb_actual > carb_goal + 5 && carb_actual < carb_goal + 10) {
                this.state.carb_actual_class = 'carb-actual-five';
                this.state.faicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );


                // Else if carb goal is in normal range
            }

            // If carb goal is between +5 and +10
            if (carb_actual > carb_goal + 5 && carb_actual < carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-five';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carbs are slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );


                // Else if carb goal is in normal range
            }

            if (carb_actual >= carb_goal - 5 && carb_actual <= carb_goal + 5) {
                this.state.bike_carb_actual_class = 'carb-actual-ok';
                this.state.bikefaicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.carbover = (

                    <Popover id="product-popover">

                    </Popover>
                );


            }


            // If carb goal is above +10 or below -10
            if (carb_actual <= carb_goal - 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (carb_actual >= carb_goal + 10) {
                this.state.bike_carb_actual_class = 'carb-actual-ten';
                this.state.bikefaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.carbover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your carb levels are off. Choose an alt product or adjust
                                serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            return this.carbover;
        }

        this.na_actual_icon_color = (na_actual) => {
            console.log(na_actual)
            if (na_actual < this.state.sodium_goal_per_hour - 250 && na_actual >= this.state.sodium_goal_per_hour - 500) {
                this.na_actual_class = 'sodium-actual-five';

            }

            if (na_actual > this.state.sodium_goal_per_hour + 250 && na_actual <= this.state.sodium_goal_per_hour + 500) {
                this.na_actual_class = 'sodium-actual-five';

            }
            if (na_actual <= this.state.sodium_goal_per_hour + 250 && na_actual >= this.state.sodium_goal_per_hour - 250) {
                this.na_actual_class = 'sodium-actual-ok';

            }


            // If sodium goal is above +1000 or below -1000
            if (na_actual < this.state.sodium_goal_per_hour - 500 || na_actual > this.state.sodium_goal_per_hour + 500) {
                this.na_actual_class = 'sodium-actual-ten';

            }

            return this.na_actual_class;

        }

        this.na_actual_icon = (na_actual) => {
            console.log(na_actual)

            if (na_actual < this.state.sodium_goal_per_hour - 250 && na_actual >= this.state.sodium_goal_per_hour - 500) {

                this.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

            }

            if (na_actual > this.state.sodium_goal_per_hour + 250 && na_actual <= this.state.sodium_goal_per_hour + 500) {

                this.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

            }
            if (na_actual <= this.state.sodium_goal_per_hour + 250 && na_actual >= this.state.sodium_goal_per_hour - 250) {

                this.naicon = <FontAwesomeIcon icon={faCircleCheck}/>;

            }


            // If sodium goal is above +1000 or below -1000
            if (na_actual < this.state.sodium_goal_per_hour - 500 || na_actual > this.state.sodium_goal_per_hour + 500) {

                this.naicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;

            }
            return this.naicon;

        }


        this.na_actual_init = (na_actual) => {
                console.log(na_actual)
            if (na_actual < this.state.sodium_goal_per_hour - 250 && na_actual >= this.state.sodium_goal_per_hour - 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-five';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }

            if (na_actual > this.state.sodium_goal_per_hour + 250 && na_actual <= this.state.sodium_goal_per_hour + 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-five';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium is slightly off, look at an alt product or
                                adjusting serving size</b>

                        </Popover.Body>
                    </Popover>
                );
            }
            if (na_actual <= this.state.sodium_goal_per_hour + 250 && na_actual >= this.state.sodium_goal_per_hour - 250) {
                this.state.bike_sodium_actual_class = 'sodium-actual-ok';
                this.state.bikenaicon = <FontAwesomeIcon icon={faCircleCheck}/>;
                this.bikenaover = (

                    <Popover id="product-popover">

                    </Popover>
                );
            }


            // If sodium goal is above +1000 or below -1000
            if (na_actual < this.state.sodium_goal_per_hour - 500 || na_actual > this.state.sodium_goal_per_hour + 500) {
                this.state.bike_sodium_actual_class = 'sodium-actual-ten';
                this.state.bikenaicon = <FontAwesomeIcon icon={faTriangleExclamation}/>;
                this.bikenaover = (

                    <Popover id="product-popover">
                        <Popover.Body>
                            <b>Your sodium level is off. Choose an alt product or adjust serving to meet goals</b>

                        </Popover.Body>
                    </Popover>
                );
            }

            return this.bikenaover;


        }


        // initialize state for products that are currently in this gut training

        this.setState({bike_time_slots:docSnap.data().bike_time_slots});

        this.setState({run_time_slots: docSnap.data().run_time_slots});

        this.updateUserEdit = async (checked) => {
            await updateDoc(doc(db, "users", user_id, 'race-plan', id), {
                user_edit: checked
            });

            window.location.reload();
        }
        if(this.state.userEdit !== true) {
            this.state.readonly = true;
            this.setState({no_arrows:'no-arrows'});
        }

        // Carb plus minus
        this.carbPlusMinus = async (event) => {
            const auth = getAuth();
            const user = auth.currentUser;
            const user_id = user.uid;

            const {bike_carb_actual} = this.state;
            const {bike_sodium_actual} = this.state;

            let startServing = event.target.defaultValue;

            let serving = event.target.value;

            let inputID = event.target.id;

            let add_serving = .5;

            let inputArray = inputID.split(',');
            

            let productID = inputArray[0];
            let time_slot = inputArray[1];


            let updateItem = this.state.current_bike_products.find(element => element.product_id == productID);

            console.log(updateItem);

            let carbActualStart = parseFloat(this.state.bike_carb_actual);
            let sodiumActualStart = parseFloat(this.state.bike_carb_actual);

            let bike_carb_actual_plus = parseFloat(this.state.bike_carb_actual) + add_serving * .5;
            //this.state.bike_sodium_actual = parseFloat(this.state.bike_na_actual) + add_serving*this.updateItem.sodium;

            this.setState({bike_carb_actual:bike_carb_actual_plus});
            //this.setState({bike_carb_actual)};

            /*await updateDoc(doc(db, "users", user_id, 'gut-training', id), {
                bike:this.state.productInfo
            });
                */
            //this.serving_count = event.target.value;

            this.product_td = event.target.id;

        } // End Carb Plus Minus Function

        this.bike_product_minus = async (e) => {
            e.preventDefault();
            var upserving = 0;
            let productDone = structuredClone(this.state.current_bike_products);
            console.log(productDone);
            let current_product = e.currentTarget.parentNode.dataset.id;
            let starting_serving = e.currentTarget;
            console.log(starting_serving);
            console.log(current_product);
            let current_product_array = current_product.split(",");
            console.log(current_product_array);
            let current_product_id = current_product_array[0];

            let current_product_time = current_product_array[1];

            console.log(current_product_array[1]);
            let current_time_slots_array = productDone.find(({product_id}) => product_id === current_product_id ).time_slots;

            current_time_slots_array.forEach(function (item, index) {
                let current_time_slots = item.split(',');
                console.log(current_time_slots[0]);

                // if the clicked timeslot and product id match what is in the firebase time slot array
                if(current_time_slots[0] === current_product_id){
                    if(current_time_slots[1] === current_product_time){
                        console.log(current_time_slots[2]);
                        let local_serving = parseFloat(current_time_slots[2])
                        upserving =  local_serving - .5;
                        console.log(upserving)


                    }
                }
            })


            productDone.find(({product_id}) => product_id === current_product_id ).serving = upserving;

            let carb = productDone.find(({product_id}) => product_id === current_product_id ).carbs;
            this.state.bike_carb_actual = parseInt(carb) * upserving;

            let sodium = productDone.find(({product_id}) => product_id === current_product_id ).sodium;
            this.state.bike_na_actual = parseInt(sodium) * upserving;

            let new_time_slots = [];

            let current_serving = 0;

            console.log(productDone);
            productDone.forEach((product, i) => {
                console.log(product)
                if(product.product_id === current_product_id) {
                    let time_slots = product.time_slots;
                    time_slots.forEach(function (item, index) {
                        console.log(item);
                        let item_array = item.split(',');
                        let prod_id = item_array[0];
                        let time = item_array[1];
                        let current_serving = item_array[2];
                        if (prod_id === current_product_id) {
                            if (time === current_product_time) {
                                console.log(upserving)
                                let current_bike_carb = parseFloat(product.carbs) * parseFloat(upserving);
                                let current_bike_na = parseFloat(product.sodium) * parseFloat(upserving);
                                new_time_slots.push(prod_id + ',' + time + ',' + upserving + ',' + current_bike_carb + ',' + current_bike_na);

                            } else {
                                let current_bike_carb = parseFloat(product.carbs) * parseFloat(current_serving);
                                let current_bike_na = parseFloat(product.sodium) * parseFloat(current_serving);
                                new_time_slots.push(prod_id + ',' + time + ',' + current_serving + ',' + current_bike_carb + ',' + current_bike_na);
                            }


                        }
                        console.log(new_time_slots);
                    })


                }
            });



            let newProducts = [];

            productDone.forEach(function(item){
                if(item.product_id === current_product_id) {
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: new_time_slots

                    }
                    newProducts.push(tempProd);

                }else{
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: item.time_slots

                    }
                    newProducts.push(tempProd);
                }

            })

            console.log(this.state.current_bike_products);
            console.log(newProducts);

            await updateDoc(doc(db, "users", user_id, 'race-plan', id), {
                bike:{products:newProducts}
            });

            this.setState({current_bike_products:newProducts});
            console.log(this.state.current_bike_products);
        }



        this.bike_product_plus = async (e) => {
            e.preventDefault();
            var upserving = 0;
            let productDone = structuredClone(this.state.current_bike_products);
            console.log(productDone);
            let current_product = e.currentTarget.parentNode.dataset.id;
            let starting_serving = e.currentTarget;
            console.log(starting_serving);
            console.log(current_product);
            let current_product_array = current_product.split(",");
            console.log(current_product_array);
            let current_product_id = current_product_array[0];

            let current_product_time = current_product_array[1];

            console.log(current_product_array[1]);
            let current_time_slots_array = productDone.find(({product_id}) => product_id === current_product_id ).time_slots;

            current_time_slots_array.forEach(function (item, index) {
                let current_time_slots = item.split(',');
                console.log(current_time_slots[0]);

                // if the clicked timeslot and product id match what is in the firebase time slot array
                if(current_time_slots[0] === current_product_id){
                    if(current_time_slots[1] === current_product_time){
                        console.log(current_time_slots[2]);
                        let local_serving = parseFloat(current_time_slots[2])
                        upserving =  local_serving + .5;
                        console.log(upserving)


                    }
                }
            })


            productDone.find(({product_id}) => product_id === current_product_id ).serving = upserving;

            let carb = productDone.find(({product_id}) => product_id === current_product_id ).carbs;
            this.state.bike_carb_actual = parseInt(carb) * upserving;

            let sodium = productDone.find(({product_id}) => product_id === current_product_id ).sodium;
            this.state.bike_na_actual = parseInt(sodium) * upserving;

            let new_time_slots = [];

            let current_serving = 0;

            console.log(productDone);
            productDone.forEach((product, i) => {
                console.log(product)
                if(product.product_id === current_product_id) {
                    let time_slots = product.time_slots;
                    time_slots.forEach(function (item, index) {
                        console.log(item);
                        let item_array = item.split(',');
                        let prod_id = item_array[0];
                        let time = item_array[1];
                        let current_serving = item_array[2];
                        if (prod_id === current_product_id) {
                            if (time === current_product_time) {
                                console.log(upserving)
                                let current_bike_carb = parseFloat(product.carbs) * parseFloat(upserving);
                                let current_bike_na = parseFloat(product.sodium) * parseFloat(upserving);
                                new_time_slots.push(prod_id + ',' + time + ',' + upserving + ',' + current_bike_carb + ',' + current_bike_na);

                            } else {
                                let current_bike_carb = parseFloat(product.carbs) * parseFloat(current_serving);
                                let current_bike_na = parseFloat(product.sodium) * parseFloat(current_serving);
                                new_time_slots.push(prod_id + ',' + time + ',' + current_serving + ',' + current_bike_carb + ',' + current_bike_na);
                            }


                        }
                        console.log(new_time_slots);
                    })


                }
            });



            let newProducts = [];

            productDone.forEach(function(item){
                if(item.product_id === current_product_id) {
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: new_time_slots

                    }
                    newProducts.push(tempProd);

                }else{
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: item.time_slots

                    }
                    newProducts.push(tempProd);
                }

            })

            console.log(this.state.current_bike_products);
            console.log(newProducts);

           await updateDoc(doc(db, "users", user_id, 'race-plan', id), {
                bike:{products:newProducts}
            });

            this.setState({current_bike_products:newProducts});
            console.log(this.state.current_bike_products);
        }

        this.run_product_minus = async (e) => {
            e.preventDefault();
            var upserving = 0;
            let productDone = structuredClone(this.state.current_run_products);

            let current_product = e.currentTarget.parentNode.dataset.id;
            let starting_serving = e.currentTarget;

            let current_product_array = current_product.split(",");

            let current_product_id = current_product_array[0];

            let current_product_time = current_product_array[1];


            let current_time_slots_array = productDone.find(({product_id}) => product_id === current_product_id ).time_slots;

            current_time_slots_array.forEach(function (item, index) {
                let current_time_slots = item.split(',');
                console.log(current_time_slots[0]);

                // if the clicked timeslot and product id match what is in the firebase time slot array
                if(current_time_slots[0] === current_product_id){
                    if(current_time_slots[1] === current_product_time){
                        console.log(current_time_slots[2]);
                        let local_serving = parseFloat(current_time_slots[2])
                        upserving =  local_serving - .5;
                        console.log(upserving)


                    }
                }
            })


            productDone.find(({product_id}) => product_id === current_product_id ).serving = upserving;

            let carb = productDone.find(({product_id}) => product_id === current_product_id ).carbs;
            this.state.bike_carb_actual = parseInt(carb) * upserving;

            let sodium = productDone.find(({product_id}) => product_id === current_product_id ).sodium;
            this.state.bike_na_actual = parseInt(sodium) * upserving;

            let new_time_slots = [];

            let current_serving = 0;

            console.log(productDone);
            productDone.forEach((product, i) => {
                console.log(product)
                if(product.product_id === current_product_id) {
                    let time_slots = product.time_slots;
                    time_slots.forEach(function (item, index) {
                        console.log(item);
                        let item_array = item.split(',');
                        let prod_id = item_array[0];
                        let time = item_array[1];
                        let current_serving = item_array[2];
                        if (prod_id === current_product_id) {
                            if (time === current_product_time) {
                                console.log(upserving)
                                let current_run_carb = parseFloat(product.carbs) * parseFloat(upserving);
                                let current_run_na = parseFloat(product.sodium) * parseFloat(upserving);
                                new_time_slots.push(prod_id + ',' + time + ',' + upserving + ',' + current_run_carb + ',' + current_run_na);

                            } else {
                                let current_run_carb = parseFloat(product.carbs) * parseFloat(current_serving);
                                let current_run_na = parseFloat(product.sodium) * parseFloat(current_serving);
                                new_time_slots.push(prod_id + ',' + time + ',' + current_serving + ',' + current_run_carb + ',' + current_run_na);
                            }


                        }
                        console.log(new_time_slots);
                    })


                }
            });



            let newProducts = [];

            productDone.forEach(function(item){
                if(item.product_id === current_product_id) {
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: new_time_slots

                    }
                    newProducts.push(tempProd);

                }else{
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: item.time_slots

                    }
                    newProducts.push(tempProd);
                }

            })

            console.log(this.state.current_run_products);
            console.log(newProducts);

            await updateDoc(doc(db, "users", user_id, 'race-plan', id), {
                run:{products:newProducts}
            });

            this.setState({current_run_products:newProducts});

        }

        this.run_product_plus = async (e) => {
            e.preventDefault();
            var upserving = 0;
            let productDone = structuredClone(this.state.current_run_products);

            let current_product = e.currentTarget.parentNode.dataset.id;
            let starting_serving = e.currentTarget;

            let current_product_array = current_product.split(",");

            let current_product_id = current_product_array[0];

            let current_product_time = current_product_array[1];


            let current_time_slots_array = productDone.find(({product_id}) => product_id === current_product_id ).time_slots;

            current_time_slots_array.forEach(function (item, index) {
                let current_time_slots = item.split(',');


                // if the clicked timeslot and product id match what is in the firebase time slot array
                if(current_time_slots[0] === current_product_id){
                    if(current_time_slots[1] === current_product_time){

                        let local_serving = parseFloat(current_time_slots[2])
                        upserving =  local_serving + .5;

                    }
                }
            })


            productDone.find(({product_id}) => product_id === current_product_id ).serving = upserving;

            let carb = productDone.find(({product_id}) => product_id === current_product_id ).carbs;
            this.state.bike_carb_actual = parseInt(carb) * upserving;

            let sodium = productDone.find(({product_id}) => product_id === current_product_id ).sodium;
            this.state.bike_na_actual = parseInt(sodium) * upserving;

            let new_time_slots = [];

            let current_serving = 0;

            console.log(productDone);
            productDone.forEach((product, i) => {
                console.log(product)
                if(product.product_id === current_product_id) {
                    let time_slots = product.time_slots;
                    time_slots.forEach(function (item, index) {
                        console.log(item);
                        let item_array = item.split(',');
                        let prod_id = item_array[0];
                        let time = item_array[1];
                        let current_serving = item_array[2];
                        if (prod_id === current_product_id) {
                            if (time === current_product_time) {
                                console.log(upserving)
                                let current_run_carb = parseFloat(product.carbs) * parseFloat(upserving);
                                let current_run_na = parseFloat(product.sodium) * parseFloat(upserving);
                                new_time_slots.push(prod_id + ',' + time + ',' + upserving + ',' + current_run_carb + ',' + current_run_na);

                            } else {
                                let current_run_carb = parseFloat(product.carbs) * parseFloat(current_serving);
                                let current_run_na = parseFloat(product.sodium) * parseFloat(current_serving);
                                new_time_slots.push(prod_id + ',' + time + ',' + current_serving + ',' + current_run_carb + ',' + current_run_na);
                            }


                        }
                        console.log(new_time_slots);
                    })


                }
            });



            let newProducts = [];

            productDone.forEach(function(item){
                if(item.product_id === current_product_id) {
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: new_time_slots

                    }
                    newProducts.push(tempProd);

                }else{
                    let tempProd = {
                        calories: item.calories,
                        carb_count: item.carb_count,
                        carbs: item.carbs,
                        flavor: item.flavor,
                        na_count: item.na_count,
                        name: item.name,
                        product_id: item.product_id,
                        serving: upserving,
                        sodium: item.sodium,
                        type: item.type,
                        time_slots: item.time_slots

                    }
                    newProducts.push(tempProd);
                }

            })

            console.log(this.state.current_run_products);
            console.log(newProducts);

            await updateDoc(doc(db, "users", user_id, 'race-plan', id), {
                run:{products:newProducts}
            });

            this.setState({current_run_products:newProducts});
            console.log(this.state.current_run_products);

        }


        console.log(this.bike_carb_actual);


    } // end fetch data function





    addBikeHydration = async (e) => {

        e.preventDefault();
        let prod_id = this.bike_hydration.current.value;

        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;


        const prodSnap = await getDoc(doc(db, "products", prod_id));

        let bikeArray = this.raceTimeArray[0];

        let slot_obj = [];

        console.log(this.state.bike_time_slots);

        this.state.bike_time_slots.forEach(function(item){
            // adding custom array for [product_id, time_slot, serving, carb actual, na_actual]
            let temp_obj = prod_id + ',' + item + ',0,0,0,0';
            slot_obj.push(temp_obj)

        });

        console.log(slot_obj)

        let updateHydObj = {
            'product_id': prodSnap.id,
            'carbs': prodSnap.data().carbs,
            'caff': prodSnap.data().caff,
            'calories': prodSnap.data().calories,
            'fiber': prodSnap.data().fiber,
            'flavor':prodSnap.data().flavor,
            'name':prodSnap.data().name,
            'sodium':prodSnap.data().sodium,
            'type':prodSnap.data().type,
            'time_slots':slot_obj,
            'serving':0,
        }
        console.log(slot_obj);
        this.state.current_bike_products.push(updateHydObj);


        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            bike:{products: this.state.current_bike_products}
        })

        window.location.reload(false);

    }
    // update fueling object
    addBikeFueling = async (e) => {
        e.preventDefault();
        let prod_id = this.bike_fuel.current.value;

        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        const prodSnap = await getDoc(doc(db, "products", prod_id));

        let bikeArray = this.raceTimeArray[0];

        let slot_obj = [];

        console.log(this.state.bike_time_slots);

        this.state.bike_time_slots.forEach(function(item){
            // adding custom array for [product_id, time_slot, serving, carb actual, na_actual]
            let temp_obj = prod_id + ',' + item + ',0,0,0,0';
            slot_obj.push(temp_obj)

        });

        console.log(slot_obj)

        let updateFuelObj = {
            'product_id': prodSnap.id,
            'carbs': prodSnap.data().carbs,
            'caff': prodSnap.data().caff,
            'calories': prodSnap.data().calories,
            'fiber': prodSnap.data().fiber,
            'flavor':prodSnap.data().flavor,
            'name':prodSnap.data().name,
            'sodium':prodSnap.data().sodium,
            'type':prodSnap.data().type,
            'time_slots':slot_obj,
            'serving':0,
        }
        this.state.current_bike_products.push(updateFuelObj);

        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            bike:{products:this.state.current_bike_products}
        })

        window.location.reload(false);

    }


    addRunHydration = async (e) => {

        e.preventDefault();
        let prod_id = this.run_hydration.current.value;

        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;


        const prodSnap = await getDoc(doc(db, "products", prod_id));
        let runArray = this.raceTimeArray[0];

        let slot_obj = [];

        console.log(this.state.run_time_slots);

        this.state.run_time_slots.forEach(function(item){
            let temp_obj = prod_id + ',' + item + ',0,0,0';
            slot_obj.push(temp_obj)
        });

        console.log(slot_obj)

        let updateHydObj = {
            'product_id': prodSnap.id,
            'carbs': prodSnap.data().carbs,
            'caff': prodSnap.data().caff,
            'calories': prodSnap.data().calories,
            'fiber': prodSnap.data().fiber,
            'flavor':prodSnap.data().flavor,
            'name':prodSnap.data().name,
            'sodium':prodSnap.data().sodium,
            'type':prodSnap.data().type,
            'time_slots':slot_obj,
            'serving':0,
        }

        this.state.current_run_products.push(updateHydObj);


        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            run:{products:this.state.current_run_products}
        })

        window.location.reload(false);

    }
    // update fueling object
    addRunFueling = async (e) => {
        e.preventDefault();
        let prod_id = this.run_fuel.current.value;
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        const prodSnap = await getDoc(doc(db, "products", prod_id));

        let runArray = this.raceTimeArray[0];

        let slot_obj = [];

        this.state.run_time_slots.forEach(function(item){
            let temp_obj = prod_id + ',' + item + ',0,0,0';
            slot_obj.push(temp_obj)

        });

        console.log(slot_obj)

        let updateFuelObj = {
            'product_id': prodSnap.id,
            'carbs': prodSnap.data().carbs,
            'caff': prodSnap.data().caff,
            'calories': prodSnap.data().calories,
            'fiber': prodSnap.data().fiber,
            'flavor':prodSnap.data().flavor,
            'name':prodSnap.data().name,
            'sodium':prodSnap.data().sodium,
            'type':prodSnap.data().type,
            'time_slots':slot_obj,
            'serving':0,
        }

        this.state.current_run_products.push(updateFuelObj);

        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            run:{products: this.state.current_run_products}
        })

        window.location.reload(false);

    }


    updateRaceData = async (e) => {
        e.preventDefault();
        let {id} = this.props.params;
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;


        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            'timestamp':Date.now(),
            'race_name':this.race_name.current.value,
            'race_time':this.race_time.current.value,
            'race_temp':this.race_temp.current.value,
            'race_date':this.race_date.current.value,
            'race_humidity':this.race_humidity.current.value,
            'race_type':this.race_type.current.value,

        })



        //const gutTraining = await query(collection(db, 'users', userID, 'gut-training'), where('race_details.id', '==', id))


        /*
        await updateDoc(doc(db, 'users', userID, 'gut-training'), {
            ['race_details']: {id: id, race_name: this.raceObject.race_name, date: this.raceObject.race_date},

        })*/

        window.location.reload(false);
    }

    // toggle to show loading gif (needs to be updated... not working correctly)
    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };

    removeProductBike = async (e) =>{
        e.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        let product_id = e.target.id;

        let product_index = this.state.current_bike_products.findIndex(x => x.product_id === product_id);

        this.state.current_bike_products.splice(product_index, 1);


        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            bike:{products: this.state.current_bike_products}
        })

        window.location.reload(false);

    }
    removeProductRun = async (e) =>{
        e.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;
        let {id} = this.props.params;

        let product_id = e.target.id;

        let product_index = this.state.current_run_products.findIndex(x => x.product_id === product_id);

        this.state.current_run_products.splice(product_index, 1);

        console.log(this.state.current_run_products);


        await updateDoc(doc(db, 'users', userID, 'race-plan', id), {
            run:{products: this.state.current_run_products}
        })

        window.location.reload(false);

    }


    raceTypeModule = () => {
        const auth = getAuth();
        const user = auth.currentUser;


        console.log(this.state.bike_time_slots);

let current_date = new Date().getTime();


        if(this.state.race_type === 'triathlon') {
            console.log(this.state.current_bike_products);
                return (
                    <div>
                        <div className="gut-training-single-single">

                            <h4>Bike</h4>
                            <table>
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    {

                                        //BIKE PRODUCTS HEADER//
                                        this.state.current_bike_products.map((result) => {

                                            if (result) {
                                                this.popover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>{result.name}</b> <br/>
                                                            Flavor: {result.flavor} <br/>
                                                            Calories: {result.calories} <br/>
                                                            Sodium: {result.sodium} <br/>
                                                            Carbs: {result.carbs} <br/>
                                                            Serving Size: {result.serving_size} <br/>

                                                        </Popover.Body>
                                                    </Popover>
                                                );

                                                return (
                                                    <th className="product-col">
                                                        <OverlayTrigger trigger="click" placement="right"
                                                                        overlay={this.popover}>
                                                <span className="product-information">
                                                    {result.name.substring(0, 20)}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                                </span>
                                                        </OverlayTrigger>
                                                    </th>
                                                )
                                            }
                                        })

                                    }
                                    <th>Carb Goal (g/hr)</th>
                                    <th>Carb Actual (g/hr)</th>
                                    <th>NA Goal (mg/hr)</th>
                                    <th>NA Actual (mg/hr)</th>
                                    <th>Fluid Goal (oz/hr)</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Loop through bike array
                                    // BIKE PRODUCTS TD ELEMENTS

                                    this.state.bike_time_slots.map((result) => {
                                            this.bike_carb_actual = 0;
                                            this.bike_na_actual = 0;
                                            // USER EDIT IS ON //
                                                console.log(this.user_edit);
                                            if (this.user_edit === true) {

                                                return (
                                                    <tr className={this.state.no_arrows}>
                                                        <td>
                                                            {result}
                                                        </td>
                                                        {

                                                            (() => {
                                                                let td = [];
                                                            for(let p = 0; p < this.state.current_bike_products.length; p++) {

                                                                console.log(this.state.current_bike_products[p]);
                                                                let time_slots = this.state.current_bike_products[p].time_slots;
                                                                let carbs = this.state.current_bike_products[p].carbs;
                                                                console.log(carbs);
                                                                time_slots.forEach((time_slot) => {
                                                                    let time_slots_array = time_slot.split(',');

                                                                    let product_id = time_slots_array[0];
                                                                    let time = time_slots_array[1];
                                                                    let serving = time_slots_array[2];

                                                                    if (result === time) {
                                                                        if (this.state.current_bike_products[p].product_id === product_id) {
                                                                            this.bike_carb_actual += parseFloat(time_slots_array[3])
                                                                            this.bike_na_actual += parseFloat(time_slots_array[4])
                                                                            td.push(<td className="race-product-td"
                                                                                data-id={this.state.current_bike_products[p].product_id + ',' + result}>
                                                                    <span className="plus-minus-icon"
                                                                          onClick={this.bike_product_minus}> - </span>{
                                                                                <input
                                                                                    className="serving race-product-col"
                                                                                    readOnly={this.state.readonly}
                                                                                    name="serving"

                                                                                    step=".5" min="0" type="number"
                                                                                    pattern="[0-9]*"
                                                                                    min="0"
                                                                                    max="30"
                                                                                    value={serving}
                                                                                    //onChange={this.carbPlusMinus}
                                                                                    //onBlur={this.carbPlusMinus}
                                                                                />

                                                                            }<span className="plus-minus-icon"
                                                                                   onClick={this.bike_product_plus}> + </span>
                                                                            </td>);

                                                                        }

                                                                    }
                                                                })
                                                            }
                                                                return td;


                                                        })()}



                                                        <td>{this.state.carb_goal_per_hour}</td>
                                                        {

                                                            (() => {
                                                                this.user_edit_bike_carb_actual = 0;
                                                                let td = [];
                                                                for(let p = 0; p < this.state.current_bike_products.length; p++) {

                                                                    console.log(this.state.current_bike_products[p]);
                                                                    let time_slots = this.state.current_bike_products[p].time_slots;
                                                                    let carbs = this.state.current_bike_products[p].carbs;
                                                                    console.log(carbs);
                                                                    time_slots.forEach((time_slot) => {

                                                                        let time_slots_array = time_slot.split(',');

                                                                        let product_id = time_slots_array[0];
                                                                        let time = time_slots_array[1];
                                                                        let serving = time_slots_array[2];

                                                                        if (result === time) {
                                                                            if (this.state.current_bike_products[p].product_id === product_id) {
                                                                                this.user_edit_bike_carb_actual += parseFloat(time_slots_array[3])
                                                                                this.bike_na_actual += parseFloat(time_slots_array[4])
                                                                            }




                                                                        }




                                                                    })

                                                                }
                                                                console.log(this.bike_carb_actual)
                                                                let bike_carb_over = this.carb_actual_init(this.user_edit_bike_carb_actual);
                                                                let carb_icon = this.carb_actual_icon(this.user_edit_bike_carb_actual);
                                                                let carb_icon_color = this.carb_actual_icon_color(this.user_edit_bike_carb_actual);
                                                                console.log(bike_carb_over)
                                                                console.log(carb_icon);
                                                                console.log(carb_icon_color);
                                                                td.push(
                                                                    <td className={carb_icon_color}>
                                                                        <OverlayTrigger trigger="click"
                                                                                        placement="right"
                                                                                        overlay={bike_carb_over}>
                                                                    <span
                                                                        className="carb-icon">{carb_icon}</span>
                                                                        </OverlayTrigger>
                                                                        {this.user_edit_bike_carb_actual}
                                                                    </td>
                                                                )
                                                                return td;


                                                            })()}

                                                        <td>{this.state.sodium_goal_per_hour}</td>
                                                        {

                                                            (() => {
                                                                this.user_edit_bike_na_actual = 0;
                                                                let td = [];
                                                                for(let p = 0; p < this.state.current_bike_products.length; p++) {

                                                                    console.log(this.state.current_bike_products[p]);
                                                                    let time_slots = this.state.current_bike_products[p].time_slots;
                                                                    let carbs = this.state.current_bike_products[p].carbs;
                                                                    let na = this.state.current_bike_products[p].sodium;
                                                                    console.log(carbs);
                                                                    time_slots.forEach((time_slot) => {

                                                                        let time_slots_array = time_slot.split(',');

                                                                        let product_id = time_slots_array[0];
                                                                        let time = time_slots_array[1];
                                                                        let serving = time_slots_array[2];

                                                                        if (result === time) {
                                                                            if (this.state.current_bike_products[p].product_id === product_id) {
                                                                                this.user_edit_bike_na_actual += parseFloat(time_slots_array[4])
                                                                                console.log(this.user_edit_bike_na_actual);
                                                                            }




                                                                        }




                                                                    })

                                                                }
                                                                console.log(this.user_edit_bike_na_actual);
                                                                let na_carb_over = this.na_actual_init(this.user_edit_bike_na_actual);
                                                                let na_icon = this.na_actual_icon(this.user_edit_bike_na_actual);
                                                                let na_icon_color = this.na_actual_icon_color(this.user_edit_bike_na_actual);
                                                                console.log(na_carb_over)
                                                                console.log(na_icon);
                                                                console.log(na_icon_color);
                                                                td.push(
                                                                    <td className={na_icon_color}>
                                                                        <OverlayTrigger trigger="click"
                                                                                        placement="right"
                                                                                        overlay={na_carb_over}>
                                                                    <span
                                                                        className="carb-icon">{na_icon}</span>
                                                                        </OverlayTrigger>
                                                                        {this.user_edit_bike_na_actual}
                                                                    </td>
                                                                )
                                                                return td;


                                                            })()}
                                                        <td>{this.state.fluid_goal_per_hour}</td>
                                                    </tr>


                                                )
                                                // USER EDIT IS OFF//

                                            } else { // if isn't user edit
                                                return (
                                                    <tr>
                                                        <td>
                                                            {result}
                                                        </td>

                                                        {(() => {
                                                            let td = [];
                                                            for (let p = 0; p < this.state.current_bike_products.length; p++) {
                                                                td.push(<td>{<input className="serving product-col"
                                                                                    readOnly={this.state.readonly}
                                                                                    name="serving"
                                                                                    id={this.state.current_bike_products[p].product_id}
                                                                                    step=".5" min="0" type="number"
                                                                                    pattern="[0-9]*"
                                                                                    min="0"
                                                                                    max="30"
                                                                                    defaultValue={this.state.current_bike_products[p].serving}

                                                                />
                                                                }</td>);


                                                            }
                                                            return td;

                                                        })()}
                                                        <td>{this.state.carb_goal_per_hour}</td>
                                                        <td className={this.state.bike_carb_actual_class}>
                                                            <OverlayTrigger trigger="click" placement="right"
                                                                            overlay={this.bikecarbover}>
                                                                    <span
                                                                        className="carb-icon">{this.state.bikefaicon}</span>
                                                            </OverlayTrigger>
                                                            {this.state.bike_carb_actual}
                                                        </td>
                                                        <td>{this.state.sodium_goal_per_hour}</td>
                                                        <td className={this.state.bike_sodium_actual_class}>
                                                            <OverlayTrigger trigger="click" placement="right"
                                                                            overlay={this.bikenaover}>
                                                                    <span
                                                                        className="sodium-icon">{this.state.bikenaicon}</span>
                                                            </OverlayTrigger>
                                                            {this.state.bike_na_actual}
                                                        </td>
                                                        <td>{this.state.fluid_goal_per_hour}</td>
                                                    </tr>


                                                )
                                            }


                                        } // End Bike Time Slots map
                                    )
                                }



                                </tbody>
                            </table>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <form onSubmit={this.addBikeHydration}>
                                    <div className="row">
                                        <label htmlFor="session-type-hyd">Add Hydration Product</label>
                                        <select name="session-type" ref={this.bike_hydration}>
                                            <option value="no hydration">Select Product</option>

                                            {
                                            // DROPDOWN ALL CURRENT BIKE HYDRATION PRODUCTS //
                                                this.state.productInfo.map((result) => {
                                                    if (result.type === 'hydration') {

                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }

                                        </select>

                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Hydration
                                        Product
                                    </button>
                                </form>
                                <div className="row">
                                    <h5>Current Hydration Products</h5>
                                    <ul>
                                        {
                                            // LIST ALL CURRENT BIKE HYDRATION PRODUCTS //
                                            this.state.current_bike_products.map((result, index) => {
                                                if (result.type === 'hydration') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductBike}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)
                                                }
                                            })

                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <form onSubmit={this.addBikeFueling}>
                                    <div className="row">

                                        <label htmlFor="session-type-fuel">Add Fueling Product</label>
                                        <select name="session-type-fuel" ref={this.bike_fuel}>
                                            <option value="no hydration">Select Product</option>
                                            {
                                                // DROPDOWN ALL BIKE FUEL PRODUCTS //
                                                this.state.productInfo.map((result) => {

                                                    if (result.type === 'fuel') {
                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }


                                        </select>
                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Fueling
                                        Product
                                    </button>
                                </form>

                                <div className="row">
                                    <h5>Current Fueling Products</h5>
                                    <ul>
                                        {
                                            // LIST ALL CURRENT BIKE FUEL PRODUCTS //
                                            this.state.current_bike_products.map((result, index) => {
                                                if (result.type === 'fuel') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductBike}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)

                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="gut-training-single-single">
                            <h4>Run</h4>
                            <table>
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    {
                                        // RUN PRODUCTS IN HEADER //
                                        this.state.current_run_products.map((result) => {
                                            if (result) {

                                                this.popover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>
                                                            <b>{result.name}</b> <br/>
                                                            Flavor: {result.flavor} <br/>
                                                            Calories: {result.calories} <br/>
                                                            Sodium: {result.sodium} <br/>
                                                            Carbs: {result.carbs} <br/>
                                                            Serving Size: {result.serving_size} <br/>

                                                        </Popover.Body>
                                                    </Popover>
                                                );

                                                return (
                                                    <th className="product-col">
                                                        <OverlayTrigger trigger="click" placement="right"
                                                                        overlay={this.popover}>
                                                <span className="product-information">
                                                    {result.name.substring(0, 20)}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                                </span>
                                                        </OverlayTrigger>
                                                    </th>
                                                )
                                            }
                                        })

                                    }
                                    <th>Carb Goal (g/hr)</th>
                                    <th>Carb Actual (g/hr)</th>
                                    <th>NA Goal (mg/hr)</th>
                                    <th>NA Actual (mg/hr)</th>
                                    <th>Fluid Goal (oz/hr)</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Loop through run array
                                    // ALL RUN PRODUCTS TD ELEMENT
                                    this.state.run_time_slots.map((result) => {
                                        this.run_carb_actual = 0;
                                        this.run_na_actual = 0;
                                            if (result.length > 0) {
                                                if (this.user_edit === true) {

                                                    return (
                                                        <tr className={this.state.no_arrows}>
                                                            <td>
                                                                {result}
                                                            </td>

                                                            {

                                                                (() => {
                                                                    let td = [];
                                                                    for(let p = 0; p < this.state.current_run_products.length; p++) {

                                                                        console.log(this.state.current_run_products[p]);
                                                                        let time_slots = this.state.current_run_products[p].time_slots;
                                                                        let carbs = this.state.current_run_products[p].carbs;

                                                                        time_slots.forEach((time_slot) => {
                                                                            let time_slots_array = time_slot.split(',');

                                                                            let product_id = time_slots_array[0];
                                                                            let time = time_slots_array[1];
                                                                            let serving = time_slots_array[2];

                                                                            if (result === time) {
                                                                                if (this.state.current_run_products[p].product_id === product_id) {
                                                                                    this.run_carb_actual += parseFloat(time_slots_array[3]);
                                                                                    this.run_na_actual += parseFloat(time_slots_array[4]);
                                                                                    console.log('this works');
                                                                                    td.push(<td className="race-product-td"
                                                                                                data-id={this.state.current_run_products[p].product_id + ',' + result}>
                                                                    <span className="plus-minus-icon"
                                                                          onClick={this.run_product_minus}> - </span>{
                                                                                        <input
                                                                                            className="serving race-product-col"
                                                                                            readOnly={this.state.readonly}
                                                                                            name="serving"

                                                                                            step=".5" min="0" type="number"
                                                                                            pattern="[0-9]*"
                                                                                            min="0"
                                                                                            max="30"
                                                                                            value={serving}
                                                                                            //onChange={this.carbPlusMinus}
                                                                                            //onBlur={this.carbPlusMinus}
                                                                                        />

                                                                                    }<span className="plus-minus-icon"
                                                                                           onClick={this.run_product_plus}> + </span>
                                                                                    </td>);

                                                                                }

                                                                            }
                                                                        })
                                                                    }
                                                                    return td;


                                                                })()}
                                                            <td>{this.state.carb_goal_per_hour}</td>
                                                            {

                                                                (() => {
                                                                    this.user_edit_run_carb_actual = 0;
                                                                    let td = [];
                                                                    for(let p = 0; p < this.state.current_run_products.length; p++) {

                                                                        console.log(this.state.current_run_products[p]);
                                                                        let time_slots = this.state.current_run_products[p].time_slots;
                                                                        let carbs = this.state.current_run_products[p].carbs;
                                                                        console.log(carbs);
                                                                        time_slots.forEach((time_slot) => {

                                                                            let time_slots_array = time_slot.split(',');

                                                                            let product_id = time_slots_array[0];
                                                                            let time = time_slots_array[1];
                                                                            let serving = time_slots_array[2];

                                                                            if (result === time) {
                                                                                if (this.state.current_run_products[p].product_id === product_id) {
                                                                                    this.user_edit_run_carb_actual += parseFloat(time_slots_array[3])

                                                                                }




                                                                            }




                                                                        })

                                                                    }

                                                                    let run_carb_over = this.carb_actual_init(this.user_edit_run_carb_actual);
                                                                    let carb_icon = this.carb_actual_icon(this.user_edit_run_carb_actual);
                                                                    let carb_icon_color = this.carb_actual_icon_color(this.user_edit_run_carb_actual);
                                                                    console.log(run_carb_over)
                                                                    console.log(carb_icon);
                                                                    console.log(carb_icon_color);
                                                                    td.push(
                                                                        <td className={carb_icon_color}>
                                                                            <OverlayTrigger trigger="click"
                                                                                            placement="right"
                                                                                            overlay={run_carb_over}>
                                                                    <span
                                                                        className="carb-icon">{carb_icon}</span>
                                                                            </OverlayTrigger>
                                                                            {this.user_edit_run_carb_actual}
                                                                        </td>
                                                                    )
                                                                    return td;


                                                                })()}
                                                            <td>{this.state.sodium_goal_per_hour}</td>
                                                            {

                                                                (() => {
                                                                    this.user_edit_run_na_actual = 0;
                                                                    let td = [];
                                                                    for(let p = 0; p < this.state.current_run_products.length; p++) {

                                                                        console.log(this.state.current_run_products[p]);
                                                                        let time_slots = this.state.current_run_products[p].time_slots;
                                                                        let carbs = this.state.current_run_products[p].carbs;
                                                                        let na = this.state.current_run_products[p].sodium;
                                                                        console.log(carbs);
                                                                        time_slots.forEach((time_slot) => {

                                                                            let time_slots_array = time_slot.split(',');

                                                                            let product_id = time_slots_array[0];
                                                                            let time = time_slots_array[1];
                                                                            let serving = time_slots_array[2];

                                                                            if (result === time) {
                                                                                if (this.state.current_run_products[p].product_id === product_id) {
                                                                                    this.user_edit_run_na_actual += parseFloat(time_slots_array[4])
                                                                                    console.log(this.user_edit_run_na_actual);
                                                                                }




                                                                            }




                                                                        })

                                                                    }
                                                                    console.log(this.user_edit_run_na_actual);
                                                                    let na_carb_over = this.na_actual_init(this.user_edit_run_na_actual);
                                                                    let na_icon = this.na_actual_icon(this.user_edit_run_na_actual);
                                                                    let na_icon_color = this.na_actual_icon_color(this.user_edit_run_na_actual);
                                                                    console.log(na_carb_over)
                                                                    console.log(na_icon);
                                                                    console.log(na_icon_color);
                                                                    td.push(
                                                                        <td className={na_icon_color}>
                                                                            <OverlayTrigger trigger="click"
                                                                                            placement="right"
                                                                                            overlay={na_carb_over}>
                                                                    <span
                                                                        className="carb-icon">{na_icon}</span>
                                                                            </OverlayTrigger>
                                                                            {this.user_edit_run_na_actual}
                                                                        </td>
                                                                    )
                                                                    return td;


                                                                })()}
                                                            <td>{this.state.fluid_goal_per_hour}</td>

                                                        </tr>


                                                    )

                                                } else { // if isn't user edit
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {result}
                                                            </td>

                                                            {(() => {
                                                                let td = [];
                                                                for (let p = 0; p < this.state.current_run_products.length; p++) {
                                                                    td.push(<td>{<input className="serving product-col"
                                                                                        readOnly={this.state.readonly}
                                                                                        name="serving"
                                                                                        id={this.state.current_run_products[p].product_id}
                                                                                        step=".5" min="0" type="number"
                                                                                        pattern="[0-9]*"
                                                                                        min="0"
                                                                                        max="30"
                                                                                        defaultValue={this.state.current_run_products[p].serving}

                                                                    />
                                                                    }</td>);


                                                                }
                                                                return td;

                                                            })()}
                                                            <td>{this.state.carb_goal_per_hour}</td>
                                                            <td className={this.state.run_carb_actual_class}>
                                                                <OverlayTrigger trigger="click" placement="right"
                                                                                overlay={this.carbover}>
                                                                    <span
                                                                        className="carb-icon">{this.state.faicon}</span>
                                                                </OverlayTrigger>
                                                                {this.state.run_carb_actual}
                                                            </td>
                                                            <td>{this.state.sodium_goal_per_hour}</td>
                                                            <td className={this.state.run_sodium_actual_class}>
                                                                <OverlayTrigger trigger="click" placement="right"
                                                                                overlay={this.naover}>
                                                                    <span
                                                                        className="sodium-icon">{this.state.naicon}</span>
                                                                </OverlayTrigger>
                                                                {this.state.run_na_actual}
                                                            </td>
                                                            <td>{this.state.fluid_goal_per_hour}</td>
                                                        </tr>


                                                    )
                                                }

                                            }
                                        }
                                    )
                                }

                                </tbody>
                            </table>

                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <form onSubmit={this.addRunHydration}>
                                    <div className="row">
                                        <label htmlFor="session-type-hyd">Add Hydration Product</label>
                                        <select name="session-type" ref={this.run_hydration}>
                                            <option value="no hydration">Select Product</option>

                                            {

                                                this.state.productInfo.map((result) => {
                                                    if (result.type === 'hydration') {

                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }

                                        </select>

                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Hydration
                                        Product
                                    </button>
                                </form>
                                <div className="row">
                                    <h5>Current Hydration Products</h5>
                                    <ul>
                                        {
                                            this.state.current_run_products.map((result, index) => {
                                                if (result.type === 'hydration') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductRun}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)
                                                }
                                            })

                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <form onSubmit={this.addRunFueling}>
                                    <div className="row">

                                        <label htmlFor="session-type-fuel">Add Fueling Product</label>
                                        <select name="session-type-fuel" ref={this.run_fuel}>
                                            <option value="no hydration">Select Product</option>
                                            {
                                                this.state.productInfo.map((result) => {

                                                    if (result.type === 'fuel') {
                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }


                                        </select>
                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Fueling
                                        Product
                                    </button>
                                </form>

                                <div className="row">
                                    <h5>Current Fueling Products</h5>
                                    <ul>
                                        {
                                            this.state.current_run_products.map((result, index) => {
                                                if (result.type === 'fuel') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductRun}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)

                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                )

        }else { /////////////////////////////////////////////////////////////////////////////// Return SINGLE SPORT

                return (
                    <div>
                        <div className="gut-training-single-single">
                            <h4>Single Sport</h4>
                            <table>
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    {
                                        this.state.current_run_products.map((result) => {

                                            if (result.product_id !== undefined) {
                                                this.popover = (

                                                    <Popover id="product-popover">
                                                        <Popover.Body>

                                                            <b>{result.name}</b> <br/>
                                                            Flavor: {result.flavor} <br/>
                                                            Calories: {result.calories} <br/>
                                                            Sodium: {result.sodium} <br/>
                                                            Carbs: {result.carbs} <br/>
                                                            Serving Size: {result.serving_size} <br/>

                                                        </Popover.Body>
                                                    </Popover>
                                                );

                                                return (
                                                    <th className="product-col">
                                                        <OverlayTrigger trigger="click" placement="right"
                                                                        overlay={this.popover}>
                                                <span className="product-information">
                                                    {result.name.substring(0, 20)}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                                </span>
                                                        </OverlayTrigger>
                                                    </th>
                                                )
                                            }
                                        })

                                    }
                                    <th>Carb Goal (g/hr)</th>
                                    <th>Carb Actual (g/hr)</th>
                                    <th>NA Goal (mg/hr)</th>
                                    <th>NA Actual (mg/hr)</th>
                                    <th>Fluid Goal (oz/hr)</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Loop through run array
                                    // ALL RUN PRODUCTS TD ELEMENT
                                    this.state.run_time_slots.map((result) => {
                                        this.run_carb_actual = 0;
                                        this.run_na_actual = 0;
                                        if (result.length > 0) {
                                            if (this.user_edit === true) {

                                                return (
                                                    <tr className={this.state.no_arrows}>
                                                        <td>
                                                            {result}
                                                        </td>

                                                        {

                                                            (() => {
                                                                let td = [];
                                                                for(let p = 0; p < this.state.current_run_products.length; p++) {

                                                                    console.log(this.state.current_run_products[p]);
                                                                    let time_slots = this.state.current_run_products[p].time_slots;
                                                                    let carbs = this.state.current_run_products[p].carbs;

                                                                    time_slots.forEach((time_slot) => {
                                                                        let time_slots_array = time_slot.split(',');

                                                                        let product_id = time_slots_array[0];
                                                                        let time = time_slots_array[1];
                                                                        let serving = time_slots_array[2];

                                                                        if (result === time) {
                                                                            if (this.state.current_run_products[p].product_id === product_id) {
                                                                                this.run_carb_actual += parseFloat(time_slots_array[3]);
                                                                                this.run_na_actual += parseFloat(time_slots_array[4]);
                                                                                console.log('this works');
                                                                                td.push(<td className="race-product-td"
                                                                                            data-id={this.state.current_run_products[p].product_id + ',' + result}>
                                                                    <span className="plus-minus-icon"
                                                                          onClick={this.run_product_minus}> - </span>{
                                                                                    <input
                                                                                        className="serving race-product-col"
                                                                                        readOnly={this.state.readonly}
                                                                                        name="serving"

                                                                                        step=".5" min="0" type="number"
                                                                                        pattern="[0-9]*"
                                                                                        min="0"
                                                                                        max="30"
                                                                                        value={serving}
                                                                                        //onChange={this.carbPlusMinus}
                                                                                        //onBlur={this.carbPlusMinus}
                                                                                    />

                                                                                }<span className="plus-minus-icon"
                                                                                       onClick={this.run_product_plus}> + </span>
                                                                                </td>);

                                                                            }

                                                                        }
                                                                    })
                                                                }
                                                                return td;


                                                            })()}
                                                        <td>{this.state.carb_goal_per_hour}</td>
                                                        {

                                                            (() => {
                                                                this.user_edit_run_carb_actual = 0;
                                                                let td = [];
                                                                for(let p = 0; p < this.state.current_run_products.length; p++) {

                                                                    console.log(this.state.current_run_products[p]);
                                                                    let time_slots = this.state.current_run_products[p].time_slots;
                                                                    let carbs = this.state.current_run_products[p].carbs;
                                                                    console.log(carbs);
                                                                    time_slots.forEach((time_slot) => {

                                                                        let time_slots_array = time_slot.split(',');

                                                                        let product_id = time_slots_array[0];
                                                                        let time = time_slots_array[1];
                                                                        let serving = time_slots_array[2];

                                                                        if (result === time) {
                                                                            if (this.state.current_run_products[p].product_id === product_id) {
                                                                                this.user_edit_run_carb_actual += parseFloat(time_slots_array[3])
                                                                                this.run_na_actual += parseFloat(time_slots_array[4])
                                                                            }




                                                                        }




                                                                    })

                                                                }

                                                                let run_carb_over = this.carb_actual_init(this.user_edit_run_carb_actual);
                                                                let carb_icon = this.carb_actual_icon(this.user_edit_run_carb_actual);
                                                                let carb_icon_color = this.carb_actual_icon_color(this.user_edit_run_carb_actual);
                                                                console.log(run_carb_over)
                                                                console.log(carb_icon);
                                                                console.log(carb_icon_color);
                                                                td.push(
                                                                    <td className={carb_icon_color}>
                                                                        <OverlayTrigger trigger="click"
                                                                                        placement="right"
                                                                                        overlay={run_carb_over}>
                                                                    <span
                                                                        className="carb-icon">{carb_icon}</span>
                                                                        </OverlayTrigger>
                                                                        {this.user_edit_run_carb_actual}
                                                                    </td>
                                                                )
                                                                return td;


                                                            })()}
                                                        <td>{this.state.sodium_goal_per_hour}</td>
                                                        <td className={this.state.run_sodium_actual_class}>
                                                            <OverlayTrigger trigger="click" placement="right"
                                                                            overlay={this.naover}>
                                                                    <span
                                                                        className="sodium-icon">{this.state.naicon}</span>
                                                            </OverlayTrigger>
                                                            {this.run_na_actual}
                                                        </td>
                                                        <td>{this.state.fluid_goal_per_hour}</td>

                                                    </tr>


                                                )

                                                } else { // if isn't user edit
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {result}
                                                            </td>

                                                            {(() => {
                                                                let td = [];
                                                                for (let p = 0; p < this.state.current_run_products.length; p++) {
                                                                    td.push(<td>{<input className="serving product-col"
                                                                                        readOnly={this.state.readonly}
                                                                                        name="serving"
                                                                                        id={this.state.current_run_products[p].product_id}
                                                                                        step=".5" min="0" type="number"
                                                                                        pattern="[0-9]*"
                                                                                        min="0"
                                                                                        max="30"
                                                                                        defaultValue={this.state.current_run_products[p].serving}

                                                                    />
                                                                    }</td>);


                                                                }
                                                                return td;

                                                            })()}
                                                            <td>{this.state.carb_goal_per_hour}</td>
                                                            <td className={this.state.run_carb_actual_class}>
                                                                <OverlayTrigger trigger="click" placement="right"
                                                                                overlay={this.carbover}>
                                                                    <span
                                                                        className="carb-icon">{this.state.faicon}</span>
                                                                </OverlayTrigger>
                                                                {this.state.run_carb_actual}
                                                            </td>
                                                            <td>{this.state.na_per_hour}</td>
                                                            <td className={this.state.run_sodium_actual_class}>
                                                                <OverlayTrigger trigger="click" placement="right"
                                                                                overlay={this.naover}>
                                                                    <span
                                                                        className="sodium-icon">{this.state.naicon}</span>
                                                                </OverlayTrigger>
                                                                {this.state.run_na_actual}
                                                            </td>
                                                            <td>{this.state.sodium_goal_per_hour}</td>
                                                        </tr>


                                                    )
                                                }
                                            }
                                        }
                                    )
                                }

                                </tbody>
                            </table>

                        </div>

                        <div className="row">

                            <div className="col-sm-12 col-md-6">
                                <h3>Step 1: Add Fueling Product(s)</h3>
                                <form onSubmit={this.addRunFueling}>
                                    <div className="row">


                                        <select name="session-type-fuel" ref={this.run_fuel}>
                                            <option value="no hydration">Select Product</option>
                                            {
                                                this.state.productInfo.map((result) => {

                                                    if (result.type === 'fuel') {
                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }


                                        </select>
                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Fueling
                                        Product
                                    </button>
                                </form>

                                <div className="row">
                                    <h5>Current Fueling Products</h5>
                                    <ul>
                                        {
                                            this.state.current_run_products.map((result, index) => {
                                                if (result.type === 'fuel') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductRun}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)
                                                }
                                            })

                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <h3>Step 2: Add Hydration Product(s)</h3>
                                <form onSubmit={this.addRunHydration}>
                                    <div className="row">
                                        <select name="session-type" ref={this.run_hydration}>
                                            <option value="no hydration">Select Product</option>

                                            {

                                                this.state.productInfo.map((result) => {
                                                    if (result.type === 'hydration') {

                                                        return (<option
                                                            value={result.product_id}>{result.product_name} - {result.product_flavor}</option>)
                                                    }
                                                })
                                            }

                                        </select>

                                    </div>
                                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add
                                        Hydration
                                        Product
                                    </button>
                                </form>
                                <div className="row">
                                    <h5>Current Hydration Products</h5>
                                    <ul>
                                        {
                                            this.state.current_run_products.map((result, index) => {
                                                if (result.type === 'hydration') {

                                                    return (<li> {result.name} - {result.flavor}<a href="#"
                                                                                                   id={result.product_id}
                                                                                                   onClick={this.removeProductRun}
                                                                                                   className="remove-product"> (X
                                                        Remove)</a></li>)
                                                }
                                            })

                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>


                    </div>
                )


        }


    } // race type module


    /*
    Products as Headings and time as first td

    No difference between gram weighting

    Notes for Race view.  Add adjustability for the grams.  Add the same as gut training with the warning.

    Sum at the end of how many carbs (at the end per race)

    Sodium Goal, Sodium Actual, Ounces per hour,

    Add a product to run or bike.



     */

    render() {

        return (
            <div className="pageContainer">
                <Navbar/>

                <div className="gutSingleContainer">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How to use your race plan</Accordion.Header>
                            <Accordion.Body>
                                <p>To hit your hourly carbohydrate, sodium, and fluid goals, please aim to consume these
                                    products each hour during your race. We encourage breaking the recommended amounts
                                    into 20 min increments.</p>

                                <p>For instance, if your goal is 60g carbs/hr, then aim to consume 20g of carbs at the
                                    first 20 minute mark, then another 20g of carbs at the 40 minute mark, and again at
                                    the 60 minute mark.</p>

                                <p>Spreading out your carb intake allows for optimal stomach emptying rate, while also
                                    minimizing your risk for GI issues.</p>

                                <p>Best of luck with your race!</p>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <br/>
                    <div className="row">
                        <div className="user-edit">
                            <p><a href={this.edit_url}>Edit Race Details</a></p>
                            <span>User Edit </span>
                            <BootstrapSwitchButton
                                checked={this.state.userEdit}
                                onlabel='On'
                                offlabel='Off'
                                onChange={(checked: boolean) => {
                                    this.updateUserEdit(checked);
                                }}
                            />

                        </div>
                        <h3 className="module-title">Race Plan - {this.state.race_name}</h3>

                        {this.raceTypeModule()}


                    </div>
                    <div className="row w-50 p-3">


                    </div>

                </div>


            </div>
        );
    }


}

export default withParams(RaceDetails);
