import React, {Component} from 'react';
import { useSession } from '../../firebase/UserProvider';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/db";
import UserMetaForm from "../../functions/user-functions/addUserMeta";
import Navbar from "../../components/Navbar";
import { getApp } from "@firebase/app";
import {doc, getDoc, collection, getDocs, setDoc, addDoc, onSnapshot} from "firebase/firestore";


const app = getApp();

class Account extends Component{
    constructor(props) {
        super(props);
        this.state = {
            display_name:'',
            email: '',
            photoURL:'',
            plans:[]
        }
        this.plans = [];
        this.getPriceID = React.createRef();

        this.componentDidMount = this.componentDidMount(this);
        this.stripeCheckout = this.stripeCheckout.bind(this);
    }

    async componentDidMount() {
        const auth = getAuth();
        const user = auth.currentUser;
        console.log(user);
        if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = user.displayName;
            this.setState({display_name: displayName});
            const email = user.email;
            this.setState({email: email})
            const photoURL = user.photoURL;
            this.setState({photoURL: photoURL})
            const emailVerified = user.emailVerified;
            const user_id = user.uid;


            // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
            const uid = user.uid;

        }
    }


    render(){
        return(
        <div className="pageContainer">
            <Navbar/>
            <div className="myAccountContainer">
                <div className="mainUser">
                    <p>Name : {this.state.display_name}</p>
                    <p>Email : {this.state.email}</p>
                    <p>Icon <img src=""></img></p>

                </div>

                <UserMetaForm/>
                <div className="subscription-plans">
                    <h2>Subscriptions</h2>
                    <div className="subscriptions-inner">

                    {
                        this.state.plans.map((result) => {
                            console.log(result.name);
                            return(<div className="subscription-single" id={result.price_id} ref={this.getPriceID}>
                                <h4>{result.name}</h4>
                                <h3>{result.product_price * .01}</h3>
                                <button className="green-button" onClick={() => this.stripeCheckout()}>Purchase</button>
                            </div>)
                        })
                    }
                    </div>
                </div>
            </div>

        </div>
    )
    }






}

export default Account;