import React, { Component } from "react";
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import { getAuth } from 'firebase/auth';
import { query, limit, orderBy, collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/db.js';


class DashProdModule extends Component {
    constructor(props) {
        super(props);

        this.state={
            userData: []
        };

        this.componentDidMount = this.componentDidMount();



    }

    componentDidMount = async () =>{
        const {userData} = this.state;

        const userDocs = await getDocs(collection(db, "users"));

        userDocs.forEach((doc) => {
            console.log(doc.data().name);
            let userObj = {
                ['first_name']: doc.data().first_name,
                ['last_name']:doc.data().last_name,
                ['id']: doc.id,
            }

            this.state.userData.push(userObj)
        });

        this.setState({userData});



    }
    render()
    {
        return(
            <div>
            {this.state.userData.map((result) => {
        return(
            <div className="row prod-item-single">
                <div className="col">
                    <p>{result.first_name} {result.last_name}</p>
                </div>
                <div className="col">
                    ...
                </div>
                <hr />
            </div>

        )
    })}

            </div>)



    }

}

export default DashProdModule;