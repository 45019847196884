import React, {Component} from 'react';
import { getAuth, onAuthStateChanged} from "firebase/auth";
import Navbar from "../../components/Navbar";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import GutForm  from '../../functions/user-functions/addGutTraining.js';
import {collection, doc, getDoc, getDocs} from "firebase/firestore";
import {db} from "../../firebase/db";
import {Link} from 'react-router-dom';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';


class Gut extends Component{
    constructor(props) {
        super(props);

        this.race_id = [];
        this.date = '';
        this.today = '';

        this.state = {
            showSession: true,
            race_id: '',
            race_name:'',
            newData:[]
        };

        this.handleClick = this.handleClick.bind(this);

        this.componentDidMount = this.componentDidMount();
    }

    handleClick(){
        this.setState(showSession => ({
            isToggleOn: !showSession.isToggleOn
        }));
    }


    componentDidMount = async () => {

        const auth = getAuth();
        const user = auth.currentUser;

        var todays_date = new Date();
        this.today = Date.parse(todays_date);


                const {newData} = this.state;
                console.log(user);

                // The user object has basic properties such as display name, email, etc.

                const user_id = user.uid;
                this.race2 = '';


                // The user's ID, unique to the Firebase project. Do NOT use
                // this value to authenticate with your backend server, if
                // you have one. Use User.getToken() instead.


                const docSnap = await getDocs(collection(db, "users", user_id, 'gut-training'));
                docSnap.forEach((doc) => {


                    const gut_link = "/client-dashboard/gut-training/details/" + doc.id;
                    const edit_link = "/client-dashboard/gut-training/edit/" + doc.id;
                    //console.log(doc.id);


                    let obj = {
                        ['id']:doc.id,
                        ['race_name']:doc.data().race_details.race_name,
                        ['race_id']:doc.data().race_details.id,
                        ['race_date']: doc.data().race_details.date,
                        ['disp_date']: Date.parse(doc.data().race_details.date),
                        ['is_paused']: doc.data().is_paused,
                        ['gut_link']: gut_link,
                        ['edit_link']: edit_link

                    }

                    newData.push(obj);

                })

                this.setState({newData});



            console.log(this.state.newData);

    }



    render() {

        return (
            <div className="pageContainer">
                <Navbar/>


                <div className="gutTrainingContainer">
                    <div className="finished-gut">
                        <a href="/client-dashboard/gut-training-completed">Completed Trainings</a>
                    </div>
                    {this.state.newData.map((result) => {
                        if (this.today <= result.disp_date) {
                            return (
                                <div className="gut-training-single row">

                                    <h3 className="module-title">Gut Training</h3>
                                    <div className="gutTrainingLeft col-xs-6 col-md-6 col-sm-2">
                                        <h4 className="fw-bolder">{result.race_name}</h4>
                                        <span className="fw-semibold module-date">
                                    {result.race_date}
                                </span>
                                    </div>
                                    <div className="gutTrainingRight col-xs-6 col-md-6 col-sm-4">
                                        <div className="row">
                                            <div className="col-xs-8 col-sm-6 col-md-6">
                                                {(() => {
                                                    if (result.is_paused === false) {
                                                        return (
                                                            <button className="in-progress">In Progress</button>
                                                        )
                                                    } else {
                                                        return (
                                                            <button className="paused">Paused</button>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div className="col-xs-4 col-sm-6 col-md-6">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                        ...
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href={result.gut_link}>View gut training details
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href={result.edit_link}>Edit
                                                            gut
                                                            training details</Dropdown.Item>

                                                        <Dropdown.Item>Pause gut training</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        }
                    })}


                </div>
            </div>
        )

    }
}

export default Gut;