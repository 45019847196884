import React from 'react';
import 'react-tabs/style/react-tabs.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';


function AdminNav(){

    return (
        <div className="nav-container">
            <nav className="client-dash-nav container-xxl">
                <div className="row row-cols-5">
                    <div className={"col"}>
                        <a href={"/practitioner-dashboard/"}><img src={require("../assets/imgs/Dashboard.png")}/> Dashboard</a>
                    </div>
                    <div className={"col"}>
                        <a href={"/practitioner-dashboard/clients"}> <img src={require("../assets/imgs/Tasks.png")}/> Clients</a>
                    </div>
                    <div className={"col"}>
                        <a href={"/practitioner-dashboard/tasks"}> <img src={require("../assets/imgs/Tasks.png")}/> Tasks</a>
                    </div>
                    <div className={"col"}>
                        <a href={"/practitioner-dashboard/resources"}> <img src={require("../assets/imgs/Messages.png")}/> Resources</a>
                    </div>
                    <div className={"col"}>
                        <a href={"/practitioner-dashboard/account"}> <img src={require("../assets/imgs/Profile.png")}/> Profile</a>
                    </div>

                </div>
            </nav>
        </div>
    );


}




export default AdminNav;