import React, {Component} from 'react';
import {getAuth} from "firebase/auth";
import Navbar from "../../components/Navbar";
import {useParams} from 'react-router-dom';
import {collection, getDocs, getDoc, doc, updateDoc, arrayUnion} from "firebase/firestore";
import { db } from "../../firebase/db";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faUpLong, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import 'datatables.net-fixedcolumns-dt';
import Accordion from 'react-bootstrap/Accordion';


/*
function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}
*/
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class GutDetailsCompleted extends Component{
    constructor(props){
        super(props);

        this.userServing = React.createRef();
        this.carb_actual_start = React.createRef();
        this.carb_actual = 0;
        this.startWeight = [];
        this.endWeight = [];
        this.drinkVolume = [];
        this.sweat_duration = [];
        this.race_duration = 0;
        this.current_products = [];
        this.weeklyCarbGoals = [];
        this.weekCount = 0;
        this.productDone = [];
        this.serving_count = 0;
        this.id = '';
        this.na_goal = '';


        this.state = {value: ''};
        this.state = {loading: false}
        this.state = {
            title: '',
            date: '',
            race_date: '',
            sodium_loss: '',
            sodium_loss_value:0,
            carb_consume:'yes',
            carb_consume_value: 0,
            fluids_train: 'yes',
            fluids_value: 0,
            product: '',
            prod_name: '',
            currentProducts: [],
            productInfo: [],
            weeklyCarbGoal: [],
            sweat_duration: 0,
            race_duration: 0,
            product_info: [],
            carbPlusMinus:0,
            productDone:[],
            initProduct:[],
            carb_actual_add:{},
            sodium_actual_add:{},
            sodium_goal: 500,
            carb_actual_class:'',
            sodium_actual_class:'',
            actual_icon:'',
            week_row: '',
            readonly: ''
        }

        this.prodObject = {

        }
        this.gutObject = {

        };

        this.current_products = '';
        this.componentDidMount = this.componentDidMount();


    }



    componentDidMount = async () => {
        let {id} = this.props.params;
        this.id = id;
        this.fetchData(id, 'start');

        //  console.log(this.state.carb_actual_add['week_one']);

        /// Carb Plus Minus Function

        this.carbPlusMinus = async (event) => {
            //console.log(event.target.defaultValue);
            //console.log(event.target.value);
            const auth = getAuth();
            const user = auth.currentUser;
            const user_id = user.uid;

            const {carb_actual_add} = this.state;
            const {sodium_actual_add} = this.state;


            let startServing = event.target.defaultValue;
            let serving = event.target.value;

            let inputID = event.target.id;

            let add_serving = .5;

            let inputArray = inputID.split('-');

            let productID = inputArray[1];
            let week_name = inputArray[0];
            let carb_actual_new = 0;
            let sodium_actual_new = 0;



            if(this.product_td !== inputID){
                this.serving_count = event.target.defaultValue;
            }



            let updateItem = this.state.productInfo.find(element => element.id == productID);


            let carbActualStart = parseFloat(this.state.carb_actual_add[week_name]);
            let sodiumActualStart = parseFloat(this.state.sodium_actual_add[week_name]);

            let carbServing = parseFloat(updateItem.carbs) * add_serving;
            let sodiumServing = parseFloat(updateItem.sodium) * add_serving;

            if(parseFloat(serving) > parseFloat(this.serving_count)) {
                carb_actual_new = parseFloat(carbActualStart) + carbServing;
                sodium_actual_new = parseFloat(sodiumActualStart) + sodiumServing;
            }else{
                carb_actual_new = parseFloat(carbActualStart) - carbServing;
                sodium_actual_new = parseFloat(sodiumActualStart) - sodiumServing;

            }


            // let carbandserving = result[i].product_info[p].carbs * result[i].product_info[p].serving;
            //this.carb_actual_add = carbandserving + this.carb_actual_add;

            this.state.carb_actual_add[week_name] = carb_actual_new;
            this.state.sodium_actual_add[week_name] = sodium_actual_new;

            this.setState({carb_actual_add});
            this.setState({sodium_actual_add});

            await updateDoc(doc(db, "users", user_id, 'gut-training', id), {
                //products: arrayUnion
            });

            this.serving_count = event.target.value;

            this.product_td = event.target.id;

        } // End Carb Plus Minus Function


    }
    fetchData = async (id, update) => {
        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;

// fetch gut-training by param
        const docSnap = await getDoc(doc(db, "users", user_id, 'gut-training', id));

        let sodium_goal_letter = docSnap.data().sodium_question;
        this.sodium_loss = docSnap.data().sodium_loss;

        if(this.sodium_loss === "no") {
            switch (sodium_goal_letter) {
                case 'a':
                    this.na_goal = 500;
                    break;
                case 'b':
                    this.na_goal = 1000;
                    break;
                case 'c':
                    this.na_goal = 1500;
                    break;
                default:
                    this.na_goal = 500;
                    break;

            }
        }else{
            this.sodium_loss_value = docSnap.data().sodium_loss_value;

        }


        // get race id
        let race_id = docSnap.data().race_details.id;

        // get race-plan from database
        const raceSnap = await getDoc(doc(db, "users", user_id, 'race-plan', race_id));

        var race_date = Date.parse(raceSnap.data().race_date);
        var week_date = 7 * 24 * 60 * 60 * 1000;
        var todays_date = new Date();
        todays_date = Date.parse(todays_date);

        // set states to use in table
        //this.setState({title: raceSnap.data().race_name});
        this.setState({date: raceSnap.data().race_date});
        this.setState({carb_consume: docSnap.data().carb_consume});
        this.setState({sodium_loss: docSnap.data().sodium_loss});
        this.setState({sodium_loss_value: docSnap.data().sodium_loss_value});
        this.setState({carb_consume_value: docSnap.data().carb_consume_value});
        this.setState({fluids_value: docSnap.data().fluids_value});
        this.setState({current_products: docSnap.data().products});
        this.setState({race_duration: raceSnap.data().race_time});


        // get all current product ID's from gut training
        this.current_products = docSnap.data().products;

        // get sweat testing from database --> needed for calculations
        const sweatSnap = await getDocs(collection(db, "users", user_id, 'sweat-test'));

        // loop through sweat-trainings to get averages
        sweatSnap.forEach((doc) => {
            //    console.log(doc.data().duration);
            this.startWeight.push(doc.data().start_weight);
            this.endWeight.push(doc.data().end_weight);
            this.drinkVolume.push(doc.data().dvolume);
            this.race_duration = doc.data().duration;
        });

        const {productInfo} = this.state;

        const prodSnap = await getDocs(collection(db, "products"));

        Object.values(docSnap.data().products).forEach((doc) => {
            //console.log(doc);
            let prodObject = {
                ['id']: doc.product_id,
                ['name']: doc.name,
                ['calories']: doc.calories,
                ['carbs']: parseInt(doc.carbs),
                ['sodium']: doc.sodium,
                ['serving']: doc.serving,
            }
            this.state.productInfo.push(prodObject);



        });

        this.setState({productInfo});

        //console.log(productInfo);
        // function to produce averages
        const average = array => array.reduce((a, b) => a + b, 0) / array.length;

        // average all numbers for calculations
        let average_start_weight = average(this.startWeight);
        let average_end_weight = average(this.endWeight);
        let average_drink_volume = average(this.drinkVolume);
        let sweat_duration = average(this.sweat_duration);
        let average_fluid_intake;


        let weight_diff = average_start_weight - average_end_weight;

        let total_sweat_loss = (weight_diff * 16) + average_drink_volume;

        let length_of_event = this.race_duration / 60;

        let sweat_rate = total_sweat_loss / length_of_event;

        sweat_rate = Math.round(sweat_rate * 100) / 100;

        let total_tolerable = average_start_weight * (2 / 100) * 16;

        let possible_fluid_loss = sweat_rate * length_of_event;

        let total_fluid_must_be_consumed = possible_fluid_loss - total_tolerable;

        let fluid_goal_per_hour = Math.round(total_fluid_must_be_consumed / length_of_event * 100) / 100;

        let sodium_calc_value = this.sodium_loss_value * fluid_goal_per_hour;

        console.log(this.sodium_loss_value);

        if (length_of_event >= 3) {
            var carb_goal = 90;
        } else if (length_of_event < 3 && length_of_event > 1.5) {
            var carb_goal = 60;
        } else if (length_of_event > 0 && length_of_event <= 1.5){
            var carb_goal = 30;
        }
        if(this.sodium_loss === "yes") {
            // Sodium Goals for Weeks
            var na_goal_week_one = Math.round(sodium_calc_value * 1.25);
            var na_goal_week_two = Math.round(na_goal_week_one - na_goal_week_one * .1);
            var na_goal_week_three = Math.round(na_goal_week_two - na_goal_week_two * .1);
            var na_goal_week_four = Math.round(na_goal_week_three - na_goal_week_three * .1);
            var na_goal_week_five = Math.round(na_goal_week_four - na_goal_week_four * .1);
            var na_goal_week_six = Math.round(na_goal_week_five - na_goal_week_five * .1);
            var na_goal_week_seven = Math.round(na_goal_week_six - na_goal_week_six * .1);
            var na_goal_week_eight = Math.round(na_goal_week_seven - na_goal_week_seven * .1);
            var na_goal_week_nine = Math.round(na_goal_week_eight - na_goal_week_eight * .1);
            var na_goal_week_ten = Math.round(na_goal_week_nine - na_goal_week_nine * .1);
        }else{
            var na_goal_week_one = this.na_goal;
            var na_goal_week_two = this.na_goal;
            var na_goal_week_three = this.na_goal;
            var na_goal_week_four = this.na_goal;
            var na_goal_week_five = this.na_goal;
            var na_goal_week_six = this.na_goal;
            var na_goal_week_seven = this.na_goal;
            var na_goal_week_eight = this.na_goal;
            var na_goal_week_nine = this.na_goal;
            var na_goal_week_ten = this.na_goal;
        }

        // Carb Goals for weeks
        var carb_goal_week_one = Math.round(carb_goal * 1.25);
        var fl_goal_week_one = Math.round(fluid_goal_per_hour * 1.25);
        var carb_goal_week_two = Math.round(carb_goal_week_one - carb_goal_week_one * .1);
        var fl_goal_week_two = Math.round(fl_goal_week_one - fl_goal_week_one * .1);
        var carb_goal_week_three = Math.round(carb_goal_week_two - carb_goal_week_two * .1);
        var fl_goal_week_three = Math.round(fl_goal_week_two - fl_goal_week_two * .1);
        var carb_goal_week_four = Math.round(carb_goal_week_three - carb_goal_week_three * .1);
        var fl_goal_week_four = Math.round(fl_goal_week_three - fl_goal_week_three * .1);
        var carb_goal_week_five = Math.round(carb_goal_week_four - carb_goal_week_four * .1);
        var fl_goal_week_five = Math.round(fl_goal_week_four - fl_goal_week_four * .1);
        var carb_goal_week_six = Math.round(carb_goal_week_five - carb_goal_week_five * .1);
        var fl_goal_week_six = Math.round(fl_goal_week_five - carb_goal_week_five * .1);
        var carb_goal_week_seven = Math.round(carb_goal_week_six - carb_goal_week_six * .1);
        var fl_goal_week_seven = Math.round(fl_goal_week_six - fl_goal_week_six * .1);
        var carb_goal_week_eight = Math.round(carb_goal_week_seven - carb_goal_week_seven * .1);
        var fl_goal_week_eight = Math.round(fl_goal_week_seven - fl_goal_week_seven * .1);
        var carb_goal_week_nine = Math.round(carb_goal_week_eight - carb_goal_week_eight * .1);
        var fl_goal_week_nine = Math.round(fl_goal_week_eight - fl_goal_week_eight * .1);
        var carb_goal_week_ten = Math.round(carb_goal_week_nine - carb_goal_week_nine * .1);
        var fl_goal_week_ten = Math.round(fl_goal_week_nine - fl_goal_week_nine * .1);

        //    console.log(this.state.date);

        const {weeklyCarbGoal} = this.state;


        let carbWeeklyObj = {}

        const {carb_actual_add} = this.state;
        const productServings = (week) => {

            let date = new Date();
            let month = date.getMonth();
            let day = date.getDay();
            let year = date.getFullYear();

            let currentDate = month + '-' + day + '-' + year;

            currentDate = new Date(currentDate);

            //console.log(week);
            let prod_count = 0;
            let carb_goal = 0;
            let na_goal = 0;
            let fl_goal = 0;
            let carb_actual = 0;
            let updatedProduct = [];
            let productDone = structuredClone(this.state.productInfo);
            let editProd = [];
            let single_week_date;


            for (let s = 0; s < productDone.length; s++) {
                productDone[s].serving = 0;

            }

            //    console.log(race_date);
            //    console.log(todays_date);
            //    console.log(week_date);
            switch (week) {
                case 'week_ten':
                    carb_goal = carb_goal_week_ten;
                    na_goal = na_goal_week_ten;
                    single_week_date = race_date - (week_date * 10);
                    fl_goal = fl_goal_week_ten;
                    //            console.log(single_week_date);
                    break;
                case 'week_nine':
                    carb_goal = carb_goal_week_nine;
                    na_goal = na_goal_week_nine;
                    single_week_date = race_date - (week_date * 9);
                    fl_goal = fl_goal_week_nine;
                    break;
                case 'week_eight':
                    carb_goal = carb_goal_week_eight;
                    na_goal = na_goal_week_eight;
                    single_week_date = race_date - (week_date * 8);
                    fl_goal = fl_goal_week_eight;
                    break;
                case 'week_seven':
                    carb_goal = carb_goal_week_seven;
                    na_goal = na_goal_week_seven;
                    single_week_date = race_date - (week_date * 7);
                    fl_goal = fl_goal_week_seven;
                    break;
                case 'week_six':
                    carb_goal = carb_goal_week_six;
                    na_goal = na_goal_week_six;
                    single_week_date = race_date - (week_date * 6);
                    fl_goal = fl_goal_week_six;
                    break;
                case 'week_five':
                    carb_goal = carb_goal_week_five;
                    na_goal = na_goal_week_five;
                    single_week_date = race_date - (week_date * 5);
                    fl_goal = fl_goal_week_five;
                    break;
                case 'week_four':
                    carb_goal = carb_goal_week_four;
                    na_goal = na_goal_week_four;
                    single_week_date = race_date - (week_date * 4);
                    fl_goal = fl_goal_week_four;
                    break;
                case 'week_three':
                    carb_goal = carb_goal_week_three;
                    na_goal = na_goal_week_three;
                    single_week_date = race_date - (week_date * 3);
                    fl_goal = fl_goal_week_three;
                    break;
                case 'week_two':
                    carb_goal = carb_goal_week_two;
                    na_goal = na_goal_week_two;
                    single_week_date = race_date - (week_date * 2);
                    fl_goal = fl_goal_week_two;
                    break;
                case 'week_one':
                    carb_goal = carb_goal_week_one;
                    na_goal = na_goal_week_one;
                    single_week_date = race_date - week_date;
                    fl_goal = fl_goal_week_one;
                    break;
                default:
                    na_goal = na_goal;
                    carb_goal = carb_goal;
                    week_date = race_date;
                    break;
            }
            console.log(na_goal);
            let loopCount = 0;
            let carb_count = 0;
            let sodium_count = 0;
            editProd = this.state.productInfo[0];
            let carbs = editProd.carbs * .5;
            let sodium = editProd.sodium * .5;
            carb_count += carbs;
            sodium_count += sodium;
            let i = 0;
            while ((carb_count < carb_goal) && loopCount < 100){
                let updateItem = productDone.find(element => element.id == editProd.id);

                if ( updateItem ) {
                    updateItem.serving += 0.5;
                    i += 1;
                    if( i > this.state.productInfo.length -1)
                        i = 0;
                }
                loopCount += 1;
                editProd = this.state.productInfo[i];
                carbs = editProd.carbs * .5;
                sodium = editProd.sodium * .5;
                carb_count += carbs;
                sodium_count += sodium;

            }

            let updateItem = productDone.find(element => element.id == editProd.id);
            updateItem.serving += 0.5;

            this.state.carb_actual_add[week] = parseFloat(carb_count);
            this.state.sodium_actual_add[week] = parseFloat(sodium_count);


            let newObject = {
                ['product_info']: productDone,
                ['carb_actual']: carb_count,
                ['sodium_actual']: sodium_count,
                ['week']: week,
                ['carb_goal']: carb_goal,
                ['na_goal']: na_goal,
                ['week_date']: single_week_date,
                ['fl_goal']: fl_goal,
                //['week_date']: week_date

            }

            updatedProduct = [newObject];

            return (updatedProduct);


        }


        let week_ten = productServings('week_ten');
        let week_nine = productServings('week_nine');
        let week_eight = productServings('week_eight');
        let week_seven = productServings('week_seven');
        let week_six = productServings('week_six');
        let week_five = productServings('week_five');
        let week_four = productServings('week_four');
        let week_three = productServings('week_three');
        let week_two = productServings('week_two');
        let week_one = productServings('week_one');


        this.state.weeklyCarbGoal.push(week_ten);
        this.state.weeklyCarbGoal.push(week_nine);
        this.state.weeklyCarbGoal.push(week_eight);
        this.state.weeklyCarbGoal.push(week_seven);
        this.state.weeklyCarbGoal.push(week_six);
        this.state.weeklyCarbGoal.push(week_five);
        this.state.weeklyCarbGoal.push(week_four);
        this.state.weeklyCarbGoal.push(week_three);
        this.state.weeklyCarbGoal.push(week_two);
        this.state.weeklyCarbGoal.push(week_one);

        this.setState({carb_actual_add});

        //console.log(this.state.weeklyCarbGoal);
        //console.log(this.carb_actual_start);

        //console.log(this.state.weeklyCarbGoal);

        $(document).ready(function () {
            setTimeout(function () {
                $('#gut-details-view').DataTable({
                    fixedColumns:   {
                        left: 1,
                        right:0
                    },
                    scrollCollapse: true,
                    "ordering": false,
                    scrollX: true,
                    scrollY: true,
                    searching: false,
                    paging: false,
                    info: false,

                });
            });


        });



    }
    render(){
        console.log(this.state.weeklyCarbGoal);
        return (

            <div className="pageContainer">
                <Navbar/>
                <div className="myRacePlanContainer">

                    <div className="clearfix"></div>
                    <div className="race-plan-container">

                        <div className="gutDetails">
                            <h1>{this.state.title}</h1>
                            <h3>{this.state.race_date}</h3>

                        </div>

                        <div className="gut-details-table">
                            <table id="gut-details-view">
                                <thead>
                                <tr>
                                    <th className="weeks-table">Weeks</th>
                                    {
                                        this.state.productInfo.map((result) => {

                                            return(
                                                <th className="product-col">{result.name.substring(0, 20)}</th>
                                            )

                                        })
                                    }
                                    <th>Carb Actual</th>
                                    <th>Carb Goal/hr (g)</th>
                                    <th>NA Actual</th>

                                    <th>NA Goal/hr (mg)</th>
                                    <th>Fluid Goal/hr (oz)</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    // Loop through weeklyCarb Goal

                                    Object.values(this.state.weeklyCarbGoal).map((result) => {
                                        for (let i = 0; i < result.length; i++) {
                                            let weekCarb = this.state.carb_actual_add[result[i].week];
                                            let weekSodium = this.state.sodium_actual_add[result[i].week];

                                            let week_date = result[i].week_date;

                                            let date_week = new Date(week_date);

                                            let dd = date_week.getDate();
                                            let mm = date_week.getMonth() + 1;
                                            let year = date_week.getFullYear();

                                            let readable_week = mm + '/' + dd + '/' + year;

                                            //   console.log(week_date);

                                            // If carb goal is between -5 and -10
                                            if(weekCarb < result[i].carb_goal - 5 && weekCarb > result[i].carb_goal - 10){
                                                this.state.carb_actual_class = 'carb-actual-five';
                                                this.state.faicon = <FontAwesomeIcon icon={faUpLong} />;
                                            }
                                            // If carb goal is between +5 and +10
                                            if (weekCarb > result[i].carb_goal + 5 && weekCarb < result[i].carb_goal + 10) {
                                                this.state.carb_actual_class = 'carb-actual-five';
                                                this.state.faicon = <FontAwesomeIcon icon={faDownLong} />;
                                                // Else if carb goal is in normal range
                                            }
                                            if (weekCarb >= result[i].carb_goal - 5 && weekCarb <= result[i].carb_goal + 5){
                                                this.state.carb_actual_class = 'carb-actual-ok';
                                                this.state.faicon = <FontAwesomeIcon icon={faCheck} />;
                                            }


                                            // If carb goal is above +10 or below -10
                                            if(weekCarb <= result[i].carb_goal - 10){
                                                this.state.carb_actual_class = 'carb-actual-ten';
                                                this.state.faicon = <FontAwesomeIcon icon={faUpLong} />;
                                            }
                                            if (weekCarb >= result[i].carb_goal + 10) {
                                                this.state.carb_actual_class = 'carb-actual-ten';
                                                this.state.faicon = <FontAwesomeIcon icon={faDownLong}/>;
                                            }

                                            if(weekSodium < result[i].na_goal - 250 && weekSodium > result[i].na_goal - 500){
                                                this.state.sodium_actual_class = 'sodium-actual-five';
                                                this.state.highicon = <FontAwesomeIcon icon={faUpLong} />;
                                            }
                                            if (weekSodium > result[i].na_goal + 250 && weekSodium < result[i].na_goal + 500) {
                                                this.state.sodium_actual_class = 'sodium-actual-five';
                                                this.state.highicon = <FontAwesomeIcon icon={faDownLong} />;
                                            }
                                            if(weekSodium >= result[i].na_goal - 250 && weekSodium <= result[i].na_goal + 250){
                                                this.state.sodium_actual_class = 'sodium-actual-ok';
                                                this.state.highicon = <FontAwesomeIcon icon={faCheck} />;
                                            }


                                            // If sodium goal is above +1000 or below -1000
                                            if(weekSodium <= result[i].na_goal - 500){
                                                this.state.sodium_actual_class = 'sodium-actual-ten';
                                                this.state.highicon = <FontAwesomeIcon icon={faUpLong} />;
                                            }
                                            if(weekSodium >= result[i].na_goal + 500){
                                                this.state.sodium_actual_class = 'sodium-actual-ten';
                                                this.state.highicon = <FontAwesomeIcon icon={faDownLong}/>;
                                            }

                                            let nowDate = new Date();
                                            let week = 7 * 24 * 60 * 60 * 1000;

                                            let current_date = nowDate.getFullYear()+'-'+(nowDate.getMonth()+1)+'-'+nowDate.getDate();

                                            current_date = Date.parse(current_date);

                                            //console.log(current_date);

                                           /* if(result[i].week_date < current_date){
                                                //console.log(result[i].week_date);
                                                this.state.week_row = 'past-week';
                                                this.state.readonly = true;
                                            }
                                            if(current_date < result[i].week_date && current_date < result[i].week_date + (week * 2)){
                                                this.state.week_row = 'current-week';
                                                this.state.readonly = false;
                                            }
                                            if(result[i].week_date > current_date + week){
                                                this.state.week_row = 'upcoming-week';
                                                this.state.readonly = true;
                                            }

                                            */

                                            this.state.readonly = true;


                                            switch(result[i].week){
                                                case 'week_ten':
                                                    this.weekName = '10 Weeks Out';
                                                    break;
                                                case 'week_nine':
                                                    this.weekName = '9 Weeks Out';
                                                    break;
                                                case 'week_eight':
                                                    this.weekName = '8 Weeks Out';
                                                    break;
                                                case 'week_seven':
                                                    this.weekName = '7 Weeks Out';
                                                    break;
                                                case 'week_six':
                                                    this.weekName = '6 Weeks Out';
                                                    break;
                                                case 'week_five':
                                                    this.weekName = '5 Weeks Out';
                                                    break;
                                                case 'week_four':
                                                    this.weekName = '4 Weeks Out';
                                                    break;
                                                case 'week_three':
                                                    this.weekName = '3 Weeks Out';
                                                    break;
                                                case 'week_two':
                                                    this.weekName = '2 Weeks Out';
                                                    break;
                                                case 'week_one':
                                                    this.weekName = '1 Week Out';
                                                    break;
                                                default:
                                                    this.weekName = '';
                                                    break;

                                            }



                                            return (<>
                                                    <tr className={this.state.week_row}>
                                                        <td className="weeks-table">{this.weekName} - {readable_week}</td>
                                                        { ( () => {
                                                            let td = [];
                                                            for(let p = 0; p < result[i].product_info.length; p++) {
                                                                td.push(<td>{<input className="serving" readOnly={this.state.readonly} name="serving" id={result[i].week + '-' + result[i].product_info[p].id} step=".5" min="0" type="number" defaultValue={result[i].product_info[p].serving} ref={this.userServing} onChange={this.carbPlusMinus}/>}</td>);

                                                            }
                                                            return td;
                                                        } ) () }
                                                        <td className={this.state.carb_actual_class}>{this.state.carb_actual_add[result[i].week]} {this.state.faicon}</td>
                                                        <td>{result[i].carb_goal}</td>
                                                        <td className={this.state.sodium_actual_class}>{this.state.sodium_actual_add[result[i].week]} {this.state.highicon}</td>
                                                        <td>{[result[i].na_goal]}</td>
                                                        <td>{result[i].fl_goal}</td>

                                                    </tr>

                                                </>

                                            )
                                        }

                                    })
                                }


                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>

        )
    }


}

export default withParams(GutDetailsCompleted);