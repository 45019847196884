import React, { Component } from "react";
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import { getAuth } from 'firebase/auth';
import { query, limit, orderBy, collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/db.js';


class DashProdModule extends Component {
    constructor(props) {
        super(props);
        this.modulecalories = 0;
        this.moduletype= '';
        this.modulecarbs= 0;
        this.modulefiber= 0;
        this.modulename= '';
        this.modulesodium= 0;
        this.moduleflavor= '';


        this.state={
            modData: [],
            userData: []
        };



    }

    componentDidMount = async () =>{
        const { modData } = this.state;

        const productsQ = query(collection(db, "products"), orderBy("timestamp"), limit(5));

        const docSnap = await getDocs(productsQ);
        docSnap.forEach((doc) => {

            let obj = {
                ['type']:doc.data().type,
                ['name']:doc.data().name,
                ['carbs']:doc.data().carbs,
                ['sodium']:doc.data().sodium,
                ['fiber']:doc.data().fiber,
                ['flavor']:doc.data().flavor,
                ['calories']:doc.data().calories,

            }

            modData.push(obj);

        });


        this.setState({modData});





    }
    render()
    {
        return(
            <div>
            {this.state.modData.map((result) => {
        return(
            <div className="row prod-item-single">
                <div className="col">
                    <p>{result.name}</p>
                </div>
                <div className="col">
                    ...
                </div>
                <hr />
            </div>

        )
    })}

            </div>)



    }

}

export default DashProdModule;