import React from 'react';
import { useSession } from '../../firebase/UserProvider';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { UserMetaForm } from "../../functions/user-functions/updateUserMeta";
import Navbar from "../../components/Navbar";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import DataTable from 'react-data-table-component';
import {useTable} from 'react-table';
import { useState } from 'react';
import SweatForm from '../../functions/user-functions/addSweatDoc';
import GetSweatTable from '../../functions/user-functions/getSweatTable';
import Accordion from "react-bootstrap/Accordion";


function Sweat(){

    const [showSession, setShowSession] = useState(false);

    const handleClick = event => {
        setShowSession(current => !current);
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        const emailVerified = user.emailVerified;
        const user_id = user.uid;


        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;

        return (
            <div className="pageContainer">
                <Navbar />


                <div className="sweatTestContainer">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Follow these simple steps to calculate your sweat rate at home!</Accordion.Header>
                            <Accordion.Body>
                                <b>1. Get your equipment ready.</b><br />
                                <span>Here’s what you will need to sweat test:</span>
                                <ul>
                                    <li>A dry towel</li>
                                    <li>Body weight scale</li>
                                </ul>
                                <p>
                                <b>2. Record your Start Body Weight.</b><br />
                                <span>Fill up whatever water bottle you will be using to hydrate with during your training session and make note of how many ounces you are starting with.</span>
                                </p>
                                <p>
                                    <b>3. Record your Start Drink Volume.</b><br />
                                    <span>Be sure to empty your bladder before weighing yourself. Before stepping on the scale, make sure your clothes are off so that you can get the most accurate weight.</span>
                                </p>
                                <p>
                                <b>4. Workout!</b><br />
                                <span>Be sure to record the duration of your training session.</span>
                                </p>
                                <p>
                                <b>5. Record your End Body Weight.</b><br />
                                <span>Dry yourself off with a dry towel, and weigh yourself (BEFORE emptying your bladder). Again, try to do this without clothes on to avoid skewing your results with the added weight from sweat. Please record your weight in pounds (lbs).</span>
                                </p>
                                <p>
                                <b>6. Record your Final Drink Volume. </b><br />
                                <span>Make note of how many ounces of the liquid remain in your bottle.</span>
                                </p>
                                <p>
                                    <b>7. Calculate your Drink Volume.</b><br />
                                    <span>Calculate your Drink Volume (oz) and log it below by subtracting your Final Drink Volume from your starting drink volume to get total ounces consumed. </span>
                                </p>
                                <p>
                                <b>8. Repeat!</b><br />
                                <span>Be sure to repeat steps 1 through 6 multiple times during different training sessions in varying outdoor or indoor temperatures and humidities.</span>
                                </p>
                                <p>
                                <i>References:</i><br />
                                <ol><li>Baker L. B. (2017). Sweating Rate and Sweat Sodium Concentration in Athletes: A Review of Methodology and Intra/Interindividual Variability. Sports medicine (Auckland, N.Z.), 47(Suppl 1), 111–128. https://doi.org/10.1007/s40279-017-0691-5</li></ol>
                                </p>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>WHY WE GATHER SWEAT TESTING DATA</Accordion.Header>
                            <Accordion.Body>

                                <p>
                                    Gathering sweat testing data helps us determine your individual fluid and sodium losses so that we can properly prepare a hydration plan to use while training and on race day. Sweating allows heat to escape the body, but it also results in the loss of fluid and electrolytes (aka, sodium).
                                </p>

                                <h5>Why?</h5>
                                <p>
                                Not every person sweats the same. And you won't have the same rate of fluid and sodium losses in every condition. Rates of sweating can vary from very little to as much as 5 liters per hour, so it's important to determine where you lie on the spectrum so that we can ensure we are addressing electrolyte losses and the necessary replenishments so you avoid dehydration, "bonking" or any GI distress on competition day. Fluid replacement during exercise is important for maintaining blood sodium concentration and osmolarity, as well as increasing blood volume retention. (Barnes et. al, 2019)
                                </p>

                                <h5>What affects your sweat loss?</h5>
                                <p>
                                Heat is the main factor that determines how much we sweat - whether that's from the training intensity or the weather. The more power you are producing, the more heat you produce. Your level of training, your clothing choices and genetics also play a role. For these varying reasons, it's important to gather sweat testing data in a number of settings that mimic training and competition day.
                                </p>
                                <h5>
                                Sweat testing & endurance activity
                                </h5>
                                <p>
                                Because endurance athletes are performing and sweating for multiple hours in their sport, this results in a longer time allotment for fluid losses to add up, endurance athletes usually find it necessary to replace a great percentage of fluid losses. And since there is realistically more time to be able to drink and tolerate fluids, it actually becomes possible. For this reason, it can benefit endurance athletes to fine tune what that means for them with sweat testing and hydration plans. Larger sweat losses can end up impacting performance, especially in hotter conditions or climates. Knowing your rate of sweat can also help you from overdrinking, which may help you feel better during training and racing, as well as help you maximize your time efficiency when refueling during competition.
                                </p>
                                <h5>
                                Signs you you might be dehydrated
                                </h5>
                                <p>
                                The most common signs of dehydration include fatigue, elevated heart rate, thirst, dry skin and lips, dark urine or less urine output, headaches, muscle cramps, lightheadedness, dizziness, increased core temperature, etc. (Kory Taylor; Elizabeth B. Jones. 2022)
                                </p>

                                <h5>References:</h5>
                                <p><i>(Kory Taylor; Elizabeth B. Jones. 2022) <br/>
                                https://pubmed.ncbi.nlm.nih.gov/32310416/<br/>
                                Kory Taylor; Elizabeth B. Jones. Adult Dehydration. Treasure Island (FL): StatPearls Publishing; 2023 Jan<br/>
                                </i>
                                </p>
                                <p>
                                    <i>
                                (Barnes et. al, 2019)<br/>
                                https://pubmed.ncbi.nlm.nih.gov/31230518/<br/>
                                Barnes KA, Anderson ML, Stofan JR, Dalrymple KJ, Reimel AJ, Roberts TJ, Randell RK, Ungaro CT, Baker LB. Normative data for sweating rate, sweat sodium concentration, and sweat sodium loss in athletes: An update and analysis by sport. J Sports Sci. 2019 Oct;37(20):2356-2366.
                                <br/>

                                    </i>
                                </p>
                                </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="sweat-test-table-container">
                        <h3 className="module-title">Sweat Testing</h3>
                        <div className="sweatTestTable">
                            <GetSweatTable />
                        </div>
                        </div>
                    <div className="sweat-test-add-session">
                        <button onClick={() => handleClick()}> Add Session</button>
                    </div>
                    {showSession && (
                        <div className="add-new-session-container">
                            <h3 className="module-title">Sweat Testing > Add Session</h3>
                            <SweatForm />
                        </div>
                    )}
                </div>

            </div>
        )
    }else{
        console.log('user not logged in');
        return (

            <div className="pageContainer">
                <p>User Not Logged In</p>
            </div>

        )
    }





}

export default Sweat;