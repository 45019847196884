import React, {Component} from "react";
import {getAuth} from "firebase/auth";
import AdminNav from "../../components/AdminNav";
import AddProduct from "../../functions/admin-functions/add-products";
import GetProdTable from "../../functions/admin-functions/product-table";

class Products extends Component{
    constructor(props){
        super(props);

        this.state = {
            showSession: true,
        }



    }

    handleClick = (event) => {
        this.setState({
            showSession: true
    });
    }


    render() {
        return (
            <div className="pageContainer">
                <AdminNav/>

                <div className="productContainer">
                    <div className="sweat-test-table-container">
                        <h3 className="module-title">Products</h3>
                        <div className="sweatTestTable">
                            <GetProdTable/>
                        </div>
                    </div>
                    <div className="sweat-test-add-session">
                        <button onClick={this.handleClick}> Add Product</button>
                    </div>
                    {this.showSession && (
                        <div className="add-new-session-container">
                            <h3 className="module-title">Sweat Testing > Add Session</h3>
                            <AddProduct/>
                        </div>
                    )}
                </div>

            </div>
        )

    }

}

export default Products;