import React from 'react';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AdminNav from '../../components/AdminNav';
import UserDashModule from '../../functions/admin-functions/user-dash-module.js';
import ProdDashModule from '../../functions/admin-functions/product-dash-module.js';
function AdminDash() {

    return (

        <div className="admin-dash-container">
            <AdminNav/>
            <div className="admin-dash-modules container-xxl">
                <div className="row row-cols-2">

                    <div className="col">
                        <div className="col dash-module-single">
                            <div className="dash-module-title-container">
                                <span className="dash-module-title">Clients</span>
                            </div>
                            <div className="dash-module-content">
                                <UserDashModule />
                            </div>
                        </div>

                    </div>
                    <div className="col">
                        <div className="col">

                            <div className="dash-module-title-container">
                                <span className="dash-module-title">Products</span>
                            </div>
                            <div className="row">
                                <a href="/practitioner-dashboard/products/add-product">View All Products</a>
                            </div>
                            <div className="row dash-module-content">
                                <div className="col">
                                    <ProdDashModule />
                                </div>


                            </div>


                        </div>

                    </div>
                </div>


                </div>


        </div>
    );


}


export default AdminDash;