import React, {Component} from "react";
import {getAuth} from "firebase/auth";
import AdminNav from "../../components/AdminNav";
import AddProduct from "../../functions/admin-functions/add-products";
import GetProdTable from "../../functions/admin-functions/product-table";
import {doc, getDocs, collection, addDoc, deleteDoc} from "firebase/firestore";
import {db} from "../../firebase/db";
import Accordion from "react-bootstrap/Accordion";

class Products extends Component{
    constructor(props){
        super(props);

        this.title = React.createRef();
        this.content = React.createRef();

        this.state = {
            showSession: true,
            resourceData: [],
        }

        this.componentDidMount = this.componentDidMount();
        this.removeRes = this.removeRes.bind(this);

    }
    componentDidMount = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;

        // initialize product state
        const {resourceData} = this.state;

        // update the gut training per param ID
        const resourceSnap = await getDocs(collection(db, 'resources'));
            resourceSnap.forEach((doc) => {
               let resourceObj = {
                   id:doc.id,
                   title:doc.data().title,
                   content:doc.data().content,
               }

               this.state.resourceData.push(resourceObj);

            });

            this.setState({resourceData});

    }
    handleClick = (event) => {
        this.setState({
            showSession: true
        });
    }

    removeRes = async (e) => {
        let removeID = e.target.getAttribute('data-resid');

        await deleteDoc(doc(db, 'resources', removeID)).then(() => {
            window.location.reload();
        });

    }

    addRes = async (e) => {
        e.preventDefault();
        let title = this.title.current.value;
        let content = this.content.current.value;

        await addDoc(collection(db, 'resources'),{
            title:title,
            content:content,
        });

    }


    render() {
        console.log(this.state.resourceData);
        return (
            <div className="pageContainer">
                <AdminNav/>

                <div className="resourcesContainer">
                    <div className="resources-inner">
                        <div className="container row">
                            <div className="col">
                                <h4>Current Resources</h4>
                                {

                                    this.state.resourceData.map((result) => {
                                        return(<div id={result.id}>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>{result.title}</Accordion.Header>
                                                <Accordion.Body>
                                                    {result.content}
                                                    <div className="remove-container"><span data-resid={result.id} className="remove-res" onClick={this.removeRes}>X Remove</span></div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        </div>)
                                    })
                                }
                            </div>
                            <div className="col">
                                <h4>Add New Resource</h4>
                                <form onSubmit={this.addRes}>
                                    <label for="resource-title">Title</label><br/>
                                    <input type="text" id="resource-title" name="resource-title" ref={this.title}/>
                                    <br/>

                                    <label for="resource-content">Content</label><br />
                                    <textarea id="resource-content" name="resource-content" ref={this.content}>

                                    </textarea>
                                    <button type="submit" value="Add Resource"> Add Resource</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )

    }

}

export default Products;