import firebase from 'firebase/compat/app';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

const Redirect = () => {
    let navigate = useNavigate();
    navigate("/", {replace:true});
}

export const signOutFunction = async () => {

    const auth = getAuth();
    signOut(auth).then(() => {
       return (<Redirect />)
    }).catch((error) => {
        return (<Redirect />)
    });
}