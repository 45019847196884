import React, { useContext } from 'react';
import { Route, Navigate, Routes, Redirect } from 'react-router-dom';
import {getAuth} from "firebase/auth";

export const ProtectedRoute = ({ children }) => {

    const auth = getAuth();
    const user = auth.currentUser;

    console.log(user);

    if(!user){
        return (
            <Navigate to={"/"}>Login</Navigate>
        )
    }else{
        return children;
    }

}