import React, {Component} from 'react';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Navbar from '../../components/Navbar';
import {getAuth} from "firebase/auth";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../firebase/db";
import {Navigate} from 'react-router-dom';
import Accordion from "react-bootstrap/Accordion";
import {useAuth} from "../../firebase/useAuth";

class Suggest extends Component {
    constructor(props) {
        super(props);

    }

    suggestForm = () => {
        return(
            <form>
                <label for="name">Full Name</label><br/>
                <input type="text" name="name" /><br/>
                <label htmlFor="email">Email</label><br/>
                <input type="text" name="email"/><br/>

                <hr/>
                <h2>Product Info</h2><br/>
                <label htmlFor="brand">Product Suggestion</label><br/>
                <input type="text" name="brand"/><br/>
                <label htmlFor="flavor">Flavor</label><br/>
                <input type="text" name="flavor"/><br/>
                <label htmlFor="Type">Flavor</label><br/>
                <select type="text" name="flavor">
                    <option>Fuel</option>
                    <option>Hydration</option>
                </select>
                    <br/>
                <label htmlFor="website">Website Link to Product</label><br/>
                <input type="url" name="website"/><br/>
                <button type="submit" value="send">Send</button>
            </form>
        )
    }

    render() {
        console.log(this.auth);
        return (

            <div className="client-dash-container">
                <Navbar/>

                <div className="suggest-product-container">
                    <h1>Suggest a Product</h1>
                    {this.suggestForm()}

                </div>

            </div>
        );
    }

}


export default Suggest;