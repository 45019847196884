import React, {Component} from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useForm } from 'react-hook-form';
import { loginTab } from './Login.js';
import {Tab, Tabs, TabPanel, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {useNavigate, Navigate} from 'react-router-dom';
import { signIn } from "../firebase/auth";

function withNavigation(Component : Component){
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Privacy extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <div className="pageContainer">
                <div className="privacyContainer">
                    <h1>Privacy Policy</h1>

                    <p><b>Effective September 14th, 2022</b></p>
                    <p>
                    SGT by Nutritional Revolution (“SGT,” “Nutritional Revolution,” “We,” or “Us”) is committed to protecting your privacy and utilizing technology that gives you a powerful and safe online experience. This Privacy Policy applies to the Nutritional Revolution-owned and operated websites and applications and governs data collection and usage.
                    </p>
                    <p>
                    This Privacy Policy describes the information we collect, how we process and share it, how to manage your privacy settings, and your rights in connection with using the service. Please also read the Terms and Conditions which set out the terms for using Nutritional Revolution’s service. For residents of the European Union, Nutritional Revolution is the controller of your information for purposes of the EU data protection laws (GDPR).
                    </p>
                    <p>
                    By using Nutritional Revolution’s website and applications you consent to the data practices described in Nutritional Revolution’s Privacy Policy and to Nutritional Revolution’s Terms and Conditions. Questions or comments may be submitted via email to nutritionalrev@gmail.com or by mail to:
                    </p>
                    <p>
                    PO Box 881 <br/>
                    Woodacre, California 94973
                    </p>
                    <h3>What this Privacy Policy Covers</h3>
                    <p>
                    This Privacy Policy covers our treatment of personal data that we collect through your use of the Nutritional Revolution websites and applications. This policy does not apply to the practices of companies that we do not own and/or control or to people we do not employ or manage.
                    </p>

                    <h3>Our Legal Basis for Collecting, Storing and Processing your Personal Data</h3>
                    <p>
                    To provide the Nutritional Revolution services, we collect, store and process the personal data you provide. In certain cases, we may store and process your personal data to comply with Nutritional Revolution’s legal obligations for record keeping and other compliance with laws or regulatory compliance. The personal data we hold about you is processed by us on the basis of our legitimate interests in providing the Nutritional Revolution services.
                    </p>

                    <h3>Information Nutritional Revolution Collects</h3>
                    <p>
                    Nutritional Revolution collects personal data provided by you, if you choose to provide the data, such as:
                    </p>
                    <ul>
                    <li>Basic account information: name, birth date, gender, email address</li>
                    <li>Use of the Nutritional Revolution’s service is limited to users that are sixteen (16) years of age and older.</li>
                    <li>
                        By using the site and providing your personal data you represent that you are sixteen (16) years of age or older. Nutritional Revolution does not collect information from children under 16 years of age.
                    </li>
                    <li>
                        Profile information: preferred sports, preferred unit of measure, preferred nutrition products.
                    </li>
                    <li>
                        Connection information: coach, training partner, or friends.
                    </li>
                    <li>
                        Health information: height, weight, sweat rate, actual performance information.
                    </li>
                    <li>
                    Events and nutrition plans: planned activities (training and races), nutrition plans as generated by Nutritional Revolution or as modified by you or others on your behalf, planned and actual nutrition, target and actual performance, annotations on plans and performance.
                    </li>
                    </ul>
                    <h3>
                        Nutritional Revolution may also collect data from others:
                    </h3>
                    <p>
                        Nutritional Revolution allows you to sign up and log in using your third-party accounts, such as Facebook, Twitter, Strava, payment processors, Amazon, etc. If you access Nutritional Revolution services with a third-party account, we will collect information that you have agreed to make available to that third-party. This information is collected by the third-party and is provided to Nutritional Revolution under the third-party’s privacy policies. You can use the privacy settings in your third-party account to control the information that we receive from these sources.
                    </p>
                    <h3>
                    Nutritional Revolution also collects information about the way you use the website or applications:
                    </h3>
                    <p>
                        Nutritional Revolution collects information from your browser, computer, or mobile device, which provides us with technical information when you access or use the SGT services. This technical information includes device and network information, cookies, log files and analytics information.
                    </p>
                    <p>
                    Nutritional Revolution is not a covered entity or a business associate of any covered entity for the purposes of the Health Insurance Portability and Accountability Act of 1996 (HIPAA). Nutritional Revolution is, therefore, not subject to the rules of HIPAA.
                    </p>
                    <h2>
                    Use of Cookies
                    </h2>
                    <p>
                        Nutritional Revolution uses “cookies,” a small text file transferred to your device, to help provide you a better user experience. The Options/Settings section of most internet browsers will tell you how to manage cookies, including how to disable such technologies. Please be advised that disabling cookies may impact our website features and may inhibit the use of Nutritional Revolution’s services.
                    </p>

                    <p>We may use the following cookies:</p>
                    <ul>
                    <li>
                        Strictly necessary cookies. These are cookies that are required for the operation of our website and may include, for instance, cookies that enable you to log into your account.
                    </li>
                    <li>
                        Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our website when using it. This helps us to improve the way our website works, for example, by ensuring that you find what you are looking for with ease.
                    </li>
                    <li>
                        Targeting cookies. These cookies record your visit to our websites, the pages you have visited and the links you have followed. We use this information to make our websites more relevant to your interests.
                    </li>
                    </ul>
                    <p>
                    If you have questions or concerns about our use of cookies, please contact nutritionalrev@gmail.com.
                    </p>
                    <h3>
                    How Your Personal Data May Be Used
                    </h3>
                    <p>
                    <u>Nutritional Revolution will use your information to provide services:</u><br/>
                    Nutritional Revolution uses the information we collect and receive to provide the services described in this Privacy Policy. For example, we use your health information (e.g. weight, sweat rate), expected performance (e.g. race time), preferences (e.g. nutritional product choices), weather (e.g. from a third-party or user) to develop personalized nutrition plans.
                    </p>
                    <p>
                    We may also use your information to accept or credit payments, provide support related to the Nutritional Revolution services, communicate with you, market the Nutritional Revolution services, and enforce our Terms and Conditions.
                    </p>
                    <p>
                    <u>Nutritional Revolution will use your information to enhance its services:</u><br/>
                    Nutritional Revolution will use your information to analyze and improve our services. We may choose to use couple your data with third-party analytics to help us determine how to improve our services. In this case, your information will be de-identified and/or aggregated prior to sharing with the third-party.
                    </p>
                    <h3>
                    Information Sharing and Disclosure
                    </h3>
                    <p>
                        Except as otherwise described in this Privacy Policy, we will not share your personal data with any other person or company.
                    </p>
                    <p>
                    <u>We may share your data with third-parties after de-identifying and aggregating:</u>
                    Nutritional Revolution may de-identify and/or aggregate data you make available in connection with our services to anonymize the information. Examples of such aggregated information could include information regarding nutrition usage, performance, etc. Nutritional Revolution may use, sell, license, and share this information with third-parties for research, business or other purposes to improve its services.
                    </p>

                    <h3>Nutritional Revolution may share your information with third-party service providers:</h3>
                    <p>
                    We may share your information with third-parties who provide services such as payment processing, order fulfillment, service promotion or analysis of the service. These service providers will only have access to the information necessary to perform these limited functions on our behalf and are required to protect and secure your personal information.
                    </p>
                    <h3>Nutritional Revolution will share your information when legally required to do so:</h3>
                    <p>
                    When required to comply with the law (e.g. court orders, warrants, or subpoenas), we may disclose your information to the appropriate parties (e.g. law enforcement, public or governmental agencies, or counsel for private litigants).
                    </p>
                    <h3>
                    How Long Do We Keep Your Information?
                    </h3>
                    <p>
                    We will store your information for as long as you have an account with Nutritional Revolution. We may keep records of transactions with you for a period of up to seven (7) years to comply with IRS regulations.
                    </p>
                    <h3>
                    Where Do We Store Your Information?
                    </h3>
                    <p>
                    Nutritional Revolution’s services are provided from the United States. If you are located outside of the United States and if you choose to use the services or provide information to us, you acknowledge and understand that your information will be transferred, processed and stored in the United States to provide the services and perform the Terms of Service.
                    </p>
                    <h3>
                    Modifying Our Use of Your Personal Data
                    </h3>
                    <p>
                        Under the General Data Protection Regulation (GDPR), you have the following rights related to Nutritional Revolution’s use of your Personal Data:
                    </p>
                    <ul>
                        <li>
                    Right to withdraw consent: you have the right to notify Nutritional Revolution that you no longer want us to process or use your personal data. This will terminate your ability to access the services.
                        </li>
                        <li>
                            Right to data portability: you have the right to obtain a copy of your personal data stored and processed by Nutritional Revolution and direct Nutritional Revolution to transfer your personal data to another controller via commonly acceptable electronic format.
                        </li>
                        <li>
                            Right to erasure/Right to be forgotten: you have the right to request that any of your personal data being stored or processed by Nutritional Revolution is deleted from our records. However, while we can delete your personal data from our current records, copies of your data may be stored in Nutritional Revolution’s inactive and archived records and will be deleted in accordance with Nutritional Revolution’s then-current document management policy. In some cases, your personal data may continue to be stored in archived records of transactions or activities where Nutritional Revolution is required by law to retain such records.
                        </li>
                        <li>
                            Right to restriction of processing: you may request that Nutritional Revolution restrict the processing of your personal data. In such case, this may affect your ability to use the SGT services.
                        </li>
                        <li>
                            To exercise any of your rights above, please contact Nutritional Revolution at nutritionalrev@gmail.com. If you choose to exercise any of your rights listed above, it may affect your ability to use our website or applications, as the operation and functionalities provided may require the use of your personal data.
                        </li>
                        </ul>
                    <h3>
                    Managing Your Information
                    </h3>
                    <p>
                        Updating your account:
                    </p>
                    <p>
                    You may review and update your account information at any time by visiting https://sgt.nutritional-revolution.com.
                    </p>
                    <h3>Adjusting email preferences:</h3>
                    <p>
                        You can adjust your email preferences by visiting https://sgt.nutritional-revolution.com. You may also unsubscribe at any time by selecting unsubscribe at the bottom of any email. Note that administrative emails (e.g. confirming purchases, updates to policies and terms) do not contain an unsubscribe option as these types of email are required to provide the SGT services.
                    </p>
                    <h3>
                        Obtaining a copy of your information:
                    </h3>
                    <p>
                    You may request a copy of your personal data by sending an email to nutritionalrev@gmail.com. There may be a reasonable charge based on administrative costs for processing this request.
                    </p>
                    <h3>
                    Removing your information:
                    </h3>
                    <p>
                        You may delete your account and have your information removed by visiting https://sgt.nutritional-revolution.com. Once your account is deleted, your data will also be deleted, and you will no longer have access to any of that data.
                    </p>
                    <h3>
                    Security
                    </h3>
                    <p>
                    We will take reasonable and appropriate measures to protect your personal data from loss, misuse and unauthorized access, disclosure, alteration and destruction. However, no electronic storage method or data transmission over the Internet can be guaranteed to be 100% secure.
                    </p>
                    <h3>
                    Changes to this Privacy Policy
                    </h3>
                    <p>
                        We reserve the right to change, modify or otherwise update this policy at any time. These changes or updates will be effective immediately. We may provide you notice of such changes when they are material. Notice may be given by posting on the website or applications, by electronic or conventional mail or by any other means by which you obtain notice of the changes or updates.
                    </p>
                    <h3>
                    Policies of Other Websites
                    </h3>
                    <p>
                        Nutritional Revolution’s website or applications may contain links to third-party websites not owned or controlled by Nutritional Revolution. Nutritional Revolution is not responsible for the privacy policies of any third-party websites which user may access through a third-party link. Further, these third-party websites may have privacy policies that differ from the Nutritional Revolution Privacy Policy. Nutritional Revolution disclaims all responsibility for the privacy practices of such other third-party websites. You should read the privacy policies of each third-party website you visit to determine what information each third-party website may be collecting about you and how they intend to use such information.
                    </p>
                </div>
            </div>
        );
    }

}




export default withNavigation(Privacy);