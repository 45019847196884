import React from 'react';
import { signOutFunction } from './signOut';
import { getAuth } from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
/*
    This button allows users to sign out once they've
    been signed in with Firebase Auth
*/
export const SignOutButton = () => {
    const navigate = useNavigate();
    const onClickSignOut = async () => {

        const result = await signOutFunction().then(() =>{
        navigate('/');

        }).catch((error) => {
            alert(error);
        });
    }
    const auth = getAuth();
    const user = auth.currentUser;

    if (user !== null){
        return (
            <button className="signOutButton"
                    onClick={onClickSignOut}
            >Sign Out</button>
        );
    }else{
        return (
            <p></p>
        )
    }
}