import React, { Component } from 'react';
import { useForm } from 'react-hook-form';
import {useState} from 'react';
import { db } from "../firebase/db";
import {doc, addDoc, collection} from "firebase/firestore";
import { signup } from '../firebase/auth';


function Signup() {
    const { register, handleSubmit, reset } = useForm();

    const [isLoading, setLoading] = useState(false);
    const [isChecked: string, setChecked] = useState('');

    const onSubmit = async (data) => {
        console.log('loading');
        setLoading(true);

        try {
            console.log(data)
            let userData = {
                'first_name': data.firstName,
                'last_name': data.lastName
            };

            if(data.privacy === true) {
                setChecked('');
                await addDoc(collection(db, 'users'), userData);
                await signup(data);
            }else{
                setChecked('You need to agree to terms to register');
            }

            reset();
        }catch(error){
            console.log(error);
        }
        setLoading(false);
    }
    console.log(isChecked);
    const formClassName = `ui form $i{isLoading ? 'loading' : ''}`;
    return (
        <div className="pageContainer">
        <div className="signup-container">
            <h2>Signup</h2>
            <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
                <br/>
                <label>First Name</label><br/>
                <input type="text" name="firstName" {...register('firstName')}/><br/><br/>
                <label>Last Name</label><br/>
                <input type="text" name="lastName" {...register('lastName')}/><br/><br/>
                <label>Username/Email</label><br/>
                <input type="email" name="email" {...register('email')}/> <br/><br/>
                <label>Password</label><br/>
                <input type="password" name="password" {...register('password')}/><br/><br/>
                <label htmlFor="privacy">I agree to the <a href="/terms/" target="_blank">Terms & Conditions</a></label><br/>
                <input type="checkbox" name="privacy" {...register('privacy')} /> <br />
                <p className="error-text">{isChecked}</p>
                <button className="signup-button" type="submit">Signup</button>
            </form>

        </div>
        </div>
    );
}


export default Signup;