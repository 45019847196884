import React, { Component } from "react";
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { getAuth } from 'firebase/auth';
import {doc, deleteDoc, onSnapshot, collection, getDocs, updateDoc, arrayUnion} from "firebase/firestore";
import { db } from '../../firebase/db.js';
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

class GetProdTable extends Component {
    removeProduct;
    constructor(props) {
        super(props);


        this.prodType = React.createRef();
        this.prodName = React.createRef();
        this.prodFlavor = React.createRef();
        this.prodCals = React.createRef();
        this.prodCarbs = React.createRef();
        this.state={
            newData: [],
            prodType: '',
            loading: false,
        };
        this.componentDidMount = this.componentDidMount();

    }



    componentDidMount = async () =>{
        const { newData } = this.state;

        const querySnapshot = await getDocs(collection(db, "products"));

        querySnapshot.forEach((doc) => {
            //console.log(doc.id);

            let obj = {
                ['id']:doc.id,
                ['type']:doc.data().type,
                ['name']:doc.data().name,
                ['carbs']:doc.data().carbs,
                ['sodium']:doc.data().sodium,
                ['fiber']:doc.data().fiber,
                ['flavor']:doc.data().flavor,
                ['calories']:doc.data().calories,
                ['caff']:doc.data().caff,
                ['serving_size']:doc.data().serving_size,


            }

            newData.push(obj);

        });

        this.removeProduct = async (e) =>{
            let deleteConfirm = window.confirm("Are you sure you want to delete this product?");
            if(deleteConfirm === true) {
                await deleteDoc(doc(db, "products", e)).then(() => {
                    const navigate = useNavigate();
                    navigate("#");
                });
            }
        }

        $(document).ready(function () {
            setTimeout(function(){
                $('#sweatTable').DataTable();
            } ,1000);
        });

        this.setState({newData});

    }

    updateProd = async (e) => {
        this.setState({loading: true});

        // update product type
        let prod_type = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].type = prod_type;

        await updateDoc(doc(db, "products", prod_id), {
            type: prod_type,

        }).then(() => {
            this.setState({loading:false});
        });
    }
        updateName = async (e) => {
            this.setState({loading:true});

            // update product type
            let prod_name = e.target.value;
            //console.log(prod_type);
            let prod_id = e.target.getAttribute("data-prodid");
            let index = this.state.newData.findIndex(x => x.id === prod_id);

            this.state.newData[index].name = prod_name;

            await updateDoc(doc(db, "products", prod_id), {
                name:prod_name,

            }).then(() => {
              this.setState({loading:false});
            });

    }

    updateFlavor = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_flavor = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].flavor = prod_flavor;

        await updateDoc(doc(db, "products", prod_id), {
            flavor:prod_flavor,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateCals = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_cals = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].cals = prod_cals;

        await updateDoc(doc(db, "products", prod_id), {
            calories:prod_cals,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateCarbs = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_carbs = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].carbs = prod_carbs;

        await updateDoc(doc(db, "products", prod_id), {
            carbs:prod_carbs,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateSodium = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_sodium = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].sodium = prod_sodium;

        await updateDoc(doc(db, "products", prod_id), {
            sodium:prod_sodium,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateFiber = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_fiber = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].fiber = prod_fiber;

        await updateDoc(doc(db, "products", prod_id), {
            fiber:prod_fiber,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateCaff = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_caff = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].caff = prod_caff;

        await updateDoc(doc(db, "products", prod_id), {
            caff:prod_caff,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    updateServing = async (e) => {
        this.setState({loading:true});

        // update product type
        let prod_ss = e.target.value;
        //console.log(prod_type);
        let prod_id = e.target.getAttribute("data-prodid");
        let index = this.state.newData.findIndex(x => x.id === prod_id);

        this.state.newData[index].serving_size = prod_ss;

        await updateDoc(doc(db, "products", prod_id), {
            serving_size:prod_ss,

        }).then(() => {
            this.setState({loading:false});
        });

    }

    render()
    {

        return (
            <div className="sweatTableContainer">

                <table id="sweatTable" className="sweat-table">
                    <thead>
                    <tr>
                        <th>
                            Product Type
                        </th>
                        <th>
                            Product Name
                        </th>
                        <th>
                            Flavor
                        </th>
                        <th>
                            Calories
                        </th>
                        <th>
                            Carbs (g)
                        </th>
                        <th>
                            Sodium (mg)
                        </th>
                        <th>
                            Fiber (g)
                        </th>
                        <th>
                            Caffeine (mg)
                        </th>
                        <th>
                            Serving Size
                        </th>
                        <th>
                            Remove
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    {this.state.newData.map((result) => {

                        //console.log(result);
                        return(
                            <tr key={result.id}>
                                <td><span className="inputHidden">{result.type}</span>
                                    <select data-prodid={result.id} className="product-type" name="type" defaultValue={result.type} onChange={this.updateProd} ref={this.prodType}>
                                        <option value="none">Choose Product Type</option>
                                        <option value="hydration">Hydration</option>
                                        <option value="fuel">Fuel</option>
                                    </select>

                                </td>
                                <td><span className="inputHidden">{result.name}</span><input data-prodid={result.id} name="name" type="text" defaultValue={result.name} onBlur={this.updateName}/></td>
                                <td><span className="inputHidden">{result.flavor}</span><input data-prodid={result.id} name="flavor" type="text" defaultValue={result.flavor} onBlur={this.updateFlavor}/></td>
                                <td><span className="inputHidden">{result.calories}</span><input data-prodid={result.id} name="calories" type="text" defaultValue={result.calories} onBlur={this.updateCals}/></td>
                                <td><span className="inputHidden">{result.carbs}</span><input data-prodid={result.id} name="carbs" type="text" defaultValue={result.carbs} onBlur={this.updateCarbs} /></td>
                                <td><span className="inputHidden">{result.sodium}</span><input data-prodid={result.id} name="sodium" type="text" defaultValue={result.sodium} onBlur={this.updateSodium}/></td>
                                <td><span className="inputHidden">{result.fiber}</span> <input data-prodid={result.id} name="sodium" type="text" defaultValue={result.fiber} onBlur={this.updateFiber}/></td>
                                <td><span className="inputHidden">{result.caff}</span> <input data-prodid={result.id} name="sodium" type="text" defaultValue={result.caff} onBlur={this.updateCaff}/></td>
                                <td><span className="inputHidden">{result.serving_size}</span> <input data-prodid={result.id} name="sodium" type="text" defaultValue={result.serving_size} onBlur={this.updateServing}/></td>
                                <td onClick={(e) => this.removeProduct(result.id, e)}><button className="remove-prod-button">Remove</button></td>

                            </tr>

                        )
                    })
                    }

                    </tbody>
                </table>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }
            </div>
        )

    }

}

export default GetProdTable;