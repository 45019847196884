import React, {Component} from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import {doc, getFirestore, setDoc, onSnapshot, getDoc, updateDoc} from "firebase/firestore";
import { db } from '../../firebase/db.js';
import { updateUserMeta } from './updateUserMeta';
import { height } from './getSweatTable';
import {Spinner} from "react-bootstrap";

class UserMetaForm extends Component{

    constructor(props) {
        super(props);

        this.height = React.createRef();
        this.sodium_goal = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleLoader = this.toggleLoader.bind(this);

        this.state = {
            user_id: '',
            height: '',
            sodium_goal: 'a',
            loading:false
        }

    }
    componentDidMount = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;
        const displayName = user.displayName;

        const metaSnap = await getDoc(doc(db, "users", user_id));

        console.log(metaSnap.data());

        this.setState({sodium_goal:metaSnap.data().sodium_goal});
        this.setState({height:metaSnap.data().height});


    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const auth = getAuth();
        const user = auth.currentUser;
        const user_id = user.uid;
        const displayName = user.displayName;


        let height = this.height.current.value;

        let sodium_goal = this.state.sodium_goal;

        await updateDoc(doc(db, 'users', user_id), {
            ['height']: height,
            ['sodium_goal']:sodium_goal
        });
    }
    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };


    render(){
    return (
        <div className="userMeta">
                <form onSubmit={this.handleSubmit}>
                    <h2>User Profile</h2>


                    <button type="submit" onClick={() => this.toggleLoader()}>Update</button>
                    {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }

                </form>

        </div>

    )
}

}

export default UserMetaForm;