import React, { Component } from "react";
import { doc, setDoc, collection, getDoc, addDoc } from "firebase/firestore";
import { db } from '../../firebase/db.js';
import { useState, useForm } from 'react-hook-form';
import { getAuth } from 'firebase/auth';
import { Spinner } from 'react-bootstrap';
import GetSweatTable from './getSweatTable.js';


function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

class GutForm extends Component {
    constructor(props){
        super(props);
        this.state = {value: ''};
        this.state = {loading: false}
        this.bodyWeightLoss = 0;
        this.dvolume = 0;
        this.date = 0;
        this.gutObject = {
            ['title']:'',
            ['race_date']:'',
            ['sodium_loss']: 'yes',
            ['sodium_loss_value']: 0,
            ['carb_consume']: 'yes',
            ['carb_consume_value']: 0,
            ['fluids_train']: 'yes',
            ['fluids_value']: 0,
            ['product']:'',
            ['is_paused']:false
        };


        this.addGutSubmit = this.addGutSubmit.bind(this);
        this.sodiumLossInput = this.sodiumLossInput.bind(this);
        this.titleInput = this.titleInput.bind(this);
        this.raceDateInput = this.raceDateInput.bind(this);
        this.sodiumLossValInput = this.sodiumLossValInput.bind(this);
        this.carbConsumeInput = this.carbConsumeInput.bind(this);
        this.carbConsumeValInput = this.carbConsumeValInput.bind(this);
        this.fluidsTrainInput = this.fluidsTrainInput.bind(this);
        this.fluidsTrainValInput = this.fluidsTrainValInput.bind(this);
        this.productInput = this.productInput.bind(this);



    }

    titleInput(event){
        this.gutObject.title = event.target.value;
        console.log(this.gutObject);
    }
    raceDateInput(event){
        this.gutObject.race_date = event.target.value;
        console.log(this.gutObject);
    }
    sodiumLossInput(event){
        this.gutObject.sodium_loss = event.target.value
        console.log(this.gutObject);
    }

    sodiumLossValInput(event){
        this.gutObject.sodium_loss_value = event.target.value
        console.log(this.gutObject);
    }
    carbConsumeInput(event){
        this.gutObject.carb_consume = event.target.value
        console.log(this.gutObject);
    }
    carbConsumeValInput(event){
        this.gutObject.carb_consume_value = event.target.value
        console.log(this.gutObject);
    }
    fluidsTrainInput(event){
        this.gutObject.fluids_train = event.target.value
        console.log(this.gutObject);
    }
    fluidsTrainValInput(event){
        this.gutObject.fluids_value = event.target.value
        console.log(this.gutObject);
    }
    productInput(event){
        this.gutObject.product= event.target.value
        console.log(this.gutObject);
    }



    addGutSubmit = async (e) => {
        e.preventDefault();

        AddLoadingGif();
        await this.updateGutTestData().then(() => {
            this.setState({ loading: false });
            //GetSweatTable.componentDidMount();
        });



    }

    updateGutTestData = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userID = user.uid;

        await addDoc(collection(db, 'users', userID, 'gut-training'), {
            ['timestamp']:Date.now(),
            ['sodium_loss']: this.gutObject.sodium_loss,
            ['sodium_loss_value']: this.gutObject.sodium_loss_value,
            ['carb_consume']: this.gutObject.carb_consume,
            ['carb_consume_value']: this.gutObject.carb_consume_value,
            ['fluids_train']: this.gutObject.fluids_train,
            ['fluids_value']: this.gutObject.fluids_value,
            ['product']:this.gutObject.product,
            ['is_paused']:this.gutObject.is_paused

        })




    }

    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };


    render(){

        return (
            <div className="gutForm">

                <form onSubmit={this.addGutSubmit}>

                    <div className="row gut-values">
                        <div className="col">
                            <label htmlFor="sodium-loss">Have you tested sodium loss?</label><br/>
                            <select name="sodium-loss" value={this.state.sodium_loss} onChange={this.sodiumLossInput} >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <br/>
                            <label htmlFor="carb-consumed">Do you currently consume carbohydrates during training?</label><br/>
                            <select name="carb-consumed" value={this.state.carb_consume} onChange={this.carbConsumeInput} >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <br/>
                            <label htmlFor="fluids">Do you currently consume fluids during training</label><br/>
                            <select name="fluids" value={this.state.fluids_train} onChange={this.fluidsTrainInput} >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="sodium-loss-value">Sodium Loss Value (mg/oz)</label><br/>
                            <input name="sodium-loss-value" type="number" value={this.state.sodium_loss_value} onChange={this.sodiumLossValInput} /> <br/>
                            <label htmlFor="carb-consumed-value">How many carbs/hr? (g)</label><br/>
                            <input name="carb-consumed-value" type="number" value={this.state.carb_consume_value} onChange={this.carbConsumeValInput} /><br/>
                            <label htmlFor="fluids-value">How many fluids/hr? (oz)</label><br/>
                            <input name="fluids-value" type="number" value={this.state.fluids_value} onChange={this.fluidsTrainValInput} /><br />
                        </div>


                    </div>
                    <div className="row">
                        <h4>What sports fueling and hydration products would you like to use?</h4>
                        <select name="session-type" value={this.state.product} onChange={this.productInput} >
                            <option value="no hydration">Select Product</option>
                            <option value="Product 1">Product 1</option>
                            <option value="Product 2">Product 2</option>
                        </select>
                    </div>
                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Submit Gut Training</button>


                </form>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }

            </div>

        )
    }



}

export default GutForm;