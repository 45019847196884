import React from 'react';



export const Footer = () =>{

    return(
        <footer>
            <div className="footer-inner">
                <div className="row d-flex flex-wrap align-items-start">
                    <div className="footer1 col">
                        <ul className="terms">
                            <li><a href="/terms">Terms</a></li>
                            <li><a href="/privacy-policy">Privacy</a></li>
                        </ul>
                    </div>
                    <div className="footer2 col">
                        <p>@ 2023 Nutritional Revolution</p>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer;