import firebase from 'firebase/compat/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import { withRouter } from "../firebase/withRouter.js";
import React from "react";


export const auth = getAuth();

export const signup = async ({ firstName, lastName, email, password }) => {
    const resp = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = resp.user;
    await resp.user.updateProfile({displayName: `${firstName} ${lastName}`});
    return user;
}


export const signIn = (email, password) => {

    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            if (errorCode){
                console.log(errorCode);
            }
        });

}

export const logout = () => {
    return firebase.auth().signOut();
}

export const userAuth = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        const emailVerified = user.emailVerified;

        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
    }
}



