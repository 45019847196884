import React, { Component } from "react";
import { doc, setDoc, collection, getDoc, addDoc } from "firebase/firestore";
import { db } from '../../firebase/db.js';
import { useState, useForm } from 'react-hook-form';
import { getAuth } from 'firebase/auth';
import { Spinner } from 'react-bootstrap';



function AddLoadingGif(){
    return <div className="loading-gif"><img src={require("../../assets/imgs/loading-gif.gif")} /></div>;
}

class AddProduct extends Component {
    constructor(props){
        super(props);



        this.state = {value: ''};
        this.state = {loading: false}

        this.prodObject = {
            ['type']: '',
            ['name']: '',
            ['flavor']: '',
            ['calories']: '',
            ['carbs']: '',
            ['sodium']: '',
            ['fiber']: '',
            ['caff']: '',

        };

        this.addProduct = this.addProduct.bind(this);
        this.prodTypeInput = this.prodTypeInput.bind(this);
        this.prodNameInput = this.prodNameInput.bind(this);
        this.prodFlavorInput = this.prodFlavorInput.bind(this);
        this.prodCaloriesInput = this.prodCaloriesInput.bind(this);
        this.prodCarbsInput = this.prodCarbsInput.bind(this);
        this.prodSodiumInput = this.prodSodiumInput.bind(this);
        this.prodFiberInput = this.prodFiberInput.bind(this);
        this.prodCaffInput = this.prodCaffInput.bind(this);

    }

    prodTypeInput(event)
    {
        console.log(event);
        this.prodObject.type = event.target.value;
    }
    prodNameInput(event)
    {
        this.prodObject.name = event.target.value;
    }
    prodFlavorInput(event)
    {
        this.prodObject.flavor = event.target.value;
    }
    prodCaloriesInput(event)
    {
        this.prodObject.calories = event.target.value;
    }
    prodCarbsInput(event)
    {
        this.prodObject.carbs = event.target.value;
    }
    prodSodiumInput(event)
    {
        this.prodObject.sodium = event.target.value;
    }
    prodFiberInput(event)
    {
        this.prodObject.fiber = event.target.value;
    }
    prodCaffInput(event)
    {
        this.prodObject.caff = event.target.value;
    }



    addProduct = async (e) => {
        e.preventDefault();

        AddLoadingGif();
        await this.addProductData().then(() => {
          this.setState({ loading: false });
        });

    }

    updateProductData = async () => {

        await addDoc(collection(db, 'products'), {
            ['timestamp']:Date.now(),
            ['calories']:this.prodObject.calories,
            ['carbs']:this.prodObject.carbs,
            ['fiber']:this.prodObject.fiber,
            ['name']:this.prodObject.name,
            ['sodium']:this.prodObject.sodium,
            ['flavor']:this.prodObject.flavor,
            ['type']:this.prodObject.type,
            ['caff']:this.prodObject.caff,


        });


    }

    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };


    render(){

        return (
            <div className="addProdForm">

                <form onSubmit={this.addProduct}>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="product-type">Product Type</label><br/>
                            <select name="product-type" value={this.state.type} onChange={this.prodTypeInput}>
                                <option value="default">Select Fuel</option>
                                <option value="fuel">Fuel</option>
                                <option value="hydration">Hydration</option>
                            </select>
                            <label htmlFor="product-calories">Product Calories</label><br/>
                            <input name="product-calories" type="number" value={this.state.calories} onChange={this.prodCaloriesInput}/>
                            <label htmlFor="product-fiber">Product Fiber (g)</label><br/>
                            <input name="product-fiber" type="number" value={this.state.fiber} onChange={this.prodFiberInput}/>
                        </div>
                        <div className="col">

                            <label htmlFor="product-name">Product Name</label><br/>
                            <input name="product-name" type="text" value={this.state.name} onChange={this.prodNameInput}/>
                            <label htmlFor="carbs">Carbs (g)</label><br/>
                            <input name="carbs" type="number" value={this.state.carbs} onChange={this.prodCarbsInput}/>
                            <label htmlFor="caff">Caffeine (mg)</label><br/>
                            <input name="caff" type="number" value={this.state.caff} onChange={this.prodCaffInput}/>

                        </div>
                        <div className="col">
                            <label htmlFor="product-flavor">Product Flavor</label><br/>
                            <input name="product-flavor" type="text" value={this.state.flavor} onChange={this.prodFlavorInput}/>
                            <label htmlFor="product-sodium">Product Sodium (mg)</label><br/>
                            <input name="product-sodium" type="number" value={this.state.sodium} onChange={this.prodSodiumInput}/>

                        </div>

                    </div>
                    <button onClick={() => this.toggleLoader()} className="new-session-submit">Add Product</button>


                </form>
                {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }

            </div>

        )
    }



}

export default AddProduct;