import React, {Component} from 'react';
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import { createRoot } from 'react-dom/client';
import Login, {loginTab} from './Login.js';
import 'react-tabs/style/react-tabs.css';
import {Spinner} from "react-bootstrap";

const EmailSent = () => {
    const emailSentMessage = 'Email Sent!';
    return emailSentMessage;
}

class Reset extends Component {
    constructor(props) {
        super(props);

        this.state = {email: '', loading:false, emailSent:false}
        this.emailValueInput = this.emailValueInput.bind(this);

    }

    emailValueInput(event) {
        this.setState({email: event.target.value});
        console.log(this.state.email);
    }




    resetPassword = (event) => {
        event.preventDefault();

        const auth = getAuth();
        sendPasswordResetEmail(auth, this.state.email)
            .then(() => {
                this.setState({ loading: false });
                this.setState({emailSent: true });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                this.setState({ loading: false });

            })

    }

    toggleLoader = () => {
        if (!this.state.loading) {
            this.setState({ loading: true });
        } else {
            this.setState({ loading: false });
        }
    };





    render() {
        return (
            <div className="pageContainer">
                <div className="reset-container">
                    <div className="reset-tab">
                        <h4>Password Reset</h4>
                    <form className="reset-fields" onSubmit={this.resetPassword}>
                        <label htmlFor="email">Email Address</label><br/>
                        <input id="email" type="text" name="email"
                               value={this.state.email}
                               onChange={this.emailValueInput}/> <br/><br/>
                        <button className="login-button"
                                onClick={this.toggleLoader}> Email Me
                        </button>
                    </form>
                        {this.state.loading ?  <Spinner style={{marginBottom:27}} animation="border" /> : null }
                        {this.state.emailSent ? <EmailSent /> : null }
                    </div>


                </div>
            </div>
        );
    }

}

export default Reset;